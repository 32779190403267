import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, DismissibleTag } from '@carbon/react';
import { ChevronDown, ChevronUp, Close } from '@carbon/icons-react';

type Option = {
  label: string,
  value: string
};

type Group = {
  name: string,
  label: string,
  options: Option[]
};

type MultiSelectDropdownProps = {
  options: Omit<Group, 'label'>[],
  defaultSelection: string[],
  selectedItems: Set<string>,
  onChange: (selectedItems: Set<string>) => void,
  disabled?: boolean
};

const MultiSelectDropdown = ({
  options,
  defaultSelection,
  selectedItems,
  onChange,
  disabled = false
}: MultiSelectDropdownProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const isOptionSelected = (value: string): boolean => selectedItems.has(value);

  const toggleOption = (value: string): void => {
    const newSelected = new Set(selectedItems);
    if (newSelected.has(value)) {
      newSelected.delete(value);
    } else {
      newSelected.add(value);
    }
    onChange(newSelected);
  };

  const toggleGroup = (groupName: string): void => {
    const newSelected = new Set(selectedItems);
    const groupOptions = options.find(group => group.name === groupName)?.options || [];

    const allSelected = groupOptions.every(option => newSelected.has(option.value));

    groupOptions.forEach(option => {
      if (allSelected) {
        newSelected.delete(option.value);
      } else {
        newSelected.add(option.value);
      }
    });

    onChange(newSelected);
  };

  const selectAll = (): void => {
    const allOptions = options.flatMap(group => group.options.map(option => option.value));
    const allSelected = allOptions.every(value => selectedItems.has(value));

    if (allSelected) {
      onChange(new Set());
    } else {
      onChange(new Set(allOptions));
    }
  };

  const selectDefault = (): void => {
    const allDefaultSelected = defaultSelection.every(value => selectedItems.has(value));
    const newSelected = new Set(selectedItems);

    if (allDefaultSelected) {
      defaultSelection.forEach(value => newSelected.delete(value));
    } else {
      defaultSelection.forEach(value => newSelected.add(value));
    }

    onChange(newSelected);
  };

  const clearSelection = (): void => {
    onChange(new Set());
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const unFilteredOptions: Group[] = options
    .map(group => ({
      ...group,
      label: t(`treePropertySelector.${group.name}`)
    })).sort((a, b) => a.label.localeCompare(b.label));

  const filteredOptions = unFilteredOptions.map(group => ({
    ...group,
    options: group.options.filter(option =>
      option.label.toLowerCase().includes(searchTerm)
    )
  })).filter(group =>
    group.options.length > 0 || group.label.toLowerCase().includes(searchTerm)
  );

  const allOptions = options.flatMap(group => group.options.map(option => option.value));
  const allSelected = allOptions.every(value => selectedItems.has(value));
  const anySelected = allOptions.some(value => selectedItems.has(value));
  const defaultSelected = defaultSelection.every(value => selectedItems.has(value));
  const anyFromDefaultSelected = defaultSelection.some(value => selectedItems.has(value));
  const selectedCount = selectedItems.size;

  return (
    <div className={`relative twp text-sm font-normal h-12 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`} ref={dropdownRef}>
      {/* Dropdown Header */}
      <div className="flex items-center relative" onClick={!disabled ? () => setIsOpen(true) : undefined}>
        {selectedCount > 0 &&
          <DismissibleTag
            type="high-contrast"
            onClose={disabled ? undefined : clearSelection}
            text={selectedCount.toString()}
            disabled={disabled}
            className="absolute left-2"
          />}
        <input
          type="text"
          placeholder={selectedCount > 0 ? 'Columns selected' : 'Select columns...'}
          value={searchTerm}
          onChange={handleSearch}
          className={`bg-[var(--cds-field)] p-3 ${selectedCount > 0 ? 'pl-16' : 'pl-4'} pr-8 border-2 border-[var(--cds-field)] focus:outline-none focus:border-[var(--cds-interactive)] ${disabled ? 'cursor-not-allowed' : ''}`}
          disabled={disabled}
        />
        <div className="absolute right-4 flex">
          {searchTerm &&
            <div className="pr-2 border-r-[1px] border-[var(--cds-border-strong)]">
              <Close className="cursor-pointer" onClick={!disabled ? () => setSearchTerm('') : undefined} />
            </div>}
          {isOpen
            ? <ChevronUp
              className="ml-2 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            />
            : <ChevronDown className="ml-2 cursor-pointer" />}
        </div>
        {/* Bottom border */}
        <div className="absolute bottom-0 bg-orchid-500 w-full h-0 border-b-[1px] border-[var(--cds-border-strong)]"/>
      </div>

      {/* Dropdown Content */}
      {isOpen && !disabled && (
        <div
          className="absolute top-full left-0 z-50 max-h-64 w-full overflow-y-auto bg-[var(--cds-field)]"
          style={{ boxShadow: '0 2px 6px var(--cds-shadow, rgba(0, 0, 0, 0.3))' }}
        >
          {/* Options List */}
          <ul>
            {/* Select All */}
            {(searchTerm.length === 0 || ('Select All').toLowerCase().includes(searchTerm.toLowerCase())) && <li
              className={`${allSelected ? 'bg-[var(--cds-layer-selected)]' : ''} px-4 py-3 cursor-pointer`}
              onClick={e => {
                e.preventDefault();
                selectAll();
              }}
            >
              <Checkbox
                className="text-[var(--cds-text-primary)] [&>label]:pt-0.5 !mb-0 [&>*]:font-semibold"
                checked={allSelected}
                id="column-select-all"
                labelText="Select All"
                indeterminate={!allSelected && anySelected}
              />
            </li>}

            {/* Select Default */}
            {(searchTerm.length === 0 || ('Select Default').toLowerCase().includes(searchTerm.toLowerCase())) && <li
              className={`${defaultSelected ? 'bg-[var(--cds-layer-selected)]' : ''} px-4 py-3 cursor-pointer`}
              onClick={e => {
                e.preventDefault();
                selectDefault();
              }}>
              <Checkbox
                className="text-[var(--cds-text-primary)] [&>label]:pt-0.5 !mb-0 [&>*]:font-semibold"
                checked={defaultSelected}
                id="column-select-default"
                labelText="Select Default"
                indeterminate={!defaultSelected && anyFromDefaultSelected}
              />
            </li>}

            {/* Grouped Options */}
            {filteredOptions.map(group => {
              const unfilteredGroup = unFilteredOptions.find(it => it.name === group.name);
              const isGroupSelected = unfilteredGroup?.options.some(option => isOptionSelected(option.value))
                && unfilteredGroup.options.every(option => isOptionSelected(option.value));
              const anyOptionFromGroupSelected = unfilteredGroup?.options.some(option => isOptionSelected(option.value));

              return (
                <li key={group.name} className={`${isGroupSelected ? 'bg-[var(--cds-layer-selected)]' : ''} `}>
                  {(searchTerm.length === 0 || group.label.toLowerCase().includes(searchTerm.toLowerCase())) && <div
                    className="mx-4 border-t-[1px] border-[var(--cds-border-subtle-01)] cursor-pointer"
                    onClick={e => {
                      e.preventDefault();
                      toggleGroup(group.name);
                    }}>
                    <div className="py-3">
                      <Checkbox
                        className="text-[var(--cds-text-primary)] [&>label]:pt-0.5 !mb-0 [&>*]:font-semibold"
                        checked={isGroupSelected}
                        id={`group-${group.name}`}
                        labelText={group.label}
                        indeterminate={!isGroupSelected && anyOptionFromGroupSelected}
                      />
                    </div>
                  </div>}
                  <ul>
                    {group.options.map(option => (
                      <li
                        key={option.value}
                        className={`${isOptionSelected(option.value) ? 'bg-[var(--cds-layer-selected)]' : ''} px-4 py-3 cursor-pointer`}
                        onClick={e => {
                          e.preventDefault();
                          toggleOption(option.value);
                        }}
                      >
                        <Checkbox
                          className="text-[var(--cds-text-secondary)] [&>label]:pt-0.5 !mb-0"
                          checked={isOptionSelected(option.value)}
                          id={`option-${option.value}`}
                          labelText={option.label}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              );})}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
