import { ContextMenu } from '../../../components/UI/ContextMenu/ContextMenu';
import { ContextMenuTrigger } from '../../../components/UI/ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../../../components/UI/ContextMenu/ContextMenuContent';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { AvailableProperties } from '../../../properties/AvailableProperties';
import { useTranslation } from 'react-i18next';
import { useLegacyAvailableColumnSelectorProperties } from '../../../properties/usePropertyConfigurations';
import { useCurrentAccount } from '../../../account/useAccounts';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import { PlusCircle, Search, ViewColumns3 } from 'iconoir-react';
import { useState } from 'react';
import { Flippers } from '../../../switches/Flippers';

export default function TableColumnSelector (props: ColumnSelectorProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const properties = useLegacyAvailableColumnSelectorProperties(organization.isEnabled(Flippers.release24q3));
  const allProperties = Object.values(properties).flat();

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedProperties, setSelectedProperties] = useState(props.properties);

  const handlePropertySelect = (property: DisplayableTreeProperty) => () => {
    if (selectedProperties.includes(property)) {
      setSelectedProperties(selectedProperties.filter(prop => prop !== property));
    } else {
      setSelectedProperties([...selectedProperties, property]);
    }
  };

  const handlePropertyGroupSelect = (properties: DisplayableTreeProperty[]) => {
    if (properties.every(prop => selectedProperties.includes(prop))) {
      setSelectedProperties(prev => prev.filter(it => !properties.includes(it)));
    } else {
      setSelectedProperties(prev => Array.from(new Set([...prev, ...properties])));
    }
  };

  const getPropertyTitle = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, organization);
    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    const columnTitle = t(`tree.${property}`) + translatedUnit;

    return (
      <li
        key={`property-selector-modal-${property}`}
        className="my-2 text-sm"
      >
        <Checkbox
          checked={selectedProperties.includes(property)}
          onChange={handlePropertySelect(property)}
          className={'border-outer-space-400'}
        >
          <span className="ml-1">{t(`${columnTitle}`)}</span>
        </Checkbox>
      </li>);
  };

  return (
    <ContextMenu onClose={() => props.setProperties(selectedProperties)}>
      <ContextMenuTrigger>
        {organization.isEnabled(Flippers.release24q3) ? (
          <div>
            <span className="twp rounded-full button py-3 px-4 w-full flex items-center gap-1 justify-center border border-outer-space-400 hover:bg-outer-space-400">
              <ViewColumns3 fontSize={10}/>{t('selectColumns.manageColumns')}
            </span>
          </div>
        ) :
          (
            <div>
              <span className="twp rounded-full button py-1 px-4 w-full flex items-center gap-1 justify-center border border-outer-space-400 hover:bg-outer-space-400">
                <PlusCircle /> {t('selectColumns.add')}
              </span>
            </div>
          )}
      </ContextMenuTrigger>
      <ContextMenuContent className="text-greehill-00 p-4 rounded-xl min-w-96 max-h-[26rem] overflow-y-scroll">
        <h3>{t('selectColumns.display')}</h3>
        <div className="border border-outer-space-400 px-3 py-1.5 flex items-center justify-between rounded-lg my-4">
          <input
            className="w-full bg-greehill-00/0 focus:outline-0"
            value={searchTerm}
            onChange={e => setSearchTerm(e.currentTarget.value)}
            placeholder={t('selectColumns.searchPlaceholder')}
          />
          <Search className="p-0.5"/>
        </div>
        <div className="divide-y divide-outer-space-400">
          <div className="flex items-center justify-between my-4">
            <Checkbox
              checked={selectedProperties.length === allProperties.length}
              onChange={() => setSelectedProperties(selectedProperties.length === allProperties.length ? [] : allProperties)}
              className={'border-outer-space-400'}
            >
              <span className="ml-1 font-bold">{t('selectColumns.allColumns')}</span>
            </Checkbox>
            <span className="text-sm text-greehill-00/50">{`${selectedProperties.length} selected`}</span>
          </div>
          {
            new AvailableProperties(properties).map((name, properties) => {
              return <div className="py-3">
                <div className="mb-3">
                  <Checkbox
                    checked={properties.every(prop => selectedProperties.includes(prop))}
                    onChange={() => handlePropertyGroupSelect(properties)}
                    className={'border-outer-space-400'}
                  >
                    <span className="ml-1 font-bold">{t(name)}</span>
                  </Checkbox>
                </div>
                <ul className="ml-5">
                  {
                    properties
                      .filter(it => searchTerm.length ? t(`details.properties.${it}`).toLowerCase().includes(searchTerm.toLowerCase()) : true)
                      .map(property => (getPropertyTitle(property)))
                  }
                </ul>
              </div>;
            })
          }
        </div>
      </ContextMenuContent>
    </ContextMenu>
  );
}

interface ColumnSelectorProps {
  properties: DisplayableTreeProperty[],
  setProperties: (properties: DisplayableTreeProperty[]) => unknown
}
