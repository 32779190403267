import PageLayout from '../../components/UI/Page/carbon/PageLayout';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../account/useAccounts';
import { AccountType } from '../../account/AccountType';
import ActivityGroups from './activities/ActivityGroups';

export default function CarbonTaskManager() {
  const match = useMatch('/organizations/:organizationId/task-manager/:subItem/*');
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const navigate = useNavigate();

  if (account.type === AccountType.Client) {
    navigate('/organizations/' + match?.params.organizationId + '/inventory');
    return null;
  }

  return (
    <PageLayout pageTitle={t('mainMenu.taskManager')}>
      <PageLayout.Content>
        <ActivityGroups />
      </PageLayout.Content>
    </PageLayout>
  );
}
