export interface ActivityDto {
  id: string,
  organizationId: string,
  name: string,
  createdAt: string,
  updatedAt: string
}

export interface ActivityGroupDto {
  id: string,
  name: string,
  organizationId: string,
  activities: ActivityDto[],
  createdAt: string,
  updatedAt: string
}

export class Activity {
  static fromDto(dto: ActivityDto): Activity {
    return new Activity(
      dto.id,
      dto.organizationId,
      dto.name,
      dto.createdAt,
      dto.updatedAt
    );
  }

  constructor(
    readonly id: string,
    readonly organizationId: string,
    readonly name: string,
    readonly createdAt: string | null,
    readonly updatedAt: string | null
  ) {
  }
}

export class ActivityGroup {
  static fromDto(dto: ActivityGroupDto): ActivityGroup {
    return new ActivityGroup(
      dto.id,
      dto.organizationId,
      dto.name,
      dto.activities.map(Activity.fromDto),
      dto.createdAt,
      dto.updatedAt
    );
  }

  constructor(
    readonly id: string,
    readonly organizationId: string,
    readonly name: string,
    readonly activities: Activity[],
    readonly createdAt: string,
    readonly updatedAt: string
  ) {
  }
}
