import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './Inventory.module.scss';
import { useCurrentAccount } from '../../account/useAccounts';
import MapViewer from './map-view/MapViewer';
import { MapStyle } from './components/MapStyleSelector';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import { DisplayableTreeProperty, TreeDisplayConfiguration } from '../../tree/Tree';
import useTree from '../../tree/useTree';
import { PanelLayout } from './PanelLayout';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import mapboxgl from 'mapbox-gl';
import { Flippers } from '../../switches/Flippers';
import BallGridLoader from '../../components/UI/BallGridLoader/BallGridLoader';
import Workspace from './Workspace';
import useManagedAreas from '../../managed-area/useManagedAreaList';
import PageLayout from '../../components/UI/Page/carbon/PageLayout';
import SearchBar from '../../components/UI/Page/carbon/SearchBar/SearchBar';
import { useCapturePoints } from '../../capture-point/useCapturePoints';
import loadLowResolutionImageOfCapturePoint
  from '../../components/Panorama/FullPanoramaSphere/loadLowResolutionImageOfCapturePoint';
import QuickTreeDetails from './components/QuickTreeDetails';
import CarbonFilterSelector from './components/CarbonFilterSelector';
import ReportDialog from './reports/ReportDialog';
import PropertyLegend from './components/propertylegend/PropertyLegend';
import FAQPanel from '../../components/UI/Carbon/FAQPanel/FAQPanel';
import useRightPanelContent, { RightPanelContent } from '../../components/UI/Page/carbon/useRightPanelContent';

export default function CarbonInsights() {
  const navigate = useNavigate();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const panelLayout = useRef(new PanelLayout(urlContext.getTreeTableOpen()));
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const treeId = urlContext.getTreeId() ?? '';
  const urlContextFingerPrint = urlContext.serialize();
  const { treeFilterService } = useContext(DependencyInjectionContext);
  const { tree } = useTree(organizationId, treeId);
  const { bestCapturePoint } = useCapturePoints(organizationId, tree);
  const account = useCurrentAccount();
  const propertyConfigs = usePropertyConfigurations();
  const { managedAreaList } = useManagedAreas(organizationId);
  const [searchParams] = useSearchParams();
  const rightPanelContent = useRightPanelContent();

  // useStates

  const [treeFilters, setTreeFilters] = useState<TreeFilter[] | null>(null);
  const [, setTableDragDistance] = useState<number | null>(null);
  const [mapResizeTriggerKey, triggerMapSizeChange] = useState('');
  const [isLoading, setLoadingState] = useState(false);

  // Variables

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const mapStyle = getCurrentMapStyle(urlContext.getStyle() as unknown as MapStyle);

  const managedAreaIdsInURL = urlContext.getManagedAreaIds();
  const selectedManagedAreas = useMemo(() => {
    const ManagedAreasInURL = urlContext.getManagedAreaIds();
    return (managedAreaList ?? []).filter(it => urlContext.getReverseMASelection() ? !ManagedAreasInURL.includes(it.id) : ManagedAreasInURL.includes(it.id));
  }, [JSON.stringify(urlContext.getReverseMASelection()), managedAreaList, JSON.stringify(urlContext.getManagedAreaIds())]);

  const selectedManagedAreaIds = useMemo(() => selectedManagedAreas.map(it => it.id), [selectedManagedAreas]);

  const selectedTreeFilters = useMemo(() => (treeFilters ?? []).filter(it => urlContext.isTreeFilterSelected(it)), [treeFilters, urlContextFingerPrint]);

  const treeDisplayConfiguration: TreeDisplayConfiguration = {
    property: selectedTreeProperty,
    managedAreaIds: urlContext.getManagedAreaIds(),
    isManagedAreaSelectionReversed: urlContext.getReverseMASelection(),
    filters: selectedTreeFilters,
    windSpeed: urlContext.getWindSpeed() || account.getDefaultWindSpeed()
  };

  const selectedTreeFilterProperties: DisplayableTreeProperty[] = useMemo(() => {
    const treeProperties = urlContext.getVisibleTableProperties() || Array.from(new Set(selectedTreeFilters
      .flatMap(it => it.getPropertyNames() as DisplayableTreeProperty[])
      .filter(it => !['species', 'externalId'].includes(it))));

    if (selectedTreeProperty && !treeProperties.includes(selectedTreeProperty)) {
      treeProperties.unshift(selectedTreeProperty);
    }
    return treeProperties;
  }, [selectedTreeFilters, selectedTreeProperty]);

  // useEffects
  useEffect(() => {
    if (urlContext.getVisibleTableProperties() === null || urlContext.getVisibleTableProperties()?.filter(it => it !== DisplayableTreeProperty.ExternalId).length === 0) {
      urlContext.setVisibleTableProperties([DisplayableTreeProperty.Genus, DisplayableTreeProperty.Species, DisplayableTreeProperty.Height, DisplayableTreeProperty.Status, DisplayableTreeProperty.TrunkDiameter]);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    loadLowResolutionImageOfCapturePoint(account.organization, bestCapturePoint, abortController).then();
    return () => abortController.abort();
  }, [bestCapturePoint]);

  useEffect(() => {
    localStorage.setItem('inventorySearchParams', searchParams.toString());
  }, [searchParams]);

  useEffect(() => {
    const searchParams = window.location.href.split('?')[1];
    if (!account.organization.isEnabled(Flippers.workspace)) {
      navigate(`/organizations/${organizationId}/map${searchParams ? '?' + searchParams : ''}`);
    }
  }, [account.organization, organizationId, navigate]);

  useEffect(() => {
    mapboxgl.prewarm();
    return () => {
      mapboxgl.clearPrewarmedResources();
    };
  }, []);

  useEffect(() => {
    treeFilterService.list(organizationId).then(setTreeFilters);
  }, [organizationId]);

  useEffect(() => {
    if (tree === null) return;
    urlContext.setPositionFromTree(tree);
  }, [tree]);

  useEffect(() => {
    const title = account.organization.name + ' - greehill';
    document.title = title;
    if (tree) document.title = tree?.externalId + ' - ' + title;
  }, [tree, account]);
  const [tableResizeTrigger, setTableResizeTrigger] = useState<number>(0);

  useEffect(() => {
    const gracefulMapResizerTimer = setInterval(
      () => requestAnimationFrame(() => {
        triggerMapSizeChange(Math.random().toString());
      }),
      2
    );
    //Resizing needs more time to occur than 120ms in cleanupTimer
    const cleanupTimer = setTimeout(() => clearInterval(gracefulMapResizerTimer), 250);
    return () => {
      clearInterval(gracefulMapResizerTimer);
      clearTimeout(cleanupTimer);
      triggerMapSizeChange(Math.random().toString());
    };
  }, [JSON.stringify(urlContext.getTreeTableOpen()), tableResizeTrigger]);

  return (
    <PageLayout pageTitle="pageTitles.insights">
      <PageLayout.CenterNavExtensions>
        <SearchBar
          managedAreas={managedAreaList}
          organization={account.organization}
        />
        {selectedTreeProperty &&
          <div
            className={styles.propertyLegendContainer}
            style={urlContext.getSidebarOpen() ? { left: '300px' } : {}}
          >
            <PropertyLegend selectedProperty={selectedTreeProperty} />
          </div>
        }
      </PageLayout.CenterNavExtensions>
      <PageLayout.Content>
        <div className={styles.container}>
          {isLoading && (
            <div className={styles.mapLoader}>
              <BallGridLoader />
            </div>
          )}
          <div className={`${styles.mapAndControlsContainer} insight-map-container`}>
            <div className={styles.treeListTableContainer} style={panelLayout.current.getTableContainerStyle()}>
              <Workspace
                setTableDragDistance={setTableDragDistance}
                open={urlContext.getTreeTableOpen()}
                filters={selectedTreeFilters}
                properties={selectedTreeFilterProperties}
                panelLayoutRef={panelLayout.current}
                setTableResizeTrigger={setTableResizeTrigger}
                windSpeed={urlContext.getWindSpeed() || account.getDefaultWindSpeed()}
                areaFilterIsSelected={selectedManagedAreaIds.length > 0}
              />
            </div>

            {!propertyConfigs.isLoading &&
              <div className={styles.mapContainer} style={panelLayout.current.getMapContainerStyle()}>
                <MapViewer
                  tableShouldSnapToTop={panelLayout.current.tableShouldSnapToTop()}
                  resizeTriggerKey={mapResizeTriggerKey}
                  mapResetTriggerKey={{ on: 'tree' }}
                  organization={account.organization}
                  selectedManagedAreas={selectedManagedAreas}
                  managedAreaIdsInURL={managedAreaIdsInURL}
                  treeDisplayConfiguration={treeDisplayConfiguration}
                  selectedPropertyConfig={propertyConfigs.data!.find(it => it.property === selectedTreeProperty) ?? null}
                  selectedTreePropertyRangeIndex={-1}
                  style={mapStyle}
                  selectedTreeId={treeId}
                  treeClusteringPieChartProperty={selectedTreeProperty}
                  showIsoMap={urlContext.isIsoMapEnabled()}
                  onLoading={setLoadingState}
                />
              </div>}
          </div>
        </div>
        {urlContext.getReportOpen() &&
          <ReportDialog
            onHide={() => urlContext.setReportOpen(false)}
            managedAreas={selectedManagedAreas}
            filters={selectedTreeFilters}
          />}
      </PageLayout.Content>
      {rightPanelContent === RightPanelContent.FILTER_PANEL &&
        <PageLayout.RightPanel>
          <CarbonFilterSelector />
        </PageLayout.RightPanel>
      }
      {rightPanelContent === RightPanelContent.QUICK_DETAILS_PANEL &&
        <PageLayout.RightPanel>
          <QuickTreeDetails />
        </PageLayout.RightPanel>
      }
      {urlContext.getFAQPanelOpen() &&
        <PageLayout.RightPanel>
          <FAQPanel />
        </PageLayout.RightPanel>
      }
    </PageLayout>
  );
}

function getCurrentMapStyle(mapStyleFromContext: MapStyle) {
  return Object.values(MapStyle).includes(mapStyleFromContext) ? mapStyleFromContext : MapStyle.Default;
}
