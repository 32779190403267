import DependencyInjectionContext from '../DependencyInjectionContext';
import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import CapturePoint from './CapturePoint';
import DetailedTree from '../tree/DetailedTree';
import * as THREE from 'three';
import MatrixUtils from '../utils/MatrixUtils';
import { newVector3FromLocalCoords } from '../utils/ThreeJsHelpers';

export function useCapturePoints(organizationId:string, tree: DetailedTree | null): {
  bestCapturePoint: CapturePoint | null,
  capturePoints: CapturePoint[],
  isLoading: boolean,
  isError: boolean
} {
  const { capturePointService } = useContext(DependencyInjectionContext);

  const { data, isLoading, isError } = useQuery(
    `capture-points-${organizationId}-${tree?.id}`,
    () => {
      if (tree) {
        return capturePointService.listVisibleFrom(
          organizationId,
          tree.localizedLocation,
          tree?.height ? Math.max(tree.height * 2, 25) : 25,
          tree?.recordingDate
        );
      }
    },
    { retry: 1, retryOnMount: false, refetchOnMount: false, keepPreviousData: true }
  );

  const bestCapturePointId = useMemo(() => {
    if (!isLoading && data?.length && tree) {
      const relativeCameraPosition = new THREE.Vector3(tree.metricHeight, 0, 0).applyMatrix3(MatrixUtils.degToYRotationMatrix(tree.canopyDirection));
      const treePosition = newVector3FromLocalCoords(tree.localizedLocation);
      const idealCameraPositionFront = treePosition.clone().add(relativeCameraPosition);
      const idealCameraPositionBack = treePosition.clone().sub(relativeCameraPosition);
      const sortedCapturePoints = data
        .filter(it => distanceTo(it, treePosition) > tree.metricHeight)
        .map(it => ({ ...it, distance: Math.min(distanceTo(it, idealCameraPositionFront), distanceTo(it, idealCameraPositionBack)) }))
        .sort((a, b) => a.distance - b.distance);
      // TODO: return the best capture point instead of only id

      return sortedCapturePoints[0].id;
    }
  }, [data, isLoading, tree]);

  const bestCapturePoint = useMemo(() => {
    if (bestCapturePointId) {
      return data?.find(it => it.id === bestCapturePointId) || null;
    }
    return null;
  }, [bestCapturePointId]);

  const capturePoints = useMemo(() => {
    if (!isLoading && data?.length && tree && bestCapturePoint) {
      const capturePointsWithAngles = data.map(it => {
        const relative = new THREE.Vector2(it.location[0] - tree.localizedLocation[0], it.location[1] - tree.localizedLocation[1]);
        const capturePoint = new THREE.Vector2(bestCapturePoint.location[0], bestCapturePoint.location[1]);
        const camera = capturePoint.clone().sub(new THREE.Vector2(tree.localizedLocation[0], tree.localizedLocation[1]));
        const angle = relative.angle();
        const cameraAngle = camera.angle();

        const angleDiffRadians = (angle - cameraAngle + Math.PI * 2) % (Math.PI * 2);
        const angleDiff = (angleDiffRadians * 180) / Math.PI;

        return { capturePoint: it, angleDiff };
      }).sort((a, b) => a.angleDiff - b.angleDiff);

      const sortedCapturePoints = capturePointsWithAngles.map(it => it.capturePoint);
      for (let i = 0; i < capturePointsWithAngles.length; i++) {
        const cp = capturePointsWithAngles[i].capturePoint;

        const closestLeftCpId = findClosestCpIdInRange(new Array(Math.round(sortedCapturePoints.length / 2)).fill(0).map((zero, idx) => i - 1 - idx), sortedCapturePoints, cp);
        const closestRightCpId = findClosestCpIdInRange(new Array(Math.round(sortedCapturePoints.length / 2)).fill(0).map((zero, idx) => i + 1 + idx), sortedCapturePoints, cp);

        sortedCapturePoints[i].leftNeighborId = closestLeftCpId;
        sortedCapturePoints[i].rightNeighborId = closestRightCpId;
      }

      return sortedCapturePoints;
    } else {
      return [];
    }
  }, [data]);

  return {
    bestCapturePoint,
    capturePoints,
    isLoading,
    isError
  };
}

function distanceTo(capturePoint: { location: [number, number, number]}, otherLocation: THREE.Vector3): number {
  return newVector3FromLocalCoords(capturePoint.location).distanceTo(otherLocation);
}

function findClosestCpIdInRange(range: number[], capturePointsWithAngles: { id: string, location: [number, number, number] }[], cp: { location: [number, number, number], id: string }) {
  let closestDistance = Infinity;
  let closestCpId = '';
  for (const index of range) {
    const otherCp = capturePointsWithAngles[(index + capturePointsWithAngles.length) % capturePointsWithAngles.length];
    if (cp.id === otherCp.id) continue;
    const distanceToCp = new THREE.Vector3(...cp.location).distanceTo(new THREE.Vector3(...otherCp.location));
    if (distanceToCp < closestDistance) {
      closestDistance = distanceToCp;
      closestCpId = otherCp.id;
    }
  }
  return closestCpId;
}
