import axios from 'axios';

export class TreeImage {
  constructor(readonly url: string) {
  }

  getRotatedUrl() {
    return this.url.replace('_large.jpg', '_rotated.jpg');
  }

  getThumbnailUrl() {
    return this.url.replace('_large.jpg', '_xs_rotated.jpg');
  }

  async getThumbnailUrlFromLayers() {
    try {
      if (this.url.includes('/single_images')) {
        return this.url;
      }

      if (!this.url.includes('/panoramas/')) {
        return this.url;
      }
      const tileUrl = this.url?.replace('panoramas', 'panorama-tiles')
        ?.replace('.jpg', '/tiles.json') || '';

      const layers = await axios.get(tileUrl, {
        withCredentials: true
      })
        .then((response: any) => response.data)
        .then((data: any) => data.layers || []);

      const singleTileLayer = layers
        .filter((item: any) => item.items?.length === 1)
        .reduce((acc: any, item: any) => {
          if (!acc) return item;

          return (acc.id <= item.id) ? item : acc;
        });

      return this.url
        ?.replace('panoramas', 'panorama-tiles')
        ?.replace('.jpg', `/${singleTileLayer?.items?.[0]?.path}`);
    } catch (e) {
      //console.log(e);
    }
  }
}
