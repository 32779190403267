import { Latitude, Longitude, SafetyFactor, Tree } from './Tree';
import { Organization } from '../organization/Organization';
import { ManagedArea } from '../managed-area/ManagedArea';
import { Cohort } from '../routes/LegacyDetails/LegacyDetails';

export type TseTreeScan = {
  id: string,
  treeId: string,
  tree: Tree,
  externalId: string,
  scanInterval: string,
  organizationId: string,
  createdAt: string,
  organization: Organization,
  managedAreaId: string,
  managedArea: ManagedArea,
  cohortId: string,
  cohort: Cohort,
  localizedLocation: { coordinates: [Longitude, Latitude, number] },
  canopyHeight: number,
  canopyWidth: number,
  trunkDiameter: number,
  height: number,
  trunkHeight: number,
  leafAreaIndex: number,
  safetyFactors: SafetyFactor[],
  safetyFactorAtDefaultWindSpeed: number,
  dieback: number,
  avoidedRunoff: number,
  co: number,
  grossCarbonSequestration: number,
  oxygenProduction: number,
  pm25: number,
  so2: number,
  waterIntercepted: number,
  carbonStorage: number,
  evaporation: number,
  no2: number,
  o3: number,
  potentialEvapotranspiration: number,
  transpiration: number,
  images: string[],
  pointCloudPath?: string
};

export function getEndYearFromScanInterval(year: string) {
  return year.split(',')[1].slice(0, 4);
}

export function parseScanIntervalToBlobPath(year: string) {
  return year.replace(',', '_').replace('[', '').replace(')', '');
}
