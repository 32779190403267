import DetailedTree from '../../../tree/DetailedTree';

export class ChartCalculator {
  private readonly structureProperties: ChartProperty[];
  private readonly healthAndVitalityProperties: ChartProperty[];
  private readonly clearancesProperties: ChartProperty[];
  private readonly outlierProperties: ChartProperty[];

  constructor(
    readonly tree: DetailedTree,
    readonly clearancesFlipper: boolean
  ) {
    this.structureProperties = [
      { label: 'Trunk stability (base)', value: (this.tree.safetyFactorAt80Kmh !== null && this.tree.safetyFactorAt80Kmh !== undefined) ? this.tree.safetyFactorAt80Kmh >= 3 : null },
      { label: 'Trunk stability (final)', value: null },
      { label: 'Leaning angle', value: (this.tree.leaningAngle !== null && this.tree.leaningAngle !== undefined) ? this.tree.leaningAngle <= 30 : null },
      { label: 'Slenderness', value: (this.tree.ageClass && this.tree.slenderness !== null && this.tree.slenderness !== undefined) ? (this.tree.ageClass === 'young' || this.tree.slenderness <= 200) : null },
      { label: 'Dieback', value: (this.tree.dieback !== null && this.tree.dieback !== undefined) ? this.tree.dieback <= 60 : null }
    ];
    this.healthAndVitalityProperties = [
      { label: 'Dieback', value: (this.tree.dieback !== null && this.tree.dieback !== undefined) ? this.tree.dieback <= 60 : null },
      { label: 'Crown Transparency', value: this.tree.crownTransparency ? !['dead', 'severelyDefoliated'].includes(this.tree.crownTransparency) : null },
      { label: 'Parasite(s)', value: null },
      { label: 'Disease(s)', value: null },
      { label: 'NDVI', value: (this.tree.ndvi !== null && this.tree.ndvi !== undefined) ? this.tree.ndvi >= 0.5 : null }
    ];
    this.clearancesProperties = [
      { label: 'Wire safe', value: this.clearancesFlipper ? this.tree.wireClearanceIssueDetected === 'false' : null },
      { label: 'Road safe', value: this.clearancesFlipper ? this.tree.roadClearanceIssueDetected === 'false' : null },
      { label: 'Building safe', value: null },
      { label: 'Traffic signs safe', value: null },
      { label: 'Viaduct safe', value: null }
    ];
    this.outlierProperties = [
      { label: 'DBH vs Leaf Area', value: this.tree.outlierTrunkDiameterPerLeafArea === null ? null : !this.tree.outlierTrunkDiameterPerLeafArea },
      { label: 'Height vs Crown Volume', value: this.tree.outlierHeightPerCrownVolume === null ? null : !this.tree.outlierHeightPerCrownVolume },
      { label: 'DBH vs Height', value: this.tree.outlierTrunkDiameterPerHeight === null ? null : !this.tree.outlierTrunkDiameterPerHeight },
      { label: 'Leaf Area vs Crown Volume', value: this.tree.outlierLeafAreaPerCrownVolume === null ? null : !this.tree.outlierLeafAreaPerCrownVolume },
      { label: 'DBH vs Crown Volume', value: this.tree.outlierTrunkDiameterPerCrownVolume === null ? null : !this.tree.outlierTrunkDiameterPerCrownVolume }
    ];
  }

  getData(): ChartData {
    return {
      sections: [
        {
          label: 'Structure',
          value: this.structureProperties.filter(p => p.value).length,
          noDataCount: this.structureProperties.filter(p => p.value === null).length,
          properties: this.structureProperties
        },
        {
          label: 'Health',
          value: this.healthAndVitalityProperties.filter(p => p.value).length,
          noDataCount: this.healthAndVitalityProperties.filter(p => p.value === null).length,
          properties: this.healthAndVitalityProperties
        },
        {
          label: 'Clearances',
          value: this.clearancesProperties.filter(p => p.value).length,
          noDataCount: this.clearancesProperties.filter(p => p.value === null).length,
          properties: this.clearancesProperties
        },
        {
          label: 'Outliers',
          value: this.outlierProperties.filter(p => p.value).length,
          noDataCount: this.outlierProperties.filter(p => p.value === null).length,
          properties: this.outlierProperties
        }
      ]
    };
  }
}

export type ChartData = {
  sections: {
    label: string,
    value: number,
    noDataCount: number,
    properties: ChartProperty[]
  }[]
};

export type ChartProperty = {
  label: string,
  value: boolean | null
};
