import { Tree } from '../tree/Tree';

export default interface FilterConfig {
  filters: Filter[],
  filterGroups: FilterGroup[],
  topLevelOperator: Operator
}

export interface Filter {
  property: keyof Tree,
  condition: Condition,
  value: number | string[] | string
}

export interface FilterGroup {
  filters: Filter[],
  filterGroupOperator: Operator
}

export enum Operator {
  AND = 'AND',
  OR = 'OR'
}

export type Condition = NumericCondition | EnumCondition | StringCondition | DateRangeCondition;

export enum NumericCondition {
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan'
}

export enum EnumCondition {
  IN = 'in'
}

export enum StringCondition {
  CONTAINS = 'contains'
}

export enum DateRangeCondition {
  IN_DATE_RANGE = 'inDateRange'
}

export function isNumericCondition(condition: Condition ): condition is NumericCondition {
  return Object.values(NumericCondition).includes(condition as NumericCondition);
}

export function isEnumCondition(condition: Condition ): condition is EnumCondition {
  return Object.values(EnumCondition).includes(condition as EnumCondition);
}

export function isDateRangeCondition(condition: Condition ): condition is DateRangeCondition {
  return Object.values(DateRangeCondition).includes(condition as DateRangeCondition);
}

export function isValid(filter: Filter): boolean {
  return filter.property as string !== '' && filter.condition as string !== '' && (filter.value instanceof Array || !isNaN(Number(filter.value)) || typeof filter.value === 'string');
}

export function getProperties(filterConfig: FilterConfig): string[] {
  return Array.from(new Set([
    ...filterConfig.filters.map(it => it.property as string),
    ...filterConfig.filterGroups.flatMap(it => it.filters.map(filter => filter.property as string))
  ]));
}
