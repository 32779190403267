import React, { useState } from 'react';
import { Draggable } from '@carbon/icons-react';

export type SortableItem = {
  id: string,
  content: string
};

export function SortableList({ items, setItems }: {
  items: SortableItem[],
  setItems: (items: SortableItem[]) => void
}) {
  const [draggedItem, setDraggedItem] = useState<{ id: string, content: string } | null>(null);

  const handleDragStart = (e, index) => {
    setDraggedItem(items[index]);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    const draggedOverItem = items[index];

    if (draggedItem === draggedOverItem) {
      return;
    }

    const itemsCopy = items.filter(item => item !== draggedItem);
    itemsCopy.splice(index, 0, draggedItem!);

    setItems(itemsCopy);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  return (
    <ul>
      {items.map((item, index) => (
        <li
          key={item.id}
          draggable
          onDragStart={e => handleDragStart(e, index)}
          onDragOver={e => handleDragOver(e, index)}
          onDragEnd={handleDragEnd}
          className="flex gap-4 items-center transition-none select-none py-3 px-2 mt-[-1px] text-xs bg-[var(--cds-layout)] border-collapse border-b border-t border-0 border-solid border-[var(--cds-border-subtle)] cursor-move text-[var(--cds-text-primary)]"
        >
          <Draggable /> {item.content}
        </li>
      ))}
    </ul>
  );
}
