import { useContext } from 'react';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { DisplayableTreeProperty, Tree } from '../tree/Tree';
import { Flippers } from '../switches/Flippers';
import { useCurrentAccount } from '../account/useAccounts';
import { Organization } from '../organization/Organization';
import { StaticQueryKeys } from '../StaticQueryKeys';

const ACCOUNT_CACHE_TTL_MS = 1;
const CACHE_KEY = StaticQueryKeys.PROPERTY_CONFIGURATION;

const useCachedPropertyConfigurations = organizationId => {
  const { propertyConfigurationService } = useContext(DependencyInjectionContext);
  return useQuery(getPropertyConfigCacheKey(organizationId), () => propertyConfigurationService.list(organizationId), {
    staleTime: ACCOUNT_CACHE_TTL_MS,
    retry: 1
  });
};

export const getPropertyConfigCacheKey = organizationId => {
  return CACHE_KEY + '-' + organizationId;
};

export const usePropertyConfigurations = () => {
  const { organizationId } = useParams();
  const { data, isLoading, refetch } = useCachedPropertyConfigurations(organizationId);
  return { data: data || [], isLoading, refetch };
};

const daveyFilter = (property: DisplayableTreeProperty, organization: Organization) => {
  const hiddenPropertiesForDavey = [
    DisplayableTreeProperty.TrunkCircumference,
    DisplayableTreeProperty.ManagedAreaId
  ];
  if (!organization.isEnabled(Flippers.davey)) return true;
  return !hiddenPropertiesForDavey.includes(property);
};

export const useAvailableProperties = () => {
  const propertyConfigs = usePropertyConfigurations();
  const { organization } = useCurrentAccount();

  const filterProperties = (property: DisplayableTreeProperty) => {
    const configs = propertyConfigs.isLoading ? [] : propertyConfigs.data!;
    return configs.some(it => it.property === property &&
      it.ranges.every(range => (!Number.isNaN(Number(range.from)) && !Number.isNaN(Number(range.to))) || range.value)
    );
  };

  return {
    metrical: Tree.METRICAL_PROPERTIES.filter(it => filterProperties(it) && daveyFilter(it, organization)),
    safety: Tree.SAFETY_PROPERTIES,
    ecosystem: Tree.ECOSYSTEM_PROPERTIES.filter(filterProperties),
    health: Tree.HEALTH_INDICATIONS.filter(filterProperties),
    economical: Tree.ECONOMICAL_VALUE.filter(filterProperties),
    identification: Tree.IDENTIFICATIONS.filter(filterProperties).filter(it => daveyFilter(it, organization)),
    outlier: Tree.OUTLIER_PROPERTIES.filter(filterProperties),
    clearances: Tree.CLEARANCE_PROPERTIES.filter(filterProperties)
  };
};

export const useLegacyAvailableColumnSelectorProperties: (includeOutliers?: boolean) => LegacyAvailablePropertiesMap = (includeOutliers = false) => {
  const { organization } = useCurrentAccount();
  return {
    metrical: Tree.METRICAL_PROPERTIES.filter(it => daveyFilter(it, organization)),
    safety: Tree.SAFETY_PROPERTIES,
    ecosystem: Tree.ECOSYSTEM_PROPERTIES,
    health: Tree.HEALTH_INDICATIONS,
    economical: Tree.ECONOMICAL_VALUE,
    identification: [
      ...Tree.IDENTIFICATIONS,
      // TODO: Move ManagedAreaId to Tree.IDENTIFICATIONS when deleting Flippers.workspace
      ...(organization.isEnabled(Flippers.workspace) ? [DisplayableTreeProperty.ManagedAreaId] : [])].filter(it => daveyFilter(it, organization)),
    outlier: includeOutliers ? Tree.OUTLIER_PROPERTIES : [],
    clearance: organization.isEnabled(Flippers.wireClearance) ? Tree.CLEARANCE_PROPERTIES : []
  };
};

export const useAvailableColumnSelectorProperties: () => AvailablePropertiesMap = () => {
  const { organization } = useCurrentAccount();
  return {
    additionalInformation: Tree.INFO_PROPERTIES,
    assignmentAndTreeId: Tree.ASSIGNMENT_PROPERTIES.filter(it => daveyFilter(it, organization)),
    benefits: Tree.BENEFIT_PROPERTIES,
    clearances: Tree.CLEARANCE_PROPERTIES,
    conclusions: Tree.CONCLUSION_PROPERTIES,
    dates: Tree.DATES,
    dimensions: Tree.DIMENSION_PROPERTIES.filter(it => daveyFilter(it, organization)),
    treeHealthAndVitality: Tree.HEALTH_AND_VITALITY_PROPERTIES,
    loadFactors: Tree.LOAD_FACTOR_PROPERTIES,
    outliers: Tree.OUTLIER_PROPERTIES,
    siteFactors: Tree.SITE_FACTOR_PROPERTIES,
    structuralAssessment: Tree.STRUCTURAL_PROPERTIES,
    treeHealthAndSpeciesProfile: Tree.HEALTH_AND_SPECIES_PROPERTIES
  };
};

export const useAvailableFilterItemProperties = () => {
  const { organization } = useCurrentAccount();
  return {
    metricalInfo: Tree.METRICAL_PROPERTIES.filter(it => daveyFilter(it, organization)),
    safety: Tree.SAFETY_PROPERTIES.filter(it => it !== DisplayableTreeProperty.SafetyFactors),
    ecosystemServices: Tree.ECOSYSTEM_PROPERTIES,
    healthIndication: Tree.HEALTH_INDICATIONS,
    economicalValue: Tree.ECONOMICAL_VALUE,
    inspections: [...Tree.INSPECTIONS],
    identification: Tree.IDENTIFICATIONS.filter(it => daveyFilter(it, organization)),
    outlierProperties: Tree.OUTLIER_PROPERTIES,
    clearance: organization.isEnabled(Flippers.wireClearance) ? Tree.CLEARANCE_PROPERTIES : []
  };
};

export const useTreeInfoProperties = () => {
  const { organization } = useCurrentAccount();

  const dimensionProperties = [
    DisplayableTreeProperty.Height,
    DisplayableTreeProperty.TrunkHeight,
    DisplayableTreeProperty.CanopyHeight,
    DisplayableTreeProperty.CanopyWidth,
    DisplayableTreeProperty.TrunkCircumference,
    DisplayableTreeProperty.TrunkDiameter,
    DisplayableTreeProperty.CanopyCircumference
  ].filter(it => daveyFilter(it, organization));

  const ecosystemProperties = [
    DisplayableTreeProperty.CarbonStorage,
    DisplayableTreeProperty.GrossCarbonSequestration,
    DisplayableTreeProperty.NO2,
    DisplayableTreeProperty.SO2,
    DisplayableTreeProperty.PM25,
    DisplayableTreeProperty.CO,
    DisplayableTreeProperty.O3,
    DisplayableTreeProperty.PotentialEvapotranspiration,
    DisplayableTreeProperty.Transpiration,
    DisplayableTreeProperty.OxygenProduction,
    DisplayableTreeProperty.AvoidedRunoff,
    DisplayableTreeProperty.Evaporation,
    DisplayableTreeProperty.WaterIntercepted
  ];

  const healthProperties = [
    DisplayableTreeProperty.LeafArea,
    DisplayableTreeProperty.LeafAreaIndex,
    DisplayableTreeProperty.NDVI,
    DisplayableTreeProperty.Dieback,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.VitalityVigor,
    DisplayableTreeProperty.LeafAreaPerCrownVolume,
    DisplayableTreeProperty.LiveCrownRatio
  ];

  const safetyProperties = [
    DisplayableTreeProperty.SafetyFactors,
    DisplayableTreeProperty.LeaningAngle,
    DisplayableTreeProperty.Slenderness
  ];
  return {
    dimensionProperties, ecosystemProperties, healthProperties, safetyProperties
  };
};

export type LegacyAvailablePropertiesMap = {
  metrical: DisplayableTreeProperty[],
  safety: DisplayableTreeProperty[],
  ecosystem: DisplayableTreeProperty[],
  health: DisplayableTreeProperty[],
  economical: DisplayableTreeProperty[],
  identification: DisplayableTreeProperty[],
  outlier: DisplayableTreeProperty[]
};

export type AvailablePropertiesMap = {
  dimensions: DisplayableTreeProperty[],
  structuralAssessment: DisplayableTreeProperty[],
  benefits: DisplayableTreeProperty[],
  treeHealthAndSpeciesProfile: DisplayableTreeProperty[],
  assignmentAndTreeId: DisplayableTreeProperty[],
  clearances: DisplayableTreeProperty[],
  outliers: DisplayableTreeProperty[],
  conclusions: DisplayableTreeProperty[],
  loadFactors: DisplayableTreeProperty[],
  siteFactors: DisplayableTreeProperty[],
  treeHealthAndVitality: DisplayableTreeProperty[],
  additionalInformation: DisplayableTreeProperty[],
  dates: DisplayableTreeProperty[]
};
