import styles from './GraphModal.module.scss';
import PieChart from './charts/PieChart';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import BarChart from './charts/BarChart';
import { useCallback, useContext, useEffect, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { usePropertyConfigurations } from '../../../properties/usePropertyConfigurations';
import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import { useTranslation } from 'react-i18next';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import { getProperties } from '../../../filter/FilterConfig';
import { Button, ContentSwitcher, IconSwitch, Switch, Tooltip } from '@carbon/react';
import { ChartColumn, ChartPie, Close, Percentage, Tree as TreeIcon } from '@carbon/icons-react';
import Modal from './Modal/Modal';

export type NumericDistributionPoint = { key: string, value: number, percentage: number };
export type LabelDistributionPoint = { key: string, value: number, percentage: number };
type EnumDistributionPoint = { key: string, value: number, percentage: number };

export type GraphDto = {
  numericDistribution: NumericDistributionPoint[] | null,
  labelDistribution: LabelDistributionPoint[] | null,
  enumDistribution: EnumDistributionPoint[] | null
};

const dataTypeItems = [
  { id: 'numericDistribution', translationKey: 'workspace.graphModal.dataTypes.numericDistribution' },
  { id: 'labelDistribution', translationKey: 'workspace.graphModal.dataTypes.labelDistribution' }
];

export const graphModalColors = [
  '#2D5050',
  '#4AA35F',
  '#5FCC7D',
  '#A5E148',
  '#F5BD43',
  '#E47E30',
  '#DB3E34',
  '#54B5CC',
  '#2C678B',
  '#5F7078'
];

const numericDistributionDisabled = [
  DisplayableTreeProperty.PlantingYear,
  DisplayableTreeProperty.NumberOfStems,
  DisplayableTreeProperty.Condition,
  DisplayableTreeProperty.CrownTransparency,
  DisplayableTreeProperty.GrowSpace,
  DisplayableTreeProperty.OverheadUtilities,
  DisplayableTreeProperty.PrevailingWindDirection,
  DisplayableTreeProperty.Fork,
];

export default function GraphModal(props: GraphModalProps) {
  const { t } = useTranslation();
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const managedAreaIds = urlContext.getManagedAreaIds();
  const fields = urlContext.getVisibleTableProperties();
  const reverseMASelection = urlContext.getReverseMASelection();
  const filterConfiguration = urlContext.getFilterConfiguration();
  const [chartData, setChartData] = useState<GraphDto | null>(null);
  const [dataType, setDataType] = useState<string>(numericDistributionDisabled.includes(props.property) ? 'labelDistribution' : 'numericDistribution');
  const [chartType, setChartType] = useState<'barChart' | 'pieChart'>('barChart');
  const [valueType, setValueType] = useState<'count' | 'percent'>('count');
  const propertyConfigs = usePropertyConfigurations();

  const getColorMap = (config?: PropertyConfiguration) => {
    if (dataType === 'numericDistribution' || !config) return graphModalColors;
    return PropertyColorConfiguration.getColorsForConfig(config).map(it => `rgb(${it})`);
  };

  const getDataTypeItems = useCallback(() => {
    return dataTypeItems.map(item => {
      if (item.id === 'labelDistribution' && !chartData?.labelDistribution?.length) {
        return { ...item, disabled: true };
      }
      if (item.id === 'numericDistribution' && numericDistributionDisabled.includes(props.property)) {
        return { ...item, disabled: true };
      }
      return { ...item, disabled: false };
    });
  }, [chartData?.labelDistribution]);

  useEffect(() => {
    (async () => {
      const fieldsToFetch = Array.from(new Set([
        ...(fields || []),
        ...getProperties(filterConfiguration),
        props.property
      ])) as (keyof Tree)[];
      const result = await treeService.fetchGraphData(
        organization.id,
        managedAreaIds,
        reverseMASelection,
        fieldsToFetch,
        filterConfiguration,
        props.taskOrTaskTemplateId,
        props.property as keyof Tree,
        Tree.isEnumProperty(props.property)
      );
      const resultSorted = {
        ...result,
        enumDistribution: sortEnumDistribution(replaceKeys(addLabelsForEmptyCategories(result.enumDistribution, props.property), props.property, props.managedAreas))
      };
      setChartData(resultSorted);
    })();
  }, [props.property]);

  return (
    <Modal onHide={props.onClose} isVisible={true} className="py-4 px-8 !border-none shadow-2xl">
      <div>
        <div className="mb-8 flex justify-between items-center border-solid border-0 border-b border-[var(--cds-text-priamry)]">
          <h3 className="text-[var(--cds-text-primary)] font-normal text-xl m-0">{t('workspace.graphModal.title')} <u>{Tree.renderPropertyName(props.property, t)}</u></h3>
          <Tooltip label={t('tooltips.close')} align="left">
            <Button
              onClick={props.onClose}
              kind="ghost"
            >
              <Close/>
            </Button>
          </Tooltip>
        </div>
        <div className="flex justify-between">
          <div className="flex justify-between gap-6">
            <div className="w-[256px]">
              <ContentSwitcher
                onChange={item => setDataType(item.name as string)}
                selectedIndex={dataTypeItems.findIndex(it => it.id === dataType)}
                size="lg"
              >
                {getDataTypeItems().map(item => (
                  <Switch
                    key={item.id}
                    name={item.id}
                    text={t(item.translationKey)}
                    disabled={item.disabled}
                  />
                ))}
              </ContentSwitcher>
            </div>
            <div>
              <ContentSwitcher
                onChange={item => setChartType(item.name as ('barChart' | 'pieChart'))}
                selectedIndex={0}
                size="lg"
              >
                <IconSwitch
                  key="barChart"
                  name="barChart"
                  text={t('workspace.graphModal.columnChart')}
                >
                  <ChartColumn/>
                </IconSwitch>
                <IconSwitch
                  key="pieChart"
                  name="pieChart"
                  text={t('workspace.graphModal.pieChart')}
                >
                  <ChartPie/>
                </IconSwitch>
              </ContentSwitcher>
            </div>
          </div>
          <div className="w-28">
            <ContentSwitcher
              onChange={item => setValueType(item.name as ('count' | 'percent'))}
              selectedIndex={0}
              size="lg"
            >
              <IconSwitch
                key="count"
                name="count"
                text={t('workspace.graphModal.piece')}
              >
                <TreeIcon />
              </IconSwitch>
              <IconSwitch
                key="percent"
                name="percent"
                text={t('workspace.graphModal.percentage')}
              >
                <Percentage />
              </IconSwitch>
            </ContentSwitcher>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {chartData && (
          <div className={styles.chartContainer}>
            {chartType === 'pieChart' ?
              <PieChart
                data={Tree.isEnumProperty(props.property) ? chartData.enumDistribution! : chartData[dataType]}
                displayPercentage={valueType === 'percent'}
                property={props.property}
              /> :
              <BarChart
                property={props.property}
                data={Tree.isEnumProperty(props.property) ? chartData.enumDistribution! : chartData[dataType]}
                displayPercentage={valueType === 'percent'}
                barColors={getColorMap(propertyConfigs.data.find(it => it.property === props.property))}
              />}
          </div>
        )}
      </div>
    </Modal>
  );
}

function addLabelsForEmptyCategories(enumDistribution: EnumDistributionPoint[] | null, property: DisplayableTreeProperty): EnumDistributionPoint[] | null {
  if (!enumDistribution) return null;
  if (enumDistribution.length === (Tree.getEnumOptions(property).length) + 1) return enumDistribution;
  const enumOptions = Tree.getEnumOptions(property);
  const keys = enumDistribution.map(it => it.key);
  const missingKeys = enumOptions.filter(option => !keys.includes(option));
  const missingValues = missingKeys.map(key => ({ key, value: 0, percentage: 0 }));
  const noData = enumDistribution.find(it => it.key === 'noData');
  if (noData) return [...enumDistribution.filter(it => it.key !== 'noData'), ...missingValues, noData];
  return [...enumDistribution.filter(it => it.key !== 'noData'), ...missingValues];
}

function replaceKeys(enumDistribution: EnumDistributionPoint[] | null, property: string, managedAreas: {
  id: string,
  code: string
}[]): EnumDistributionPoint[] | null {
  if (!enumDistribution) return null;
  if (property === 'managedAreaId') return enumDistribution.map(it => ({
    key: managedAreas.find(area => area.id === it.key)?.code ?? it.key,
    value: it.value,
    percentage: it.percentage
  }));
  return enumDistribution;
}

function sortEnumDistribution(enumDistribution: EnumDistributionPoint[] | null): EnumDistributionPoint[] | null {
  if (!enumDistribution) return null;
  if (enumDistribution.length < 10) return enumDistribution;
  const sorted = [...enumDistribution];
  sorted.sort((a, b) => b.value - a.value);
  const rest = sorted.splice(10);
  return [...sorted, ...collectTheRest(rest)];
}

function collectTheRest(enumDistribution: EnumDistributionPoint[]): [EnumDistributionPoint] | [] {
  if (enumDistribution.length === 0) return [];

  return [{
    key: 'other',
    value: enumDistribution.reduce((acc, it) => acc + it.value, 0),
    percentage: enumDistribution.reduce((acc, it) => acc + it.percentage, 0)
  }];
}

interface GraphModalProps {
  onClose: () => void,
  property: DisplayableTreeProperty,
  managedAreas: { id: string, code: string }[],
  taskOrTaskTemplateId?: string
}
