import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useAvailableColumnSelectorProperties } from '../../../properties/usePropertyConfigurations';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import MultiSelectDropdown from './MultiSelectDropdown';

export default function ColumnSelector(props: { disabled: boolean }) {
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const properties = useAvailableColumnSelectorProperties();
  const selectedItems = urlContext.getVisibleTableProperties();
  let isChangeHandlerCalled = false;

  const options = Object.entries(properties).map(([groupName, groupItems]) => ({
    name: groupName,
    label: t(`treePropertySelector.${groupName}`),
    options: groupItems
      .filter(item => item !== DisplayableTreeProperty.SafetyFactors)
      .map(item => ({ label: Tree.renderPropertyName(item, t), value: item }))
      .sort((a, b) => a.label.localeCompare(b.label))
  })).sort((a, b) => a.label.localeCompare(b.label));

  const selectedItemsSet = new Set(selectedItems);

  const handleChange = (newSelectedItems: Set<string>) => {
    if (isChangeHandlerCalled) return;
    isChangeHandlerCalled = true;
    urlContext.setVisibleTableProperties(Array.from(newSelectedItems as Set<DisplayableTreeProperty>));
  };

  return (
    <div className="flex bg-[var(--cds-field)]">
      <MultiSelectDropdown
        options={options}
        defaultSelection={[DisplayableTreeProperty.Genus, DisplayableTreeProperty.Species, DisplayableTreeProperty.Height, DisplayableTreeProperty.Status, DisplayableTreeProperty.TrunkDiameter]}
        selectedItems={selectedItemsSet}
        onChange={handleChange}
        disabled={props.disabled}
      />
    </div>
  );
}
