import * as THREE from 'three';
import { Object3D } from 'three';
import SphereControls from '../../../components/Panorama/SphereControls';
import { generateMultiResolutionMeshFromImage, Image } from './meshGenerator';
import MultiResolutionMesh from './MultiResolutionMesh';
import { LoadablePointCloud } from '../../../point-cloud/LoadablePointCloud';
import { Tree } from '../../../tree/Tree';
import { Measurement } from '../../../measurement/Measurement';
import { removeObjectFromScene } from '../../../utils/ThreeJsHelpers';
import { CSS3DObject, CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import styles from './PanoramicViewLight.module.scss';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import PanoramicSphere from './PanoramicSphere';
import { METER_TO_FEET } from '../../../components/PointCloud/unitConstants';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import LShapeRuler from '../../LegacyDetails/TreeDisplayTile/twin-view/LShapeRuler';
import DetailedTree from '../../../tree/DetailedTree';
import { Organization } from '../../../organization/Organization';
import { SpherePanoramaObject } from '../../../components/Panorama/FullPanoramaSphere/SpherePanoramaObject';
import PanoramaImageSphere from '../../../components/Panorama/FullPanoramaSphere/PanoramaImageSphere';
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';

export class PanoramicViewLight {
  static create(canvas: HTMLCanvasElement, styles: Record<string, string>, isMetric: boolean): PanoramicViewLight {
    const renderer = new THREE.WebGLRenderer({ canvas, preserveDrawingBuffer: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor('#293336');

    const css3DRenderer = new CSS3DRenderer();
    css3DRenderer.domElement.style.position = 'absolute';
    css3DRenderer.domElement.style.top = '0px';
    css3DRenderer.domElement.style.left = '0px';
    css3DRenderer.domElement.style.zIndex = '1';

    const htmlRenderer = new CSS2DRenderer();
    htmlRenderer.domElement.style.position = 'absolute';
    htmlRenderer.domElement.style.top = '0px';
    htmlRenderer.domElement.style.left = '0px';

    canvas.parentElement!.appendChild(htmlRenderer.domElement);
    canvas.parentElement!.appendChild(css3DRenderer.domElement);

    const controls = new SphereControls();

    const camera = new THREE.PerspectiveCamera(controls.MAX_FOV, canvas.clientWidth / canvas.clientHeight, 1, 1100);
    camera.rotation.order = 'YXZ';
    camera.updateProjectionMatrix();

    const scene = new THREE.Scene();

    const measurement = new Measurement(styles, isMetric);

    return new PanoramicViewLight(canvas, renderer, css3DRenderer, htmlRenderer, controls, camera, scene, measurement);
  }

  private lShape: THREE.Group | null = null;
  private lShapeRuler: THREE.Group | null = null;
  private raycaster = new THREE.Raycaster();
  private sphere: PanoramicSphere | null = null;
  private newSphere: SpherePanoramaObject | null = null;
  private capturePointMarkers: CSS3DObject[] = [];
  private dome: THREE.Group | null = null;
  private rootZone: THREE.Group | null = null;
  private crz: THREE.Group | null = null;
  private scrz: THREE.Group | null = null;
  private pointCloud: THREE.Group | null = null;
  public originalPointCloud: LoadablePointCloud | null = null;
  private environmentPointCloud: THREE.Group | null = null;
  public originalEnvironmentPointCloud: LoadablePointCloud | null = null;
  private treePosition: any;
  public readonly cameraFacingGroup = new THREE.Group();
  private criticalPointClouds = new THREE.Group();
  private wireGroup = new THREE.Group();

  constructor(
    private readonly canvas: HTMLCanvasElement,
    readonly renderer: THREE.WebGLRenderer,
    readonly css3DRenderer: CSS3DRenderer,
    readonly htmlRenderer: CSS2DRenderer,
    private readonly controls: SphereControls,
    readonly camera: THREE.PerspectiveCamera,
    readonly scene: THREE.Scene,
    readonly measurement: Measurement
  ) {
    this.scene.add(this.criticalPointClouds);
    this.scene.add(this.wireGroup);
  }

  private rotationCallback: () => void = () => {};
  private resizeListener: (() => void) = () => {};
  private renderListener: (() => void) = () => {};
  private mouseMoveListener: ((event: MouseEvent) => void) = () => {};

  render() {
    this.renderer.render(this.scene, this.camera);
    this.css3DRenderer.render(this.scene, this.camera);
    this.htmlRenderer.render(this.scene, this.camera);
  }

  clear2DRenderer() {
    this.htmlRenderer.domElement.replaceChildren();
  }

  dispose() {
    this.removePointCloud();
    this.removeEnvironmentPointCloud();
    this.scene.children.forEach(it => removeObjectFromScene(it, this.scene));
    this.scene.clear();
    if (this.css3DRenderer.domElement.parentElement === this.canvas.parentElement) {
      this.canvas.parentElement?.removeChild(this.css3DRenderer.domElement);
    }
    if (this.htmlRenderer.domElement.parentElement === this.canvas.parentElement) {
      this.canvas.parentElement?.removeChild(this.htmlRenderer.domElement);
    }
    this.renderer.dispose();
  }

  addCameraFacingObjects(cameraFacingObjects: Object3D[]) {
    if (cameraFacingObjects.length === 0) return;
    this.cameraFacingGroup.add(...cameraFacingObjects);
    this.cameraFacingGroup.children.forEach(marker => marker.lookAt(this.camera.position));
    this.render();
  }

  removeCameraFacingObjects(cameraFacingObjects: Object3D[]) {
    this.cameraFacingGroup.remove(...cameraFacingObjects);
  }

  async addLShape(organization: Organization, treePosition: [number, number, number]) {
    const lShapeObjectUrl = organization.getCDNUrlFromRelativePath('tasks/US_PA_PIT23_0177_A_009/infrastructure/road_clearing/4713_L_shape4.gltf');

    const response: GLTF = await new Promise((resolve, reject) => new GLTFLoader().load(lShapeObjectUrl, resolve, () => {}, reject));
    const object: THREE.Group = response.scene;
    const translation = treePosition.map(it => -it) as [number, number, number];
    object.children.forEach(it => ((it as THREE.Mesh).material = new THREE.MeshBasicMaterial({
      color: 'white',
      transparent: true,
      opacity: 0.3,
      side: THREE.DoubleSide
    })));
    object.children.forEach(it => (it as THREE.Mesh).geometry.translate(...translation));
    object.rotateX(-Math.PI / 2);
    this.lShape = object;
    this.scene.add(object);
  }

  removeLShape() {
    if (this.lShape) {
      this.scene.remove(this.lShape);
    }
  }

  addLShapeRuler(tree: DetailedTree, isMetric: boolean) {
    this.lShapeRuler = new LShapeRuler(tree.environment!.corridorLShapeVertex!.coordinates.map(coord => sub(coord, tree.localizedLocation)), isMetric).toThreeObject();
    this.scene.add(this.lShapeRuler.rotateX(-Math.PI / 2));

    function sub(a: number[], b: number[]): number[] {
      return a.map((it, i) => it - b[i]);
    }
  }

  removeLShapeRuler() {
    if (this.lShapeRuler) {
      this.lShapeRuler.clear();
      this.scene.remove(this.lShapeRuler);
    }
  }

  private handleMouseMoveInMeasurementMode = (event: MouseEvent) => {
    if (!this.scene.visible) {
      return;
    }
    const pickedPoint = this.pickPointFromPointCloud(event);
    if (pickedPoint) {
      this.measurement.setCursor(pickedPoint);
      this.render();
      this.changeCursorHover();
    } else {
      this.changeCursorDefault();
    }
  };

  private handleMouseClickInMeasurementMode = (event: MouseEvent) => {
    event.stopPropagation();

    const mouseDownAt = new THREE.Vector2(event.pageX, event.pageY);

    const mouseUpHandler = (event: Event) => {
      event.stopPropagation();
      const minimalDragDistance = 6;
      const mouseUpAt = new THREE.Vector2((event as MouseEvent).pageX, (event as MouseEvent).pageY);
      const isDrag = mouseDownAt.manhattanDistanceTo(mouseUpAt) > minimalDragDistance;
      if (!isDrag) {
        this.measurement.handleClick();
        this.render();
      }

      event.target?.removeEventListener('mouseup', mouseUpHandler);
    };
    event.target?.addEventListener('mouseup', mouseUpHandler);
  };

  pickPointFromPointCloud(event: MouseEvent) {
    if (!this.originalPointCloud) return;

    const canvasPosition = this.canvas.getBoundingClientRect();
    const mouse = new THREE.Vector2(
      ((event.clientX - canvasPosition.x) / canvasPosition.width) * 2 - 1,
      1 - ((event.clientY - canvasPosition.y) / canvasPosition.height) * 2
    );

    this.raycaster.setFromCamera(mouse, this.camera);

    const pointClouds = [this.originalPointCloud!];
    if (this.originalEnvironmentPointCloud) pointClouds.push(this.originalEnvironmentPointCloud);

    const intersects = this.raycaster.intersectObjects(pointClouds as any, true);
    return (
      intersects
        .filter(it => it.distanceToRay! < 0.1)
        .sort((a, b) => a.distanceToRay! - b.distanceToRay!)
        .at(0)?.point ?? null
    );
  }

  enableLineMeasure() {
    this.measurement.reset();
    this.scene.add(...this.measurement.getElements());
    this.css3DRenderer.domElement.addEventListener('mousedown', this.handleMouseClickInMeasurementMode);
    this.css3DRenderer.domElement.addEventListener('mousemove', this.handleMouseMoveInMeasurementMode);
  }

  disableLineMeasure() {
    this.measurement.reset();
    this.scene.remove(...this.measurement.getElements());
    this.css3DRenderer.domElement.removeEventListener('mousedown', this.handleMouseClickInMeasurementMode);
    this.css3DRenderer.domElement.removeEventListener('mousemove', this.handleMouseMoveInMeasurementMode);
  }

  listen(callback: (angle: number) => void) {
    this.rotationCallback = () => callback(this.getNorthAngle());
    this.renderListener = this.render.bind(this);
    this.enableControls();

    this.resizeListener = this.setSize.bind(this);
    window.addEventListener('resize', this.resizeListener);
  }

  setMouseMovementListener(callback: (event: MouseEvent, fov: number, rotation: number[]) => void) {
    this.resetMouseMovementListener();
    this.mouseMoveListener = (event: MouseEvent) => {
      callback(event, this.camera.fov, this.camera.rotation.toArray());
    };
    this.css3DRenderer.domElement.addEventListener('mousemove', this.mouseMoveListener);
  }

  resetMouseMovementListener() {
    this.css3DRenderer.domElement.removeEventListener('mousemove', this.mouseMoveListener);
  }

  getNorthAngle() {
    const northTarget = new THREE.Vector3(0, 0, 1);
    const v = new THREE.Vector3(0, 0, 1);
    v.applyQuaternion(this.camera.quaternion);
    v.setY(0);
    return THREE.MathUtils.radToDeg(this.convertToFullAngle(v, northTarget));
  }

  private convertToFullAngle(v1, v2) {
    const o = v1.x * v2.z - v1.z * v2.x;
    let angle = v1.angleTo(v2);
    if (o > 0) {
      angle = 2 * Math.PI - angle;
    }

    return angle;
  }

  clearListeners() {
    this.disableControls();
    window.removeEventListener('resize', this.resizeListener);
  }

  setSize() {
    if (!this.canvas) return;

    const width = this.canvas.parentElement?.clientWidth ?? 0;
    const height = this.canvas.parentElement?.clientHeight ?? 0;
    this.canvas.style.width = width + 'px';
    this.canvas.style.height = height + 'px';

    this.camera.aspect = width / height;

    this.css3DRenderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.htmlRenderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.camera.updateProjectionMatrix();
    this.render();
  }

  async setSphereAndPutCameraThere(images: Image[]) {
    if (images.length > 0 && !isNaN(images[0].cx)) {
      await this.drawSphereFromSingleImages(images);
    } else {
      await this.drawSphereFromFullPanoImage(images[0]);
    }
  }

  private async drawSphereFromSingleImages(images: Image[]) {
    const meshes = this.imagesToMeshes(images);

    const center = new THREE.Vector3(...images[0].origin.coordinates).applyAxisAngle(
      new THREE.Vector3(1, 0, 0),
      -Math.PI / 2
    ).sub(this.treePosition);

    if (this.sphere) {
      this.sphere.dispose();
      this.scene.remove(this.sphere);
      this.scene.remove(this.cameraFacingGroup);
    }
    this.sphere = new PanoramicSphere(meshes, center);
    await this.sphere.loadLowResolutionImages();
    this.scene.add(this.sphere);

    this.camera.position.copy(center);
    this.scene.add(this.cameraFacingGroup);
    this.cameraFacingGroup.children.forEach(marker => marker.lookAt(center));
    this.measurement.setOrigin(center);

    this.sphere.loadHighResolutionImages().then(this.render.bind(this));
  }

  private async drawSphereFromFullPanoImage(image: Image) {
    const center = new THREE.Vector3(...image.origin.coordinates).applyAxisAngle(
      new THREE.Vector3(1, 0, 0),
      -Math.PI / 2
    ).sub(this.treePosition);

    if (this.newSphere) {
      this.scene.remove(this.newSphere);
      this.scene.remove(this.cameraFacingGroup);
      this.newSphere.unload();
    }

    this.newSphere = new SpherePanoramaObject();
    this.scene.add(this.newSphere);

    await this.newSphere.load(PanoramaImageSphere.createFromServerResponse(image), this.render.bind(this));
    this.newSphere.position.copy(center);

    this.camera.position.copy(center);
    this.scene.add(this.cameraFacingGroup);
    this.cameraFacingGroup.children.forEach(marker => marker.lookAt(center));
    this.measurement.setOrigin(center);
  }

  private imagesToMeshes(images: Image[]): MultiResolutionMesh[] {
    return images.map(it => generateMultiResolutionMeshFromImage(it));
  }

  setTreePosition(selectedTree: Tree) {
    this.treePosition = new THREE.Vector3(...selectedTree.localizedLocation).applyAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
  }

  setPointCloud(pointCloud: LoadablePointCloud) {
    this.originalPointCloud = pointCloud;
    const standingObject = new THREE.Group().rotateX(-Math.PI / 2).add(pointCloud as any);
    this.pointCloud = standingObject;
    this.scene.add(standingObject);
  }

  setEnvironmentPointCloud(pointCloud: LoadablePointCloud) {
    this.originalEnvironmentPointCloud = pointCloud;
    const standingObject = new THREE.Group().rotateX(-Math.PI / 2).add(pointCloud as any);
    this.environmentPointCloud = standingObject;
    this.scene.add(standingObject);
  }

  addCriticalPointCloud(pointCloud: LoadablePointCloud) {
    const standingObject = new THREE.Group().rotateX(-Math.PI / 2).add(pointCloud as any);
    this.criticalPointClouds.add(standingObject);
  }

  hideTreePointCloud() {
    if (!this.pointCloud) return;
    this.pointCloud.visible = false;
  }

  hideEnvironmentPointCloud() {
    if (!this.environmentPointCloud) return;
    this.environmentPointCloud.visible = false;
  }

  disableCapturePoints() {
    this.capturePointMarkers.forEach(cp => {
      cp.element.style.pointerEvents = 'none';
    });
  }

  enableCapturePoints() {
    this.capturePointMarkers.forEach(cp => {
      cp.element.style.pointerEvents = 'auto';
    });
  }

  disableControls() {
    this.controls.dispose(this.camera, this.canvas, [
      this.renderListener,
      this.rotationCallback
    ]);
  }

  enableControls() {
    this.controls.listen(this.camera, this.css3DRenderer.domElement as HTMLCanvasElement, [
      this.renderListener,
      this.rotationCallback
    ]);
  }

  showEnvironmentPointCloud() {
    if (!this.environmentPointCloud) return;
    this.environmentPointCloud.visible = true;
  }

  removePointCloud() {
    removeObjectFromScene(this.pointCloud, this.scene);
    this.pointCloud = null;
    this.originalPointCloud = null;
  }

  removeEnvironmentPointCloud() {
    removeObjectFromScene(this.environmentPointCloud, this.scene);
    this.environmentPointCloud = null;
    this.originalEnvironmentPointCloud = null;
  }

  removeCriticalPointClouds() {
    this.criticalPointClouds.clear();
  }

  addWires(tree: DetailedTree) {
    if (!tree.aggByClassWireClearances) return;

    for (const clearance of tree.aggByClassWireClearances) {
      if (clearance.wireShape?.type === 'LineString') {
        this.wireGroup.add(this.createWire(clearance.wireShape.coordinates, tree.localizedLocation));
      } else if (clearance.wireShape?.type === 'MultiLineString') {
        clearance.wireShape.coordinates.forEach(line => {
          this.wireGroup.add(this.createWire(line, tree.localizedLocation));
        });
      }
    }
  }

  removeWires() {
    this.wireGroup.clear();
  }

  private createWire(line: [number, number, number][], treePosition: [number, number, number]) {
    return new Line2(
      new LineGeometry().setPositions(line.flatMap(it => [
        it[0] - treePosition[0],
        it[1] - treePosition[1],
        it[2] - treePosition[2]
      ])),
      new LineMaterial({
        color: 0xffffff,
        linewidth: 3,
        resolution: new THREE.Vector2(640, 480)
      })
    ).rotateX(-Math.PI / 2);
  }

  setCapturePointsAsCss3D(currentCapturePoints: { id: string, location: [number, number, number] }[], onclick: (id: string) => void) {
    if (this.capturePointMarkers.length) {
      this.scene.remove(...this.capturePointMarkers);
    }
    this.capturePointMarkers = currentCapturePoints.map(it => {
      const el = document.createElement('div');
      el.innerHTML = '<div></div>';
      el.classList.add(styles.capturePoint);
      el.onclick = () => onclick(it.id);

      const obj = new CSS3DObject(el);
      const position = new THREE.Vector3(...it.location).applyAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
      obj.rotateX(-Math.PI / 2);
      obj.position.copy(position.clone().add(new THREE.Vector3(0, -3.2, 0))).sub(this.treePosition);
      obj.renderOrder = 1;
      obj.scale.multiplyScalar(0.03);
      return obj;
    });

    this.capturePointMarkers.forEach(it => this.scene.add(it));
  }

  addRingToGroup(innerRadius: number, outerRadius: number, color: string, opacity: number, group: THREE.Group, y: number) {
    if (!group) return;
    const ringGeometry = new THREE.RingGeometry( innerRadius, outerRadius, 100 );
    const ringMaterial = new THREE.MeshBasicMaterial( { opacity, color, transparent: true });
    const ring = new THREE.Mesh( ringGeometry, ringMaterial );
    ring.position.setY(y);
    ring.rotateX(-Math.PI / 2);
    group.add(ring);
  }

  displayDome(treeHeight: number) {
    if (this.dome) {
      this.scene.remove(this.dome);
    }
    this.dome = new THREE.Group();
    this.dome.renderOrder = 2;

    const borderThickness = 0.1;

    this.addRingToGroup(treeHeight * 1.5 - borderThickness, treeHeight * 1.5, '#AE611A', 1, this.dome, 0);
    this.addRingToGroup(treeHeight, treeHeight * 1.5 - borderThickness, '#AE611A', 0.35, this.dome, 0);
    this.addRingToGroup(treeHeight - borderThickness, treeHeight, '#B62600', 1, this.dome, 0);
    this.addRingToGroup(0, treeHeight - borderThickness, '#B62600', 0.3, this.dome, 0);

    // TODO: Dome was temporarily removed, add it back whenever it's needed
    // const sphereGeometry = new THREE.SphereGeometry( treeHeight, 100, 100, 0, Math.PI, 0, Math.PI );
    // const sphereMaterial = new THREE.MeshBasicMaterial( { color: '#B62600', opacity: 0.15, transparent: true, side: THREE.DoubleSide });
    // const sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
    // sphere.rotateX(-Math.PI / 2);
    // this.dome?.add(sphere);

    if (this.dome) this.scene.add(this.dome);
  }

  hideDome() {
    if (this.dome) {
      this.scene.remove(this.dome);
    }
  }
  displayRootZone(tree: Tree, isMetric: boolean) {
    if (!tree.criticalRootZone || !tree.structuralCriticalRootZone) return;
    if (this.rootZone) {
      this.scene.remove(this.rootZone);
    }
    this.rootZone = new THREE.Group();
    this.rootZone.renderOrder = 1;

    const borderThickness = 0.05;

    const structuralRootZone = tree.structuralCriticalRootZone * (isMetric ? 1 : 0.3048);
    const criticalRootZone = tree.criticalRootZone * (isMetric ? 1 : 0.3048);
    const canopy = tree.canopyWidth / 2 * (isMetric ? 1 : 0.3048);
    this.addRingToGroup(0, canopy - borderThickness, '#03D33B', 0.24, this.rootZone, 0.0098);
    this.addRingToGroup(canopy - borderThickness, canopy, '#03D33B', 1, this.rootZone, 0.01);
    this.addRingToGroup(canopy, structuralRootZone - borderThickness, 'rgba(251, 124, 20, 1)', 0.24, this.rootZone, 0.01);
    this.addRingToGroup(structuralRootZone - borderThickness, structuralRootZone, 'rgba(251, 124, 20, 1)', 1, this.rootZone, 0.01);
    this.addRingToGroup(structuralRootZone, criticalRootZone - borderThickness, 'rgba(230, 25, 36, 1)', 0.24, this.rootZone, 0.01);
    this.addRingToGroup(criticalRootZone - borderThickness, criticalRootZone, 'rgba(230, 25, 36, 1)', 1, this.rootZone, 0.01);

    if (this.rootZone) this.scene.add(this.rootZone);
  }

  displaySCRZ(tree: Tree, isMetric: boolean) {
    if (this.scrz) {
      this.scene.remove(this.scrz);
    }
    if (!tree.structuralCriticalRootZone) return;
    this.scrz = new THREE.Group();
    this.scrz.renderOrder = 1;

    const borderThickness = 0.05;

    const structuralRootZone = tree.structuralCriticalRootZone * (isMetric ? 1 : 0.3048);
    const y = (tree.criticalRootZone || 0) < tree.structuralCriticalRootZone ? 0.01 : 0.02;
    this.addRingToGroup(0, structuralRootZone - borderThickness, 'rgba(251, 124, 20, 1)', 0.24, this.scrz, y);
    this.addRingToGroup(structuralRootZone - borderThickness, structuralRootZone, 'rgba(251, 124, 20, 1)', 1, this.scrz, y);

    if (this.scrz) this.scene.add(this.scrz);
    this.render();
  }

  hideSCRZ() {
    if (this.scrz) {
      this.scene.remove(this.scrz);
      this.render();
    }
  }

  displayCRZ(tree: Tree, isMetric: boolean) {
    if (this.crz) {
      this.scene.remove(this.crz);
    }
    if (!tree.criticalRootZone) return;
    this.crz = new THREE.Group();
    this.crz.renderOrder = 1;

    const borderThickness = 0.05;

    const innerRadius = 0;
    const criticalRootZone = tree.criticalRootZone * (isMetric ? 1 : 0.3048);
    const y = tree.criticalRootZone < (tree.structuralCriticalRootZone || 0) ? 0.02 : 0.01;
    this.addRingToGroup(innerRadius, criticalRootZone - borderThickness, 'rgba(230, 25, 36, 1)', 0.24, this.crz, y);
    this.addRingToGroup(criticalRootZone - borderThickness, criticalRootZone, 'rgba(230, 25, 36, 1)', 1, this.crz, y);

    if (this.crz) this.scene.add(this.crz);
    this.render();
  }

  hideCRZ() {
    if (this.crz) {
      this.scene.remove(this.crz);
      this.render();
    }
  }

  hideRootZone() {
    if (this.rootZone) {
      this.scene.remove(this.rootZone);
    }
  }
  lookAtTree(tree: Tree, isMetric: boolean) {
    const height = isMetric ? tree.height : tree.height / METER_TO_FEET;
    this.camera.lookAt(new THREE.Vector3(0, height / 2, 0));
    this.controls.synchronizeTo(this.camera);
  }

  remove() {
    if (this.sphere) {
      this.sphere.dispose();
      this.scene.remove(this.sphere);
    }
  }

  applyRotation(cameraRotation: [number, number, number]) {
    this.camera.rotation.set(...cameraRotation);
    this.controls.synchronizeTo(this.camera);
  }

  zoomIn() {
    this.controls.zoomIn();
  }

  zoomOut() {
    this.controls.zoomOut();
  }

  resetZoom() {
    this.controls.reset();
  }

  private changeCursorHover() {
    this.css3DRenderer.domElement.style.cursor = 'pointer';
  }

  private changeCursorDefault() {
    this.css3DRenderer.domElement.style.cursor = 'default';
  }

  addToScene(object: THREE.Object3D) {
    this.scene.add(object);
  }

  removeFromScene(object: THREE.Object3D) {
    this.scene.remove(object);
  }
}
