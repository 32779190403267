import SpeciesInItalic from '../Explore/workspace/components/SpeciesInItalic';
import { useTranslation } from 'react-i18next';
import DetailedTree from '../../tree/DetailedTree';
import React from 'react';
import { Task } from '../../task-manager/Task';
import { Flippers } from '../../switches/Flippers';
import { useCurrentAccount } from '../../account/useAccounts';
import { GH_DEFAULT, Tree } from '../../tree/Tree';
import NoData from './NoData';

export default function Header({ tree }: {
  tree: DetailedTree | null,
  task: Task | null,
  isTreeEdited: boolean
}) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();

  const renderedGenus = (organization.isEnabled(Flippers.davey) && tree?.genus === GH_DEFAULT) ? 'Unknown' : (tree?.genus || <NoData />);
  const renderedSpecies = (organization.isEnabled(Flippers.davey) && tree?.species === GH_DEFAULT) ? <i>Unknown</i> : (tree?.species ? <SpeciesInItalic species={tree.species} /> : <NoData />);
  return (
    <div className="flex justify-between w-full twp">
      <div className="flex gap-2 items-center">
        <div className="grid grid-cols-7 gap-5">
          <div>
            <Label label={t('treeDetails.inventory.genus')} />
            <p className="text-sm font-semibold"><i>{renderedGenus}</i></p>
          </div>
          <div>
            <Label label={t('treeDetails.inventory.species')} />
            <p className="text-sm font-semibold">{renderedSpecies}</p>
          </div>
          <div>
            <Label label={t('details.properties.commonName')} />
            <p className="text-sm font-semibold">{tree?.commonName || <NoData />}</p>
          </div>
          <div>
            <Label label={t('details.properties.address')} />
            <p className="text-sm font-semibold">{tree?.streetAddress || <NoData />}</p>
          </div>
          <div>
            <Label label={t('tree.tmsCat')} />
            <p className="text-sm font-semibold capitalize">{Tree.renderPropertyValue('tmsCategory', tree?.tmsCategory, t) || <NoData />}</p>
          </div>
          <div>
            <Label label={t('treeDetails.lastScanDate')} />
            <p className="text-sm font-semibold">{tree?.recordingDate ? formatDate(tree.recordingDate) : <NoData />}</p>
          </div>
          <div>
            <Label label={t('treeDetails.lastUpdate')} />
            <p className="text-sm font-semibold">{tree?.lastUpdatedAt ? formatDate(tree.lastUpdatedAt) : <NoData />}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const Label = ({ label }: { label: string }) => {
  return (
    <span className="text-sm text-[var(--cds-text-secondary)]">{label}</span>
  );
};

function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate();
  const monthYear = date.toLocaleDateString('en-GB', {
    month: 'long',
    year: 'numeric'
  });

  const daySuffix = (n: number) => {
    if (n >= 11 && n <= 13) return 'th';
    switch (n % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
    }
  };

  const formattedMonthYear = monthYear.replace(' ', ', ');

  return `${day}${daySuffix(day)} ${formattedMonthYear}`;
}
