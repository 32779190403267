import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionItem, Button, Search, Tooltip } from '@carbon/react';
import { CloseLarge, VideoPlayer } from '@carbon/icons-react';
import PageLayout from '../../Page/carbon/PageLayout';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { AuthContext } from '../../../../auth/AuthContext';
import { ArrowRight } from 'iconoir-react';

export default function FAQPanel() {
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();
  const faqItems = t('faq.items', { returnObjects: true });
  const [searchPhrase, setSearchPhrase] = useState('');

  const filteredFaqItems = Object.values(faqItems).filter((faq: any) =>
    faq.question.toLowerCase().includes(searchPhrase.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchPhrase.toLowerCase())
  );

  const account = useCurrentAccount();
  const authContext = useContext(AuthContext);
  const user = authContext.user;

  function setNativeValue(el: any, value: string) {
    const previousValue = el.value;

    el.value = value;

    const tracker = el._valueTracker;
    if (tracker) {
      tracker.setValue(previousValue);
    }

    el.dispatchEvent(new Event('change', { bubbles: true }));
  }

  useEffect(() => {
    const handleClick = () => {
      const jiraWidget = document.getElementById('jsd-widget') as HTMLIFrameElement;
      if (jiraWidget) {
        const emailField = jiraWidget.contentWindow?.document.getElementById('email');
        if (emailField) setNativeValue(emailField, user.email);

        const organizationField = jiraWidget.contentWindow?.document.getElementById('customfield_10092');
        if (organizationField) setNativeValue(organizationField, account.organization.name);

        const nameField = jiraWidget.contentWindow?.document.getElementById('customfield_10093');
        if (nameField) setNativeValue(nameField, user.getName());
      }
    };

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          const iframe = document.getElementById('jsd-widget') as HTMLIFrameElement;
          if (iframe) {
            iframe.style.display = 'block';
            iframe.contentWindow?.addEventListener('click', handleClick);
            observer.disconnect();
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    const iframe = document.getElementById('jsd-widget');
    if (iframe) iframe.style.display = 'block';

    if (!document.getElementById('jsd-widget-script')) {
      const script = document.createElement('script');
      script.setAttribute('data-jsd-embedded', '');
      script.setAttribute('id', 'jsd-widget-script');
      script.setAttribute('data-key', 'e328c564-26fa-4c0b-9f3e-357288e8e0c4');
      script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        window.document.dispatchEvent(new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true
        }));
      });
    }
    return () => {
      const iframe = document.getElementById('jsd-widget') as HTMLIFrameElement;
      if (iframe) {
        iframe.style.display = 'none';
        iframe.contentWindow?.removeEventListener('click', handleClick);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <PageLayout.RightPanelContent>
      <div className="px-6 py-2 h-full">
        <div className="flex justify-between items-center mb-8 text-[var(--cds-text-primary)]">
          <div className="text-xl">{t('faq.help')}</div>
          <Tooltip label={t('tooltips.close')} align="left">
            <div className="p-2 cursor-pointer" onClick={() => urlContext.setFAQPanelOpen(false)}><CloseLarge /></div>
          </Tooltip>
        </div>
        <div className="bg-[var(--cds-layer-01)]">
          <Search
            size="lg"
            placeholder={t('faq.search')}
            labelText={t('faq.search')}
            onChange={e => setSearchPhrase(e.target.value)}
            value={searchPhrase}
            className="[&>input]:max-h-12 [&>button]:max-h-12 [&>input]:bg-[var(--cds-layer-02)]"
          />
        </div>
        <Accordion>
          {filteredFaqItems.map((faq: any, index) => (
            <AccordionItem title={<div className="py-3">{faq.question}</div>} key={faq.question}>
              <div className="px-4">
                <div className="text-sm whitespace-pre-line leading-6">{faq.answer}</div>
                {tutorialLinksMap[index + 1] && (
                  <Button
                    href={tutorialLinksMap[index + 1]}
                    className="w-full mt-4 p-2 max-w-full"
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                  >
                    <div className="flex items-center gap-2">
                      <VideoPlayer />
                      {t('faq.watchTutorial')}
                    </div>
                  </Button>
                )}
              </div>
            </AccordionItem>
          ))}
        </Accordion>
        <div className="text-sm my-2 space-y-2">
          <div className="text-[var(--cds-text-primary)]">{t('faq.visitYoutube')}</div>
          <a
            href="https://www.youtube.com/playlist?list=PLKXbjz0Y00FBhDGhLXmsdtq_gHapfwQ5l"
            target="_blank"
            className="text-[var(--cds-interactive)] no-underline flex items-center gap-1"
            rel="noreferrer"
          >
            {t('faq.learningMaterials')}<ArrowRight height={14} width={12}/>
          </a>
        </div>
      </div>
    </PageLayout.RightPanelContent>
  );
}

const tutorialLinksMap = {
  1: 'https://www.youtube.com/watch?v=B1GTOpTpFBc',
  2: 'https://www.youtube.com/watch?v=B1GTOpTpFBc',
  3: 'https://www.youtube.com/watch?v=B1GTOpTpFBc',
  4: 'https://www.youtube.com/watch?v=B1GTOpTpFBc',
  5: 'https://www.youtube.com/watch?v=B1GTOpTpFBc',
  6: 'https://www.youtube.com/watch?v=tB2iwbo9doU',
  7: 'https://www.youtube.com/watch?v=jTGC1WlfWmA',
  8: 'https://www.youtube.com/watch?v=jTGC1WlfWmA',
  9: 'https://www.youtube.com/watch?v=UochsMbg_q0',
  10: 'https://www.youtube.com/watch?v=UochsMbg_q0',
  11: 'https://www.youtube.com/watch?v=UochsMbg_q0',
  12: 'https://www.youtube.com/watch?v=UNhfVDPLYCM',
  13: 'https://www.youtube.com/watch?v=UochsMbg_q0',
  14: 'https://www.youtube.com/watch?v=7UDgK9YfX5I',
  15: 'https://www.youtube.com/watch?v=7UDgK9YfX5I'
};
