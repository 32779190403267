import Dropdown, { Item } from '../../../components/UI/Dropdown/Dropdown';
import { Xmark } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import React, { useState } from 'react';
import { useAvailableFilterItemProperties } from '../../../properties/usePropertyConfigurations';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Condition, EnumCondition, Filter, NumericCondition, Operator } from '../../../filter/FilterConfig';
import Input from '../../../components/UI/Input/Input';
import { MultiSelect, reactSelectStyles } from '../../../components/Settings/Select/Select';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';
import { ViStatus } from '../../../tree/DetailedTree';
import { SpeciesLists } from './SpeciesList';
import ReactSelect from 'react-select';

type ReactSelectItem = { value: string, label: string };
export default function FilterRow(props: FilterRowProps) {
  const enumProperties = {
    [DisplayableTreeProperty.Status]: Object.values(TreeStatus),
    [DisplayableTreeProperty.VitalityVigor]: Object.values(VitalityVigor),
    [DisplayableTreeProperty.ViStatus]: Object.values(ViStatus),
    [DisplayableTreeProperty.ScientificName]: props.speciesLists.scientificNames,
    [DisplayableTreeProperty.Genus]: props.speciesLists.genus,
    [DisplayableTreeProperty.Species]: props.speciesLists.species,
    [DisplayableTreeProperty.OutlierHeightPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierHeightPerLeafArea]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerHeight]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea]: ['true', 'false']
  };

  const { t } = useTranslation();

  const { organization } = useCurrentAccount();
  const properties = useAvailableFilterItemProperties();

  const [inputValue, setInputValue] = useState(props.filter.value.toString());

  const getDisplayableProperty = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, organization);
    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    return t(`tree.${property}`) + translatedUnit;
  };

  const groups = Object.keys(properties).map(title => ({
    label: t(`treePropertySelector.${title}`),
    options: properties[title].map(it => ({ value: it, label: getDisplayableProperty(it) }))
  }));

  const handleTreePropertySelect = (item: ReactSelectItem) => {
    if (item?.value) {
      props.update(props.filterIndex, {
        ...props.filter,
        property: item.value as keyof Tree,
        condition: Tree.isEnumProperty(item.value as keyof Tree) ? EnumCondition.IN : NumericCondition.EQUALS,
        value: Tree.isEnumProperty(item.value as keyof Tree) ? [] : 0
      });
    } else {
      props.update(props.filterIndex, {
        ...props.filter,
        property: '' as keyof Tree,
        condition: '' as EnumCondition,
        value: []
      });
    }
  };

  const handleConditionSelect = (item: ReactSelectItem) => {
    if (item?.value) {
      props.update(props.filterIndex, {
        ...props.filter,
        condition: item?.value as NumericCondition | EnumCondition,
        value: isEnumCondition(item.value as NumericCondition | EnumCondition) ? [] : 0
      });
    } else {
      props.update(props.filterIndex, {
        ...props.filter,
        condition: '' as NumericCondition | EnumCondition,
        value: []
      });
    }
  };

  const handleNumericUpdate = (value: string) => {
    value = value.replace(/-/g, '');
    props.update(props.filterIndex, {
      ...props.filter,
      value: Number(value)
    });
    setInputValue(value);
  };

  const handleEnumChange = (values: Item[]) => {
    props.update(props.filterIndex, {
      ...props.filter,
      value: values as string[]
    });
  };

  const enumConditionOptions = Object.values(EnumCondition).map(it => ({ value: it, label: t(`taskManager.${it}`) }));
  const numberConditionOptions = Object.values(NumericCondition).map(it => ({ value: it, label: t(`taskManager.${it}`) }));

  const isEnumCondition = (condition: Condition) => {
    return Object.values(EnumCondition).includes(condition as EnumCondition);
  };

  const enumPropertyOptions = enumProperties[props.filter.property]?.map(it => ({
    label: t(Tree.getTKeyForProperty(props.filter.property, it)),
    value: it
  })) || [];

  const topLevelOperatorOptions = [
    { id: Operator.AND, translationKey: `taskManager.topLevelOperators.${Operator.AND}` },
    { id: Operator.OR, translationKey: `taskManager.topLevelOperators.${Operator.OR}` }
  ];

  const handleTopLevelOperatorSelect = (item: Item) => {
    props.updateOperator(item.id as Operator);
  };

  return (<div className="grid grid-cols-12 text-greehill-00 items-center gap-3 my-3">
    <div className="col-span-2 flex justify-center items-center">
      {props.filterIndex === 0 ? (
        <span>{t('taskManager.where')}</span>)
        :
        props.filterIndex === 1 ? (
          <Dropdown
            value={topLevelOperatorOptions.find(it => it.id === props.topLevelOperator)}
            onSelect={handleTopLevelOperatorSelect}
            items={topLevelOperatorOptions}
            className="w-fit"
            fieldClassName="rounded h-11 flex items-center gap-2"
            menuClassname="bg-outer-space-600 mx-0 px-0"
            dropDownItemClassName="hover:bg-outer-space-500 px-3 py-2"

          />
        ) : <span>{t(`taskManager.topLevelOperators.${props.topLevelOperator}`)}</span>
      }
    </div>
    <div className="col-span-9 rounded-lg items-center grid grid-cols-3 gap-3 w-full">
      <div className="col-span-1">
        <ReactSelect
          value={groups.flatMap(group => group.options).find(item => item.value === props.filter.property)}
          onChange={handleTreePropertySelect}
          options={groups}
          placeholder={t('treePropertySelector.placeholder')}
          noOptionsMessage={() => ''}
          isSearchable={true}
          styles={reactSelectStyles()}
          isClearable={true}
        />
      </div>
      <div className="col-span-1">
        {Tree.isEnumProperty(props.filter.property)
          ? <ReactSelect
            value={enumConditionOptions.find(it => it.value === props.filter.condition)}
            onChange={it => handleConditionSelect(it as ReactSelectItem)}
            options={enumConditionOptions}
            placeholder={t('taskManager.condition')}
            isSearchable={true}
            styles={reactSelectStyles()}
            isClearable={true}
          />
          : <ReactSelect
            value={numberConditionOptions.find(it => it.value === props.filter.condition)}
            onChange={it => handleConditionSelect(it as ReactSelectItem)}
            options={numberConditionOptions}
            placeholder={t('taskManager.condition')}
            isSearchable={true}
            styles={reactSelectStyles()}
            isClearable={true}
          />
        }
      </div>
      <div className="col-span-1">
        {isEnumCondition(props.filter.condition) ? (
          <div>
            <MultiSelect
              value={props.filter.value as Item[]}
              options={enumPropertyOptions}
              onChange={handleEnumChange}
              containerStyle={{ maxWidth: '15vw' }}
              label={''}
              isSearchable={true}
            />
          </div>
        ) : (
          <Input
            label={''}
            type={'number'}
            value={inputValue}
            onValueChange={handleNumericUpdate}
            legacyStyle={true}
            disabled={!props.filter.condition}
          />
        )}
      </div>
    </div>
    <div className="col-span-1 flex justify-center items-center">
      <Xmark onClick={() => props.handleDeleteFilter()} cursor={'pointer'} />
    </div>
  </div>);
}

interface FilterRowProps {
  handleDeleteFilter: () => void,
  filter: Filter,
  filterIndex: number,
  update: (index: number, filter: Filter) => void,
  updateOperator: (operator: Operator) => void,
  speciesLists: SpeciesLists,
  topLevelOperator?: string
}
