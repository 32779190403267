import * as THREE from 'three';
import MultiResolutionMesh from './MultiResolutionMesh';
import { Organization } from '../../../organization/Organization';

interface Point {
  type: 'Point',
  coordinates: [number, number, number]
}

export interface Image {
  id: string,
  scanName: string,
  filename: string,
  path?: string,
  gufPath?: string,
  timestamp: number,
  origin: Point & { crs: { type: string, properties: { name: string } } },
  direction: Point,
  up: Point,
  roll: number,
  pitch: number,
  yaw: number,
  cameraId: string,
  snapshotId: string,
  cameraIdx: number,
  cameraName: string,
  version: number,
  type: number,
  dx: number,
  dy: number,
  nx: number,
  ny: number,
  fx: number,
  fy: number,
  cx: number,
  cy: number,
  k1: number,
  k2: number,
  k3: number,
  k4: number,
  p1: number,
  p2: number,
  directionVec: [number, number, number],
  upVec: [number, number, number],
  width: number,
  height: number,
  cameraMatrix: [[number, number, number], [number, number, number], [number, number, number]],
  url: string,
  smallUrl: string,
  largeUrl: string
}

export const parseImageProperties = (imageProperties: Image[], organization: Organization, snapshotId: string): Image[] => {
  const getSmallUrl = (url: string) => {
    return organization.getCDNUrlOfExportedDataFromRelativePath(`${url.replace('_large.jpg', '_small.jpg')}`);
  };
  const getLargeUrl = (url: string) => {
    return organization.getCDNUrlOfExportedDataFromRelativePath(`${url}`);
  };

  return imageProperties.map(img => ({
    ...img,
    directionVec: img.direction.coordinates,
    upVec: img.up.coordinates,
    cameraMatrix: [
      [img.fx, 0, img.cx],
      [0, img.fy, img.cy],
      [0, 0, 1]
    ],
    url: organization.getCDNUrlFromRelativePath(`scan/${img.path ?? img.gufPath}`),
    smallUrl: getSmallUrl(img.path ?? img.gufPath ?? ''),
    largeUrl: getLargeUrl(img.path ?? img.gufPath ?? ''),
    id: img.cameraId.toString(),
    cameraId: img.cameraId.toString(),
    height: img.ny,
    width: img.nx,
    snapshotId
  }));
};

export const generateMeshFromImage = (image: Image) => {
  const geom = _generateGeometryForImage(image, 100);
  const material = new THREE.MeshBasicMaterial({
    side: THREE.FrontSide,
    opacity: 1,
    color: 0x222c2f,
    transparent: true
  });

  return new THREE.Mesh(geom, material);
};

export const generateMultiResolutionMeshFromImage = (image: Image) => {
  const geom = _generateGeometryForImage(image, 100);

  return new MultiResolutionMesh(geom, image.smallUrl, image.largeUrl);
};

const _generateGeometryForImage = (image: Image, distance: number) => {
  const vectors = transform(image, distance);

  return new THREE.PlaneGeometry().setFromPoints(vectors) as THREE.PlaneGeometry;
};

const transform = (image: Image, distance: number) => {
  const cameraMatrix = image.cameraMatrix;
  const directionVec = new THREE.Vector3(...image.directionVec);
  const upVec = new THREE.Vector3(...image.upVec);

  const r2 = upVec.clone().negate();
  const r1 = directionVec.cross(upVec);
  const r3 = r1.clone().cross(r2);
  const matrix = new THREE.Matrix3();
  matrix.set(r1.x, r2.x, r3.x, r1.y, r2.y, r3.y, r1.z, r2.z, r3.z);
  matrix.transpose();

  const t1 = new THREE.Matrix3()
    .set(...cameraMatrix[0], ...cameraMatrix[1], ...cameraMatrix[2])
    .invert()
    .toArray();

  return [
    calculateVector(t1, distance, matrix, 0, 0),
    calculateVector(t1, distance, matrix, image.width - 1, 0),
    calculateVector(t1, distance, matrix, 0, image.height - 1),
    calculateVector(t1, distance, matrix, image.width - 1, image.height - 1)
  ];
};

const calculateVector = (t1: THREE.Matrix3Tuple, distance: number, matrix: THREE.Matrix3, x: number, y: number) => {
  const t2 = [x, y, 1.0];
  let p = multiplyMatrix(t1, t2);
  p = p.map(n => n * (distance / p[2]));
  p = multiplyMatrix(matrix.clone().transpose().toArray(), p);

  return new THREE.Vector3(...p);
};

// 3x3 X 1x3
const multiplyMatrix = (t1, t2) => [
  t1[0] * t2[0] + t1[3] * t2[1] + t1[6] * t2[2],
  t1[1] * t2[0] + t1[4] * t2[1] + t1[7] * t2[2],
  t1[2] * t2[0] + t1[5] * t2[1] + t1[8] * t2[2]
];
