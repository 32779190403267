import { useCurrentAccount } from '../../../account/useAccounts';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import { TreeFilter } from '../../../tree-filter/TreeFilter';
import { ReportData } from './ReportData';
import GeneralInsightsSection from './sections/GeneralInsightsSection';
import SpeciesDistributionSection from './sections/SpeciesDistributionSection';
import CarbonStorageSection from './sections/CarbonStorageSection';
import AirPollutionSection from './sections/AirPollutionSection';
import AvoidedWaterSection from './sections/AvoidedWaterSection';
import DBHDistributionSection from './sections/DBHDistributionSection';
import CanopyCoverage from './sections/CanopyCoverageSection';
import { useMatch, useNavigate } from 'react-router-dom';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import GufTooltip from '../../../components/UI/Tooltip/Tooltip';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { Tree } from '../../../tree/Tree';
import { Flippers } from '../../../switches/Flippers';
import { getProperties } from '../../../filter/FilterConfig';
import FullscreenModal from '../components/Modal/fullscreen-modal/FullscreenModal';
import { Button, SideNav, SideNavItems, SideNavLink, Tooltip } from '@carbon/react';
import { CloseLarge, Printer } from '@carbon/icons-react';

export default function ReportDialog(props: ReportDialogProps) {
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const { t } = useTranslation();

  const reportsContainerRef = useRef<HTMLDivElement | null>(null);
  const bulletPointListRef = useRef<HTMLUListElement | null>(null);

  const [reportData, setReportData] = useState<ReportData | null>(null);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState<string | null>('generalInsights');

  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';

  useEffect(() => {
    const reverseMASelection = urlContext.getReverseMASelection();
    const managedAreaIds = urlContext.getManagedAreaIds();
    const filterConfiguration = urlContext.getFilterConfiguration();
    const fields = urlContext.getVisibleTableProperties();

    const fieldsToFetch = Array.from(new Set([
      ...(fields || []),
      ...getProperties(filterConfiguration)
    ])) as (keyof Tree)[];

    if (account.organization.isEnabled(Flippers.workspace)) {
      treeService.fetchWorkspaceReportData(
        account.organization.id,
        managedAreaIds,
        reverseMASelection,
        fieldsToFetch,
        props.filters.map(it => it.id),
        filterConfiguration,
        props.taskTemplateId
      ).then(setReportData);
    } else {
      treeService.fetchReportData(
        account.organization.id,
        managedAreaIds,
        reverseMASelection,
        fieldsToFetch,
        props.filters.map(it => it.id)
      ).then(setReportData);
    }
  }, [
    account.organization.id,
    props.filters,
    treeService,
    JSON.stringify(urlContext.getReverseMASelection()),
    JSON.stringify(urlContext.getManagedAreaIds()),
    JSON.stringify(urlContext.getFilterConfiguration())
  ]);

  const scrollToItem = itemId => {
    if (!reportsContainerRef.current) return;

    reportsContainerRef.current?.scrollTo({
      top: (reportsContainerRef.current?.querySelector(`${itemId}`) as HTMLDivElement).offsetTop - 50,
      behavior: 'smooth'
    });
  };

  const onListItemClick = ((e, section: string) => {
    e.preventDefault();
    e.stopPropagation();
    scrollToItem(section);
    setActiveSection(section);
  });

  const isInside = (container: DOMRect, element: DOMRect) => {
    return element.top > container.top && element.top < container.bottom;
  };

  useEffect(() => {
    if (!reportsContainerRef.current) return;
    reportsContainerRef.current.addEventListener('scroll', () => {
      const container = reportsContainerRef.current!;
      const rect = container.getBoundingClientRect();
      const children = Array.from(container.children);
      const visibleElement = children.find(it => isInside(rect, it.getBoundingClientRect()));
      const id = visibleElement?.getAttribute('id');
      setActiveSection('#' + id);
    });
  }, [reportsContainerRef.current, bulletPointListRef.current]);

  const managedAreaNames = (props.managedAreas || []).map(it => it.getName());
  const managedAreaNamesDisplay = useMemo(() => {
    if (managedAreaNames.length === 0) {
      return t('reporting.allAreas');
    }

    if (managedAreaNames.length > 3) {
      return managedAreaNames.slice(0, 3).join(', ').concat('...');
    }

    return managedAreaNames.join(', ');
  }, [managedAreaNames, t]);

  const filterNames = useMemo(() => {
    if (props.filters.length === 0) {
      return t('reporting.allTrees');
    }

    return props.filters.map(it => it.name).join(', ');
  }, [props.filters, t]);

  const managedAreaNamesContainer = <div>
    <strong>{managedAreaNamesDisplay}</strong>
  </div>;

  const managedAreaNamesPossiblyWithTooltips = props.managedAreas.length > 3
    ? managedAreaNamesContainer
    : <GufTooltip
      placement="bottom"
      overlay={<span>{managedAreaNames.join('\n')}</span>}
      overlayStyle={{ zIndex: 99999, whiteSpace: 'pre-wrap' }}>
      {managedAreaNamesContainer}
    </GufTooltip>;

  return (
    <FullscreenModal isVisible={true} onHide={props.onHide}>
      <div className="twp divide-y divide-[var(--cds-border-subtle-00)] text-[var(--cds-text-primary)]">
        <div className="flex justify-between text-sm font-normal">
          <div className="font-semibold pl-4 flex items-center">{t('reporting.reportName', { organizationName: account.getOrganizationName() })}</div>
          <div className="flex justify-end">
            <Tooltip label={t('tooltips.printOrSave')} align="left">
              <Button
                onClick={() => {
                  navigate(`/organizations/${organizationId}/map/report`, {
                    state: {
                      reportData,
                      managedAreaNamesDisplay,
                      filterNames
                    }
                  });
                }}
                kind="ghost"
              >
                <Printer />
              </Button>
            </Tooltip>
            <Tooltip label={t('tooltips.close')} align="left">
              <Button
                onClick={props.onHide}
                kind="ghost"
              >
                <CloseLarge />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div>
          {reportData
            ? <div className="flex">
              <SideNav
                className="mx-14 mt-24 relative"
                expanded={true}
                isChildOfHeader={false}
                isFixedNav={true}
                aria-label="Side navigation">
                <SideNavItems>
                  <SideNavLink
                    href="#generalInsights"
                    onClick={event => onListItemClick(event, '#generalInsights')}
                    isActive={activeSection === '#generalInsights'}
                  >
                    {t('reporting.generalInsights.title')}
                  </SideNavLink>
                  <SideNavLink
                    href="#speciesDistribution"
                    onClick={event => onListItemClick(event, '#speciesDistribution')}
                    isActive={activeSection === '#speciesDistribution'}
                  >
                    {t('reporting.speciesDistribution.title')}
                  </SideNavLink>
                  <SideNavLink
                    href="#DBHDistribution"
                    onClick={event => onListItemClick(event, '#DBHDistribution')}
                    isActive={activeSection === '#DBHDistribution'}
                  >
                    {t('reporting.DBHDistribution.title')}
                  </SideNavLink>
                  <SideNavLink
                    href="#canopyCoverage"
                    onClick={event => onListItemClick(event, '#canopyCoverage')}
                    isActive={activeSection === '#canopyCoverage'}
                  >
                    {t('reporting.canopyCoverage.title')}
                  </SideNavLink>
                  <SideNavLink
                    href="#carbonStorage"
                    onClick={event => onListItemClick(event, '#carbonStorage')}
                    isActive={activeSection === '#carbonStorage'}
                  >
                    {t('reporting.carbonStorage.title')}
                  </SideNavLink>
                  <SideNavLink
                    href="#airPollution"
                    onClick={event => onListItemClick(event, '#airPollution')}
                    isActive={activeSection === '#airPollution'}
                  >
                    {t('reporting.airPollution.title')}
                  </SideNavLink>
                  <SideNavLink
                    href="#avoidedWater"
                    onClick={event => onListItemClick(event, '#avoidedWater')}
                    isActive={activeSection === '#avoidedWater'}
                  >
                    {t('reporting.avoidedWater.title')}
                  </SideNavLink>
                </SideNavItems>
              </SideNav>
              <div className="w-2/3 max-h-[90vh] overflow-auto text-sm font-normal pt-10 divide-y divide-[var(--cds-border-subtle-00)]" ref={reportsContainerRef}>
                <div className="flex gap-4 pb-8">
                  <div className="flex flex-col text-[var(--cds-text-secondary)]">
                    <span>Areas</span>
                    <span>{t('reporting.numberOfTrees')}</span>
                  </div>
                  <div className="flex flex-col">
                    {managedAreaNamesPossiblyWithTooltips}
                    <span>{reportData?.total || '-'}</span>
                  </div>
                </div>
                <GeneralInsightsSection
                  data={reportData.generalInsights}
                  containerId={'generalInsights'}
                />
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <SpeciesDistributionSection
                      data={reportData.speciesDistribution}
                      containerId={'speciesDistribution'}
                    />
                  )
                }
                <DBHDistributionSection data={reportData.dbhDistribution} containerId={'DBHDistribution'} />
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <CanopyCoverage
                      data={reportData.leafAreaAndCanopyCoverage}
                      speciesDistribution={reportData.speciesDistribution}
                      containerId={'canopyCoverage'}
                    />
                  )
                }
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <CarbonStorageSection
                      data={reportData.carbonStorageAndSequestration}
                      speciesDistribution={reportData.speciesDistribution}
                      containerId={'carbonStorage'}
                    />
                  )
                }
                <AirPollutionSection
                  data={reportData.airPollutionRemoval}
                  containerId={'airPollution'}
                />
                {
                  (reportData.speciesDistribution.length > 1) && (
                    <AvoidedWaterSection
                      data={reportData.avoidedWaterRunoff}
                      speciesDistribution={reportData.speciesDistribution}
                      containerId={'avoidedWater'}
                    />
                  )
                }
              </div>
            </div>
            : <Spinner />}
        </div>
      </div>
    </FullscreenModal>
  );
}

interface ReportDialogProps {
  managedAreas: ManagedArea[],
  filters: TreeFilter[],
  onHide: () => void,
  taskTemplateId?: string
}
