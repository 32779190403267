import styles from './Workspace.module.scss';
import React, { Dispatch, SetStateAction, useCallback, useContext, useRef, useState } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useMatch } from 'react-router-dom';
import { useTracking } from '../../analytics/useTracking';
import { DisplayableTreeProperty } from '../../tree/Tree';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import { PanelLayout } from './PanelLayout';
import TreeTable, { Property } from './TreeTable';
import { DragVertical } from '@carbon/icons-react';
import { Tooltip } from '@carbon/react';
import { useTranslation } from 'react-i18next';

const MIN_DRAG_DISTANCE = 10;

export default function Workspace(props: WorkspaceProps) {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/*');
  const { track, events } = useTracking();
  const dragStartPos = useRef<number | null>(null);

  const [dragDistance, setDragDistance] = useState(0);

  const organizationId = match?.params?.organizationId || '';
  const properties: Property[] = ['externalId', ...props.properties];

  const onToggle = useCallback(() => {
    const wasOpened = urlContext.getTreeTableOpen();
    urlContext.setTreeTableOpen(!wasOpened);
    props.panelLayoutRef.toggleTable();
    track(events.TABLE_TOGGLE_CHANGE, { opened: !wasOpened });
  }, [organizationId]);

  const onTableDragStart = downEvent => {
    const nativeDownEvent = (downEvent.nativeEvent as MouseEvent);
    dragStartPos.current = nativeDownEvent.screenY;
    const mouseMoveListener = e => onMouseMove(e);
    const mouseUpListener = e => {
      const ev = e as MouseEvent;
      if (Math.abs(ev.screenY - (dragStartPos.current || ev.screenY)) < MIN_DRAG_DISTANCE) {
        onToggle();
      }

      props.setTableResizeTrigger(Math.random());
      window.removeEventListener('mousemove', mouseMoveListener);
      window.removeEventListener('mouseup', mouseUpListener);
    };

    window.addEventListener('mousemove', mouseMoveListener);
    window.addEventListener('mouseup', mouseUpListener);
  };

  const onMouseMove = useCallback((e: Event) => {
    const onTableDrag = height => {
      props.panelLayoutRef.setTableDragDistance(height);
      props.setTableDragDistance(height);
    };

    const evt = e as MouseEvent;
    const distance = window.innerHeight - evt.clientY;
    if (distance >= window.innerHeight * 0.7 && distance > dragDistance) {
      onTableDrag(window.innerHeight - 100);
      setDragDistance(distance);
      return;
    }

    if (distance < window.innerHeight - 100) {
      onTableDrag(distance);
    }

    setDragDistance(distance);
  }, [setDragDistance, dragDistance, props.panelLayoutRef]);

  return (
    <div className={`${styles.container} ${urlContext.getTreeId() || urlContext.isFilterPanelOpen() ? 'max-w-[calc(100vw-390px)]' : ''}`}>
      <button
        type="button"
        className={`${styles.toggle} twp w-full flex justify-center p-2 ${props.open ? 'pb-6' : ''}`}
        onMouseDown={onTableDragStart}
        data-testid={'table-toggle-button'}
      >
        <Tooltip label={t('tooltips.drag')} align="top">
          <DragVertical size={16}/>
        </Tooltip>
      </button>
      <TreeTable
        filters={props.filters}
        properties={properties}
        areaFilterIsSelected={props.areaFilterIsSelected}
        windSpeed={props.windSpeed}
        open={props.open}
      />
    </div>
  );
}

interface WorkspaceProps {
  properties: DisplayableTreeProperty[],
  open: boolean,
  filters: TreeFilter[],
  windSpeed: number,
  panelLayoutRef: PanelLayout,
  setTableResizeTrigger: Dispatch<SetStateAction<number>>,
  setTableDragDistance: (height: number) => void,
  areaFilterIsSelected: boolean
}
