import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useContext } from 'react';

export enum RightPanelContent {
  FILTER_PANEL = 'FILTER_PANEL',
  QUICK_DETAILS_PANEL = 'QUICK_DETAILS_PANEL',
  COLUMN_ORDERING_PANEL = 'COLUMN_ORDERING_PANEL',
  NONE = 'NONE'
}

export default function useRightPanelContent(): RightPanelContent {
  const { urlContext } = useContext(DependencyInjectionContext);
  if (urlContext.isFilterPanelOpen()) return RightPanelContent.FILTER_PANEL;
  if (urlContext.isColumnSorterOpen()) return RightPanelContent.COLUMN_ORDERING_PANEL;
  if (urlContext.getTreeId()) return RightPanelContent.QUICK_DETAILS_PANEL;
  return RightPanelContent.NONE;
}