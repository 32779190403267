import * as THREE from 'three';
import { DisplayableTreeProperty, Tree, TreeDisplayConfiguration } from '../../../../tree/Tree';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import TreeMarkerVariations from './TreeMarkerVariations';
import styles from './TreeMarkerVariations.module.scss';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { AdvancedFilter, SigmaBoundary } from '../../table-view/advanced-filter/AdvancedFilter';
import { Account } from '../../../../account/Account';
import CohortConfig from '../../../../components/cohort/CohortConfig';
import { ColoringType } from '../../../../components/Navbar/PropertyLegend';
import FilterCompound from '../../../../filter/FilterCompound';

export class HTMLTreeMarker {
  static create(
    tree: Tree,
    onMouseEnter: (tree: Tree) => void,
    onMouseLeave: () => void,
    onWheel: (event: WheelEvent) => void,
    onMouseDown: (event: MouseEvent) => void
  ) {
    const treeMercatorCoordinates = tree.getMercatorCoordinates();

    return new HTMLTreeMarker(
      tree,
      treeMercatorCoordinates.x,
      treeMercatorCoordinates.y,
      onMouseEnter,
      onMouseLeave,
      onWheel,
      onMouseDown
    );
  }

  public isVisible = true;
  public isSelected = false;
  public isAdvancedFiltered = false;
  private readonly markerIcon: CSS2DObject = this.createMarkerHtml();
  private readonly treeMarkerVariations: TreeMarkerVariations = new TreeMarkerVariations(this.markerIcon.element as HTMLDivElement, this.tree.externalId);

  constructor(
    readonly tree: Tree,
    private readonly mercatorXPosition: number,
    private readonly mercatorYPosition: number,
    private readonly onMouseEnter: (tree: Tree) => void,
    private readonly onMouseLeave: () => void,
    private readonly onWheel: (event: WheelEvent) => void,
    private readonly onMouseDown: (event: MouseEvent) => void
  ) {
    this.markerIcon.element.onclick = this.handleClick;
    this.markerIcon.element.onmouseenter = () => this.onMouseEnter(this.tree);
    this.markerIcon.element.onmouseleave = () => this.onMouseLeave();
    this.markerIcon.element.onwheel = this.onWheel;
    this.markerIcon.element.onmousedown = this.onMouseDown;
  }

  private onSelect = (_: string) => {};

  setPosition(cameraCenterX: number, cameraCenterY: number, scale: number) {
    this.markerIcon.position
      .setX((this.mercatorXPosition - cameraCenterX) / scale)
      .setY((cameraCenterY - this.mercatorYPosition) / scale)
      .setZ(0);
  }

  addTo(scene: THREE.Scene) {
    scene.add(this.markerIcon);
    return this;
  }

  removeFrom(scene: THREE.Scene) {
    scene.remove(this.markerIcon);
    return this;
  }

  belongsTo(treeId: string) {
    return this.tree.id === treeId;
  }

  removeListeners() {
    this.markerIcon.element.onclick = null;
    this.markerIcon.element.onmouseenter = null;
    this.markerIcon.element.onmouseleave = null;
    this.markerIcon.element.onwheel = null;
    this.markerIcon.element.onmousedown = null;
  }

  handleClick = () => {
    if (this.isSelected) {
      this.resetSelectionState();
      this.onSelect('');
    } else {
      this.onSelect(this.tree.id);
    }
  };

  setOnSelectCallback(onClick: (treeId: string) => void) {
    this.onSelect = onClick;
  }

  select() {
    if (this.isVisible) {
      this.isSelected = true;
      this.treeMarkerVariations.setContentSelected();
    }
    return this;
  }

  resetSelectionState() {
    if (this.isVisible && this.isSelected) {
      this.isSelected = false;
      this.treeMarkerVariations.setDefaultContent();
    }
    return this;
  }

  applyDisplayConfiguration(
    displayConfiguration: TreeDisplayConfiguration,
    advancedFilter: AdvancedFilter,
    filter: FilterCompound,
    hideMarker = false,
    selectedPropertyConfig: PropertyConfiguration | null = null,
    coloringType: ColoringType,
    hideLabel = false,
    account: Account
  ) {
    if (hideMarker) {
      this.treeMarkerVariations.hide();
      return this;
    }

    const isFiltered = advancedFilter.isExcluded(this.tree, displayConfiguration.windSpeed);
    if (isFiltered) {
      this.isAdvancedFiltered = true;
      this.treeMarkerVariations.setContentDisabled();
      this.markerIcon.element.onclick = null;
      this.treeMarkerVariations.removeColor();
      this.treeMarkerVariations.removeLabelContent();
    }

    if (!advancedFilter.isExcluded(this.tree, displayConfiguration.windSpeed)) {
      this.isAdvancedFiltered = false;
      if (!this.isSelected) {
        this.treeMarkerVariations.setDefaultContent();
      } else {
        this.treeMarkerVariations.setContentSelected();
      }
      this.markerIcon.element.onclick = this.handleClick;
    }

    if (this.isTreeInSelectedManagedArea(displayConfiguration) && filter.apply(this.tree) && this.isTreeInAnyFilter(displayConfiguration, account)) {
      this.show();
    } else {
      this.hide();
    }
    if (!selectedPropertyConfig) {
      this.treeMarkerVariations.removeColor();
      return this;
    }

    if (!isFiltered) {
      const value = this.getPropertyValue(selectedPropertyConfig, displayConfiguration);
      const color = this.getColorOfValue(value, selectedPropertyConfig, coloringType);
      if (!color) return this;
      this.treeMarkerVariations.setColor(color);
      if (hideLabel) {
        this.treeMarkerVariations.removeLabelContent();
      } else {
        this.treeMarkerVariations.setLabel(value);
      }
    }
    return this;
  }

  private getPropertyValue(selectedPropertyConfig: PropertyConfiguration, displayConfiguration: TreeDisplayConfiguration): number {
    return selectedPropertyConfig.property === DisplayableTreeProperty.SafetyFactors
      ? this.tree.getSafetyFactor(displayConfiguration.windSpeed)
      : this.tree[selectedPropertyConfig.property];
  }

  private getCohortColorOfValue(value: number, selectedPropertyConfig: PropertyConfiguration) {
    const cohortValue = this.tree.cohort?.cohortValues[selectedPropertyConfig.property];
    if (!cohortValue) return '255, 255, 255';
    return new CohortConfig(cohortValue).getColorOfValue(value);
  }

  private getLabelColorOfValue(value: number | string, selectedPropertyConfig: PropertyConfiguration) {
    const index = selectedPropertyConfig.getRangeIndexForValue(value, Boolean(selectedPropertyConfig.ranges[0].value));
    const colors = PropertyColorConfiguration.getColorsForConfig(selectedPropertyConfig);
    return colors[index];
  }

  private getColorOfValue(value: number, selectedPropertyConfig: PropertyConfiguration, coloringType: ColoringType): string | null {
    if (value === null) return null;
    if (coloringType === ColoringType.COHORT) {
      return this.getCohortColorOfValue(value, selectedPropertyConfig);
    }
    return this.getLabelColorOfValue(value, selectedPropertyConfig);
  }

  private show() {
    this.treeMarkerVariations.show();
    this.isVisible = true;
  }

  private hide() {
    this.treeMarkerVariations.hide();
    this.isVisible = false;
  }

  changeOpacity(propertyConfig: PropertyConfiguration | null, index: number, windSpeed: number | null = null) {
    this.markerIcon.element.classList.remove(styles.decreasedOpacity);

    if (propertyConfig?.isOutsideRange(index, this.tree, windSpeed)) {
      this.markerIcon.element.classList.add(styles.decreasedOpacity);
    }
  }

  changeOpacityForCohort(selectedProperty: string, boundary: SigmaBoundary | null) {
    this.markerIcon.element.classList.remove(styles.decreasedOpacity);
    const cohort = this.tree.cohort?.cohortValues[selectedProperty];

    if (!cohort) {
      if (boundary) this.markerIcon.element.classList.add(styles.decreasedOpacity);
    } else {
      const cohortConfig = new CohortConfig(this.tree.cohort?.cohortValues[selectedProperty]);
      if (cohortConfig?.isOutsideBoundary(this.tree[selectedProperty], boundary)) {
        this.markerIcon.element.classList.add(styles.decreasedOpacity);
      }
    }
  }

  private createMarkerHtml() {
    const div = document.createElement( 'div' );
    div.classList.add(styles.mapMarkerContainer);
    return new CSS2DObject(div);
  }

  private isTreeInSelectedManagedArea(displayConfiguration: TreeDisplayConfiguration) {
    return (
      displayConfiguration.managedAreaIds.length === 0 ||
      (displayConfiguration.managedAreaIds.includes(this.tree.managedAreaId) !== displayConfiguration.isManagedAreaSelectionReversed)
    );
  }

  private isTreeInAnyFilter(displayConfiguration: TreeDisplayConfiguration, account: Account) {
    return (
      displayConfiguration.filters.length === 0 ||
      displayConfiguration.filters.some(filter => filter.apply(this.tree, account))
    );
  }
}
