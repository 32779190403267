import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../auth/AuthContext';
import { Account } from '../../../../account/Account';
import { Button, Popover, PopoverContent, Tooltip } from '@carbon/react';
import { Group, Language, NetworkAdminControl, Settings, UserAvatar } from '@carbon/icons-react';
import useAccounts from '../../../../account/useAccounts';
import OrganizationMenu from './OrganizationMenu';

export default function ProfileMenu({ currentAccount }: { currentAccount: Account }) {
  const organization = currentAccount.organization;
  const { accounts, isLoadingAccounts } = useAccounts();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const user = useContext(AuthContext).user;

  return (
    <Popover open={open} caret={false} align="bottom-end">
      <Tooltip label={t('tooltips.settingsAndPreferences')} align="left">
        <div>
          <Button className="py-[7px] px-4 flex items-center justify-center" kind="ghost" onClick={() => setOpen(prev => !prev)}>
            <div className="flex gap-2 items-center text-[var(--cds-text-primary)]">
              {currentAccount.organization.name}
              <img className="h-6 rounded-full" src={currentAccount.organization.logoUrl} alt="logo"/>
            </div>
          </Button>
        </div>
      </Tooltip>
      <PopoverContent
        style={{
          transform: 'translate(calc(var(--cds-popover-offset, 0rem) + 56px), calc(100% + var(--cds-popover-offset, 0rem) - 48px))'
        }}
        className="bg-[var(--cds-background)] divide-y divide-[var(--cds-border-subtle-00)] text-sm text-[var(--cds-text-primary)] max-w-[30rem] [&>*]:border-solid [&>*]:border-l-0  [&>*]:border-r-0"
      >
        {organization.isDemo && (
          <div>
            <Button
              kind="ghost"
              className="w-full flex justify-center gap-2 items-center"
              onClick={() => navigate(`/organizations/${organization.id}`)}
            >
              <div className="w-full">
                {t('navbar.home')}
              </div>
            </Button>
          </div>
        )}

        <div className="border-0">
          <Button
            kind="ghost"
            className="w-full flex justify-center gap-2 items-center text-[var(--cds-text-primary)]"
            onClick={() => navigate(`/organizations/${organization.id}/settings`)}
          >
            <Settings />
            <div className="w-full">
              {t('navbar.settings')}
            </div>
          </Button>
        </div>

        {currentAccount.canManageMembers() && (
          <>
            <div>
              <Button
                kind="ghost"
                className="w-full flex justify-center gap-2 items-center text-[var(--cds-text-primary)]"
                onClick={() => navigate(`/organizations/${organization.id}/settings/members`)}>
                <Group />
                <div className="w-full">
                  {t('navbar.manageMembers')}
                </div>
              </Button>
            </div>
          </>
        )}

        {user.superOwner && (
          <>
            <div>
              <Button
                kind="ghost"
                className="w-full flex justify-center gap-2 items-center text-[var(--cds-text-primary)]"
                onClick={() => navigate('/super-owner')}
              >
                <NetworkAdminControl />
                <div className="w-full">
                  {t('navbar.superOwnerPanel')}
                </div>
              </Button>
            </div>
          </>
        )}

        {user.isTranslator() && (
          <>
            <div>
              <Button
                kind="ghost"
                className="w-full flex justify-center gap-2 items-center text-[var(--cds-text-primary)]"
                onClick={() => navigate('/translation-tool')}
              >
                <Language />
                <div className="w-full">
                  Translation Tool
                </div>
              </Button>
            </div>
          </>
        )}

        {!isLoadingAccounts && <OrganizationMenu accounts={accounts || []} />}

        <div className="flex gap-2 px-4 py-2">
          <div><UserAvatar /></div>
          <div className="flex flex-col gap-2">
            <div>{t('navbar.loggedInAs')}</div>
            <div className="flex flex-col gap-1">
              <div className="flex">
                <div className="text-[var(--cds-text-secondary)] w-12">{t('signup.user')}</div>
                <div>{user.getName()}</div>
              </div>
              <div className="flex">
                <div className="text-[var(--cds-text-secondary)] w-12">{t('signup.email')}</div>
                <div>{user.email}</div>
              </div>
              <div className="flex">
                <div className="text-[var(--cds-text-secondary)] w-12">{t('settings.role')}</div>
                <div className="capitalize">{t('account.type.' + currentAccount.type)}</div>
              </div>
            </div>
            <Button kind="tertiary" size="md" onClick={() => authContext.logout()}>
              {t('navbar.logOut')}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
