import { Tree, TreeDisplayConfiguration } from '../../../tree/Tree';
import TreeService from '../../../tree/TreeService';
import { UrlContext } from '../UrlContext';
import { TFunction } from 'react-i18next';
import { DetailsBackUrl } from '../../../utils/DetailsBackUrl';
import * as THREE from 'three';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import PanoramicTreeMarker from '../tree-marker/PanoramicTreeMarker';
import { AdvancedFilterConfiguration } from '../table-view/advanced-filter/AdvancedFilter';
import { Organization } from '../../../organization/Organization';
import { Account } from '../../../account/Account';
import { ColoringType } from '../../../components/Navbar/PropertyLegend';
import FilterCompound from '../../../filter/FilterCompound';
import { Flippers } from '../../../switches/Flippers';

export default class TreeMarkerHandler {
  treeMarkers: PanoramicTreeMarker[] = [];
  private treePromise: Promise<Tree> | null = null;
  private hoverTimeout: HoverTimerForTreeId = new HoverTimerForTreeId();
  private treeMarkersGroup: THREE.Group = new THREE.Group();
  private TREE_MARKERS_GROUP_NAME = 'tree-markers-group';

  constructor(
    private readonly treeService: TreeService,
    readonly scene: THREE.Scene,
    readonly organization: Organization,
    readonly urlContext: UrlContext,
    readonly selectedTree: Tree | null,
    readonly t: TFunction,
    readonly location,
    readonly navigate,
    readonly isDemo,
    readonly tracker
  ) {
  }

  hasMarker(tree: Tree) {
    return this.treeMarkers.some(marker => marker.treeId === tree.id);
  }

  addTreeMarkers(
    visibleTrees: Tree[],
    currentCapturePointId: string | undefined,
    treeDisplayConfiguration: TreeDisplayConfiguration,
    hideMarkers: boolean,
    propertyConfig: PropertyConfiguration | null,
    advancedFilterConfig: AdvancedFilterConfiguration,
    filter: FilterCompound,
    coloringType: ColoringType,
    hideLabel: boolean,
    account: Account,
    centerPosition: THREE.Vector3
  ) {
    this.treeMarkers = visibleTrees.map(tree => PanoramicTreeMarker.create(this.treeService, tree, this.organization, this.t, this.onDetailsButtonClick.bind(this), new THREE.Vector3(...tree.localizedLocation).applyAxisAngle(
      new THREE.Vector3(1, 0, 0),
      -Math.PI / 2
    ).sub(centerPosition)));
    const group = this.scene.children.find(child => child.name === this.TREE_MARKERS_GROUP_NAME) as THREE.Group;
    if (group) {
      this.treeMarkersGroup = group;
    } else {
      this.treeMarkersGroup = new THREE.Group();
      this.treeMarkersGroup.name = this.TREE_MARKERS_GROUP_NAME;
    }
    this.initTreeMarkers(currentCapturePointId, treeDisplayConfiguration, hideMarkers, propertyConfig, advancedFilterConfig, filter, coloringType, hideLabel, account);
  }

  applyDisplayConfiguration(
    treeDisplayConfiguration: TreeDisplayConfiguration,
    selectedTree: Tree | null,
    selectedPropertyConfig: PropertyConfiguration | null = null,
    advancedFilterConfig: AdvancedFilterConfiguration,
    filter: FilterCompound,
    coloringType: ColoringType,
    hideLabels = false,
    account: Account
  ) {
    this.treeMarkers.forEach(it => {
      it.applyDisplayConfiguration(treeDisplayConfiguration, selectedPropertyConfig, advancedFilterConfig, filter, coloringType, hideLabels, account);
      if (!selectedTree) return it.deselect();
      if (it.isFilteredBy(advancedFilterConfig, treeDisplayConfiguration)) return it.disable();
      if (selectedTree) {
        if (it.belongsTo(selectedTree)) {
          it.select();
        } else {
          it.deselect();
        }
      }
    });
  }

  setSelectedTreePropertyRangeIndex(selectedPropertyConfig: PropertyConfiguration | null, index: number, windSpeed: number) {
    this.treeMarkers.forEach(it => it.changeOpacity(selectedPropertyConfig, index, windSpeed));
  }

  hideMarkers() {
    this.treeMarkers.forEach(it => it.setMarkerVisibility(false));
  }

  showMarkers() {
    this.treeMarkers.forEach(it => it.setMarkerVisibility(true));
  }

  removeEventListeners() {
    this.treeMarkers.forEach(it => it.removeEventListeners());
  }

  private initTreeMarkers(
    currentCapturePointId: string | undefined,
    treeDisplayConfiguration: TreeDisplayConfiguration,
    hideMarkers: boolean,
    propertyConfig: PropertyConfiguration | null,
    advancedFilterConfig: AdvancedFilterConfiguration,
    filter: FilterCompound,
    coloringType: ColoringType,
    hideLabel: boolean,
    account: Account
  ) {
    this.removeAllMarkersFromScene();

    this.treeMarkers.forEach(it => {
      this.initTreeMarker(it, currentCapturePointId, treeDisplayConfiguration, hideMarkers, propertyConfig, advancedFilterConfig, filter, coloringType, hideLabel, account);
      this.treeMarkersGroup.add(it.group);
    });

    this.scene.add(this.treeMarkersGroup);
  }

  private initTreeMarker(
    treeMarker: PanoramicTreeMarker,
    currentCapturePointId: string | undefined,
    treeDisplayConfiguration: TreeDisplayConfiguration,
    hideMarkers: boolean,
    propertyConfig: PropertyConfiguration | null,
    advancedFilterConfig: AdvancedFilterConfiguration,
    filter: FilterCompound,
    coloringType: ColoringType,
    hideLabel: boolean,
    account: Account
  ) {
    treeMarker.onHover(tree => this.onTreeMarkerHover(tree, propertyConfig));
    treeMarker.onClick(tree => this.onTreeMarkerClick(tree, treeMarker, currentCapturePointId));

    treeMarker.applyDisplayConfiguration(treeDisplayConfiguration, propertyConfig, advancedFilterConfig, filter, coloringType, hideLabel, account);

    if (treeMarker.belongsTo(this.selectedTree)) {
      treeMarker.select();
    }
    if (hideMarkers) {
      treeMarker.setMarkerVisibility(false);
    }
  }

  private removeAllMarkersFromScene() {
    this.treeMarkersGroup.children.forEach(it => {
      it.clear();
      this.scene.remove(it);
    });
  }

  private onTreeMarkerHover(tree: Tree | null, propertyConfig: PropertyConfiguration | null) {
    if (this.treeMarkers.find(it => it.belongsTo(tree))?.isHidden()) return;

    if (tree === null && this.hoverTimeout.isSet()) {
      this.hoverTimeout.resetTimer();
    }
    const treeId = tree?.id;
    if (!treeId) return;
    this.treePromise = this.treeService.find(this.organization, treeId);
    this.hoverTimeout.setTimer(treeId, () => {
      this.showTreeNamecard(tree, propertyConfig);
    });
  }

  private showTreeNamecard(tree: Tree, propertyConfig: PropertyConfiguration | null) {
    this.treeMarkers.forEach(marker => {
      if (marker.belongsTo(tree)) {
        this.treePromise?.then(fullTree => {
          if (fullTree.id === tree.id && this.hoverTimeout.getTreeId() === fullTree.id) {
            this.hoverTimeout.resetTimer();
            this.tracker.track(this.tracker.events.TREE_NAME_CARD_SHOWN_FROM_PANO, { tree });
            return marker.showTreeNamecard(fullTree, propertyConfig);
          } else {
            return null;
          }
        });
      } else {
        marker.hideTreeNamecard();
      }
    });
  }

  private onTreeMarkerClick(tree: Tree, marker: PanoramicTreeMarker, capturePointId: string | undefined) {
    const isDisabledMarkerClicked = this.treeMarkers.find(it => it.belongsTo(tree))?.isHidden();
    if (isDisabledMarkerClicked) return;

    const isSelectedMarkerClicked = tree.id === this.urlContext.getTreeId();
    if (isSelectedMarkerClicked) {
      marker.deselect();
      if (capturePointId) {
        this.urlContext.setCapturePointId(capturePointId);
      }
      this.urlContext.setTreeId('');
      return;
    }

    this.urlContext.setTreeId(tree.id);
    this.tracker.track(this.tracker.events.TREE_SELECT_FROM_MAP_PANO, { treeId: tree.id });
    this.urlContext.setCameraRotation(null);
  }

  private onDetailsButtonClick(tree: Tree) {
    this.tracker.track(this.tracker.events.TREE_NAME_CARD_GO_TO_DETAILS_FROM_PANO);
    if (this.organization.isEnabled(Flippers.workspace)) {
      this.navigate(`/organizations/${this.organization.id}/inventory/trees/${tree.id}`);
    } else {
      DetailsBackUrl.store(tree.id, this.location);
      let url = `/organizations/${this.organization.id}/trees/${tree.id}/`;
      url += this.organization.isDemo ? 'analytics/general' : 'details';
      this.navigate(url);
    }
  }
}

export class HoverTimerForTreeId {
  private timer: ReturnType<typeof setTimeout> | null = null;
  private forTreeId: string | null = null;

  resetTimer() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
      this.forTreeId = null;
    }
  }

  isSet() {
    return this.timer !== null && this.forTreeId !== null;
  }

  getTreeId() {
    return this.forTreeId;
  }

  setTimer(treeId: string | null, cb: () => unknown) {
    if (treeId === null) return;
    this.forTreeId = treeId;
    this.timer = setTimeout(() => {
      if (this.isSet()) {
        cb();
      }
    }, 500);
  }
}
