import { Chart } from 'chart.js';

export default class PointLabelPlugin {
  readonly id = 'labelPlugin';
  private readonly px = 12;
  private readonly py = 6;
  private fontSizePx = 12;

  afterDraw(chart: Chart) {
    const ctx = chart.ctx;
    for (const dataset of chart.data.datasets) {
      for (let i = 0; i < dataset.data.length; i++) {
        if (dataset.data[i]) this.drawLabelAt(ctx, chart.scales.x.getPixelForValue(i, 0), chart.scales.y.getPixelForValue(dataset.data[i] as number, 0), (dataset.data[i] as number).toFixed(2));
      }
    }
  }

  private drawLabelAt(ctx: CanvasRenderingContext2D, x: number, y: number, text: string) {
    const { width: textWidth } = ctx.measureText(text);
    const height = this.fontSizePx + 2 * this.py;
    this.renderRoundedRectangle(ctx, x - textWidth / 2 - this.px, y - height / 2, textWidth + 2 * this.px, height);
    this.renderText(ctx, text, x - textWidth / 2, y + this.fontSizePx / 2 - 1);
  }

  private renderRoundedRectangle(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number) {
    ctx.fillStyle = '#0ABF91';
    ctx.beginPath();
    ctx.roundRect(x, y, width, height, height / 2);
    ctx.fill();
  }

  private renderText(ctx: CanvasRenderingContext2D, text: string, x: number, y: number) {
    ctx.font = `${this.fontSizePx}px Inter`;
    ctx.fillStyle = '#fff';
    ctx.fillText(text, x, y);
  }
}
