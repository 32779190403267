import { DisplayableTreeProperty, Tree } from '../tree/Tree';
import { NumericFormat } from '../numeric-format/NumericFormat';

export type PropertyRange = {
    from: number,
    to: number,
    name: string,
    value?: string
};

export type MinMaxRange = {
    min: number,
    max: number,
    initial: number
};

export default class PropertyConfiguration {
  static fromDto(dto: PropertyConfigurationDto) {
    return new PropertyConfiguration(dto.id, dto.property, dto.isStandard, dto.ranges, dto.reversedOrder);
  }

  constructor(
        readonly id: string,
        readonly property: DisplayableTreeProperty,
        readonly isStandard: boolean,
        readonly ranges: PropertyRange[],
        readonly reversedOrder: boolean
  ) {
  }

  getSize() {
    return this.ranges.length;
  }

  isOutsideRange(rangeIndex: number, tree: Tree, windSpeed: number | null = null) {
    const range = this.ranges[rangeIndex];
    if (!range) return false;
    const isFirstRange = rangeIndex === 0;
    if (this.property === DisplayableTreeProperty.SafetyFactors && windSpeed !== null) {
      const value = tree.getSafetyFactor(windSpeed);
      if (isFirstRange) {
        return value !== null ? (value > range.to || value < range.from) : true;
      }
      return value !== null ? (value > range.to || value <= range.from) : true;
    }
    return tree.isOutsideRange(this.property, range.from, range.to, isFirstRange);
  }

  getTooltip(idx: number): string {
    const range = this.ranges[idx];
    return `${range.name} ${NumericFormat.range(range.from, range.to, 'hu-HU')}`;
  }

  getFormattedRange(idx: number): string {
    const range = this.ranges[idx];
    return `${NumericFormat.range(range.from, range.to, 'hu-HU')}`;
  }

  getRangeIndexForValue(value: number | string, enumProperty?: boolean) {
    if (enumProperty) {
      return this.ranges.findIndex(range => range.value === value?.toString());
    }
    return this.ranges.findIndex(range => range.to >= value && range.from <= value);
  }

  getMinMaxValues(): MinMaxRange {
    const min = this.ranges[0].from;
    const max = this.ranges[this.ranges.length - 1].to;
    return { min, max, initial: min + ((max - min) / 2) };
  }

  hasOverlappingRanges(): boolean {
    const topBoundaries = this.ranges.map(it => it.to);
    return new Set(topBoundaries).size !== topBoundaries.length;
  }

  toDto() {
    return {
      id: this.id,
      property: this.property,
      isStandard: this.isStandard,
      ranges: this.ranges,
      reversedOrder: this.reversedOrder
    };
  }
}

export type PropertyConfigurationDto = {
    id: string,
    property: DisplayableTreeProperty,
    isStandard: boolean,
    ranges: PropertyRange[],
    reversedOrder: boolean
};

export type PropertyConfigRange = [string, [string, string][]];
