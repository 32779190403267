import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import PointLabelPlugin from './PointLabelPlugin';
import useCarbonThemes from '../../../components/UI/Carbon/useCarbonThemes';

export type Value = { value: number, year: string };

interface HistoryChartProps {
  data: Value[],
  setLastValue?: (value: number) => void
}

export function HistoryChart(props: HistoryChartProps) {
  const { colors } = useCarbonThemes();
  Chart.defaults.font.family = "'Inter', sans-serif";
  const options = {
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: { display: false }
    },
    scales: {
      y: {
        grid: { color: colors.borderSubtle00, drawTicks: false },
        ticks: { color: colors.textPrimary },
        min: 0
      },
      x: {
        grid: { color: colors.borderSubtle00, drawTicks: false },
        ticks: { color: colors.textPrimary }
      }
    }
  };
  const extendedData = [props.data[0], ...props.data, props.data.at(-1)!];

  const data = {
    labels: extendedData.map((it, index) => index === 0 || index === extendedData.length - 1 ? '' : it.year),
    datasets: [
      {
        data: extendedData.map((it, index) => index === 0 || index === extendedData.length - 1 ? undefined : it.value),
        fill: false,
        borderColor: '#0ABF91',
        pointBackgroundColor: '#0ABF91'
      }
    ]
  };

  return (
    <Line
      data={data}
      options={options}
      plugins={[new PointLabelPlugin()]}
    />
  );
}
