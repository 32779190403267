import { useAvailableProperties, usePropertyConfigurations } from '../../../../properties/usePropertyConfigurations';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import {
  AdvancedFilterConfigurationBuilder
} from '../../../Explore/table-view/advanced-filter/AdvancedFilterConfigurationBuilder';
import { Button, Dropdown, Tooltip } from '@carbon/react';
import { CloseLarge } from '@carbon/icons-react';

export default function PropertyLegend(props: LegendProps) {
  const propertyConfigs = usePropertyConfigurations();
  const selectedConfig = propertyConfigs.isLoading ? undefined : propertyConfigs.data?.find(it => it.property === props.selectedProperty);
  const account = useCurrentAccount();
  const organization = account.organization;

  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const properties = useAvailableProperties();
  const propertyDropdownItems = Object.keys(properties).reduce((acc, key) => {
    acc = [...acc, ...properties[key]];
    return acc;
  }, [] as DisplayableTreeProperty[]);

  const hideValueLabelsFor = [
    DisplayableTreeProperty.OverallOutlierIndex,
    DisplayableTreeProperty.WireClearanceIssueDetected,
    DisplayableTreeProperty.RoadClearanceIssueDetected,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.TmsCategory,
    DisplayableTreeProperty.AgeClass,
    DisplayableTreeProperty.ViStatus,
    DisplayableTreeProperty.Condition,
    DisplayableTreeProperty.CrownTransparency,
    DisplayableTreeProperty.CrownLightExposure,
    DisplayableTreeProperty.OutlierHeightPerCrownVolume,
    DisplayableTreeProperty.OutlierHeightPerLeafArea,
    DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
    DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea,
    DisplayableTreeProperty.VitalityVigor,
    DisplayableTreeProperty.GrowSpace,
    DisplayableTreeProperty.OverheadUtilities,
    DisplayableTreeProperty.PrevailingWindDirection,
    DisplayableTreeProperty.CoDominantStems,
    DisplayableTreeProperty.Fork,
    DisplayableTreeProperty.FoliageNoneSeasonal,
    DisplayableTreeProperty.FoliageNoneDead,
    DisplayableTreeProperty.IncludedBark
  ];

  if (!(selectedConfig)) return <></>;
  const colorMap = PropertyColorConfiguration.getColorsForConfig(selectedConfig!);

  const unit = Tree.getUnit(selectedConfig.property, organization);

  const onClose = () => {
    urlContext.setSelectedTreeProperty(null);
    const builder = AdvancedFilterConfigurationBuilder.from(urlContext.getAdvancedFilterConfiguration());
    builder.removeProperty(selectedConfig.property);
    urlContext.setAdvancedFilterConfiguration(builder.build());
  };

  const onSelect = (property: DisplayableTreeProperty | null) => {
    const advancedFilterConfig = urlContext.getAdvancedFilterConfiguration();
    if (advancedFilterConfig.propertyConfigs.length) {
      const builder = AdvancedFilterConfigurationBuilder.from(advancedFilterConfig);
      builder.removeProperty(advancedFilterConfig.propertyConfigs[0].property);
      urlContext.setAdvancedFilterConfiguration(builder.build());
    }

    urlContext.setSelectedTreeProperty(property);
  };

  function capitalizeFirstLetter(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (<div className='bg-[var(--cds-layer-01)] shadow-lg p-4 pb-6'>
    <div className="text-sm font-semibold mb-2.5 flex justify-between items-center">
      <span>{t('treeList.mapAnalytics')}</span>
      <Tooltip label={t('tooltips.close')} align="right">
        <Button
          kind="ghost"
          size="md"
          onClick={onClose}
        >
          <CloseLarge />
        </Button>
      </Tooltip>
    </div>
    <div className="w-60 pb-4">
      <Dropdown
        label=''
        titleText=''
        id='property-dropdown'
        size="lg"
        selectedItem={{ id: selectedConfig.property, translationKey: Tree.renderPropertyName(selectedConfig.property, t) }}
        items={propertyDropdownItems.map(it => ({ id: it, translationKey: Tree.renderPropertyName(it, t) }))}
        itemToString={it => it ? Tree.renderPropertyName(it.id, t) : ''}
        onChange={data => onSelect(data.selectedItem?.id || null)}
      />
    </div>
    <div>
      <div
        className="flex flex-col gap-2.5 text-sm font-normal"
      >
        {selectedConfig.ranges.map((range, idx) =>
          <div
            className="flex"
            key={idx}
          >
            <div className="flex justify-center pt-1 px-3">
              <div
                className='w-3 h-3 rounded-full'
                style={{ backgroundColor: `rgb(${colorMap[idx]})` }}
              ></div>
            </div>
            <div>
              <div>{range.value ? Tree.renderPropertyValue(selectedConfig.property, range.name, t) : capitalizeFirstLetter(range.name)}</div>
              {!hideValueLabelsFor.includes(props.selectedProperty) && <div>{selectedConfig.getFormattedRange(idx)} {unit ? t(`units.${unit}`) : ''}</div>}
            </div>
          </div>
        )}
      </div>
      <div>
      </div>
    </div>
  </div>);
}

interface LegendProps {
  selectedProperty: DisplayableTreeProperty
}
