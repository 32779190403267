import PartialTree from './PartialTree';
import { TreeDto } from './Tree';
import { plainToInstance } from 'class-transformer';

export class FilteredPartialTree extends PartialTree {
  private static fromPartialTree(partialTree: PartialTree, filtered: boolean): FilteredPartialTree {
    return plainToInstance(FilteredPartialTree, { filtered, ...partialTree });
  }

  static override fromDto(dto: Partial<TreeDto> & { filtered: boolean }): FilteredPartialTree {
    return FilteredPartialTree.fromPartialTree(PartialTree.fromDto(dto), dto.filtered);
  }

  constructor(readonly filtered: boolean, ...args: ConstructorParameters<typeof PartialTree>) {
    super(...args);
  }
}
