import { TreeDisplayConfiguration } from '../../../tree/Tree';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from '../../../components/Panorama/PanoramaView.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CapturePoint from '../../../capture-point/CapturePoint';
import { useTranslation } from 'react-i18next';
import { LoadablePointCloud } from '../../../point-cloud/LoadablePointCloud';
import { PanoramicView } from './PanoramicView';
import TreeMarkerHandler from './TreeMarkerHandler';
import PanoramicDataHandler from './PanoramicDataHandler';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Minimap } from './minimap/Minimap';
import { useTracking } from '../../../analytics/useTracking';
import useMountedEffect from '../../../hooks/useMountedEffect';
import DetailedTree from '../../../tree/DetailedTree';
import FilterCompound from '../../../filter/FilterCompound';

export function PanoramicViewer(props: PanoramicViewProps) {
  const { treeService, capturePointService } = useContext(DependencyInjectionContext);

  const [currentCapturePoint, setCurrentCapturePoint] = useState<CapturePoint | null>(null);
  const [currentCapturePointId, setCurrentCapturePointId] = useState<string | null>(null);
  const [treeMarkersRendered, setTreeMarkersRendered] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const viewRef = useRef<PanoramicView | null>(null);
  const miniMapRef = useRef<typeof Minimap | null>(null);
  const isCanvasRendered = useCallback(node => {
    if (node !== null) {
      canvasRef.current = node;
    }
  }, []);
  const account = useCurrentAccount();
  const { events, track } = useTracking();
  const organization = account.organization;
  const treeMarkerHandlerRef = useRef<TreeMarkerHandler | null>(null);
  const preloadedDataRef = useRef<{ [capturePointId: string]: any }>({});
  const dataHandlerRef = useRef<PanoramicDataHandler | null>(null);

  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const setAngleToNorth = useCallback(angle => {
    if (!miniMapRef.current) return;
    (miniMapRef.current as any).rotate(angle);
  }, [miniMapRef]);

  useEffect(() => {
    viewRef.current?.setSize();
  }, [canvasRef.current?.parentElement?.clientHeight]);

  useEffect(() => {
    if (!isCanvasRendered) return;
    viewRef.current = PanoramicView.create(canvasRef.current!, styles, organization.isMetric);
    return () => {
      viewRef.current?.dispose();
    };
  }, [viewRef, isCanvasRendered, organization.isMetric]);

  useEffect(() => {
    if (!viewRef.current || !treeMarkerHandlerRef.current) return;

    const treeMarkerHandler = treeMarkerHandlerRef.current!;

    if (!treeMarkersRendered) return;
    treeMarkerHandler.applyDisplayConfiguration(
      props.treeDisplayConfiguration,
      props.tree,
      props.selectedPropertyConfig,
      urlContext.getAdvancedFilterConfiguration(),
      FilterCompound.fromConfig(urlContext.getFilterConfiguration()),
      urlContext.getColoringType(),
      props.hideLabels,
      account
    );

    if (props.hideMarkers) {
      treeMarkerHandler.hideMarkers();
    } else {
      treeMarkerHandler.showMarkers();
    }
  }, [
    JSON.stringify(props.treeDisplayConfiguration),
    props.hideMarkers,
    props.selectedPropertyConfig,
    props.hideLabels,
    JSON.stringify(urlContext.getAdvancedFilterConfiguration()),
    JSON.stringify(urlContext.getFilterConfiguration()),
    props.tree?.id,
    treeMarkersRendered,
    JSON.stringify(urlContext.getColoringType())
  ]);

  useEffect(() => {
    if (!viewRef.current) return;

    if (props.hideTreePointCloud) {
      viewRef.current.hideTreePointCloud();
    } else {
      viewRef.current.showTreePointCloud();
    }
    viewRef.current?.render();
  }, [props.hideTreePointCloud]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = viewRef.current;
    const selectedTree = props.tree;
    if (!canvas || !view || !view.scene || !selectedTree) return;

    view.measurement.reset();

    const cameraRotation = urlContext.getCameraRotation();

    if (urlContext.getCapturePointId() === selectedTree.capturePointId && cameraRotation) {
      view.applyRotation(cameraRotation);
      view.clearListeners();
      view.listen(setAngleToNorth);
      view.render();
      return;
    }

    urlContext.setCameraRotation(null);
    urlContext.setCapturePointId(selectedTree.capturePointId);
  }, [props.viewResetTriggerKey]);

  const currentCapturePoints = useRef<{ id: string, location: [number, number, number] }[]>([]);
  const [preloadedMeshes, setPreloadedMeshes] = useState<any[] | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const canvas = canvasRef.current;
    const view = viewRef.current;
    if (!canvas || !view || !view.scene) return;

    if (props.tree === null) {
      view.removePointCloud();
      view.render();
      return;
    }

    if ((!urlContext.getCapturePointId() && !currentCapturePointId) || !treeMarkerHandlerRef.current?.hasMarker(props.tree)) {
      setCurrentCapturePointId(props.tree.capturePointId);
    }

    view.measurement.reset();

    treeMarkerHandlerRef.current?.applyDisplayConfiguration(
      props.treeDisplayConfiguration,
      props.tree,
      props.selectedPropertyConfig,
      urlContext.getAdvancedFilterConfiguration(),
      FilterCompound.fromConfig(urlContext.getFilterConfiguration()),
      urlContext.getColoringType(),
      props.hideLabels,
      account
    );

    new LoadablePointCloud(props.tree.getPointCloudUrl(account.organization)).loadIntoWithTreeId(props.tree.id, props.tree.localizedLocation).then(pc => {
      if (!props.tree) {
        return;
      }

      if (pc && pc.belongsTo(props.tree)) {
        view.removePointCloud();
        view.setPointCloud(pc, props.tree);
        if (props.hideTreePointCloud) {
          view.hideTreePointCloud();
        }
        view.render();
      }
    });
  }, [props.tree, account.organization]);

  useMountedEffect(() => {
    const view = viewRef.current;
    if (!view) return;

    if (props.isLineMeasureEnabled) {
      track(events.LINE_MEASUREMENT_CHANGE_IN_PANORAMIC_VIEW, { enabled: true });
      view.enableLineMeasure();
    } else {
      track(events.LINE_MEASUREMENT_CHANGE_IN_PANORAMIC_VIEW, { enabled: false });
      view.disableLineMeasure();
    }
    view.render();
    return () => view.disableLineMeasure();
  }, [props.isLineMeasureEnabled]);

  useEffect(() => {
    if (!treeMarkerHandlerRef.current) return;
    treeMarkerHandlerRef.current.setSelectedTreePropertyRangeIndex(props.selectedPropertyConfig, props.selectedTreePropertyRangeIndex, props.treeDisplayConfiguration.windSpeed);
  }, [props.selectedTreePropertyRangeIndex, props.selectedPropertyConfig, props.treeDisplayConfiguration.windSpeed]);

  useEffect(() => {
    const capturePointChange = urlContext.getCapturePointId();
    if (!capturePointChange) return;
    setCurrentCapturePointId(capturePointChange);
  }, [urlContext.getCapturePointId()]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !viewRef.current || !viewRef.current.scene) return;
    const view: PanoramicView = viewRef.current;

    if (!dataHandlerRef.current || dataHandlerRef.current?.organization.id !== organization.id) {
      dataHandlerRef.current = new PanoramicDataHandler(treeService, capturePointService, organization);
    }

    setTreeMarkersRendered(false);

    view.resetScene();

    const scene = view.scene;

    const setStateForNewCapturePointData = capturePoints => {
      currentCapturePoints.current = capturePoints;
    };

    const onCapturePointClick = (capturePoint: { id: string }, camera) => {
      let promise;
      if (!preloadedDataRef.current[capturePoint.id]) {
        promise = preloadDataForCapturePoint(capturePoint);
      } else {
        promise = preloadedDataRef.current[capturePoint.id];
      }
      promise.then(data => {
        if (data) {
          setPreloadedMeshes(data.meshes);
          setStateForNewCapturePointData(data.capturePoints);
        }
        urlContext.setCameraRotation(camera.rotation.toArray());
        urlContext.setCapturePointId(capturePoint.id);
      });
      track(events.CAPTURE_POINT_CHANGE_FROM_PANORAMIC_VIEW, { from: currentCapturePointId, to: capturePoint.id });
    };

    const handleCapturePointHover = (capturePoint: { id: string }) => {
      if (preloadedDataRef.current[capturePoint.id]) {
        return;
      }

      preloadedDataRef.current[capturePoint.id] = preloadDataForCapturePoint(capturePoint);
    };

    const preloadDataForCapturePoint = async (marker: { id: string }) => {
      const nextCapturePoint = currentCapturePoints.current.find(it => it.id === marker.id);
      if (nextCapturePoint && dataHandlerRef.current) {
        const panoramicData = await dataHandlerRef.current?.load(nextCapturePoint, null, account);
        const { visibleTrees, images, capturePoints, currentCapturePoint } = panoramicData!;
        const meshes = view.imagesToMeshes(images);
        await Promise.all(meshes.map(it => it.loadLowResolutionImageUrl()));

        return { visibleTrees, images, capturePoints, currentCapturePoint, meshes, nextCapturePoint };
      }
    };

    (async () => {
      if (!canvas || !viewRef.current || !viewRef.current.scene || !currentCapturePointId) return;

      viewRef.current.resetScene();

      const capturePointId = currentCapturePointId;

      if (!props.tree && !capturePointId) return;

      const cameraRotation = urlContext.getCameraRotation();

      const parentBounds = canvas.parentElement!.getBoundingClientRect();
      canvas.style.height = `${parentBounds.height}px`;
      canvas.style.width = `${parentBounds.width}px`;

      const capturePoint = await capturePointService.show(organization.id, capturePointId);
      setCurrentCapturePoint(capturePoint);

      treeMarkerHandlerRef.current = new TreeMarkerHandler(treeService, scene, organization, urlContext, props.tree, t, location, navigate, account.organization.isDemo, { track, events });

      const panoramicData = await dataHandlerRef.current!.load(capturePoint, props.tree, account);
      if (!panoramicData) return;
      const { visibleTrees, images, capturePoints, currentCapturePoint } = panoramicData;
      setStateForNewCapturePointData(capturePoints);

      await view.setSphereAndPutCameraThere(images, preloadedMeshes, props.tree);
      setPreloadedMeshes(null);

      treeMarkerHandlerRef.current!.addTreeMarkers(
        visibleTrees,
        currentCapturePoint?.id,
        props.treeDisplayConfiguration,
        props.hideMarkers,
        props.selectedPropertyConfig,
        urlContext.getAdvancedFilterConfiguration(),
        FilterCompound.fromConfig(urlContext.getFilterConfiguration()),
        urlContext.getColoringType(),
        props.hideLabels,
        account,
        view.centerPosition
      );

      setTreeMarkersRendered(true);

      view.setCapturePointsAsCss3D(capturePoints, onCapturePointClick, handleCapturePointHover);

      if (props.tree) {
        view.lookAtTree(props.tree, organization.isMetric);
      }

      if (cameraRotation) {
        view.applyRotation(cameraRotation);
      }

      view.clearListeners();
      view.listen(setAngleToNorth);
      setAngleToNorth(view.getNorthAngle());
      view.setSize();
      view.render();
    })();
    return () => {
      if (!canvas) return;
      treeMarkerHandlerRef.current?.removeEventListeners();
      view.clearListeners();
    };
  }, [
    viewRef.current,
    organization.id,
    isCanvasRendered,
    currentCapturePointId
  ]);

  return (
    <>
      <div className={styles.container}>
        <canvas ref={isCanvasRendered}/>
      </div>

      <Minimap
        ref={miniMapRef}
        currentCapturePoint={currentCapturePoint}
        organization={account.organization}/>
    </>
  );
}

export interface PanoramicViewProps {
  isLineMeasureEnabled: boolean,
  hideMarkers: boolean,
  hideLabels: boolean,
  hideTreePointCloud: boolean,
  tree: DetailedTree | null,
  treeDisplayConfiguration: TreeDisplayConfiguration,
  viewResetTriggerKey: { key?: number, on?: string },
  selectedPropertyConfig: PropertyConfiguration | null,
  selectedTreePropertyRangeIndex: number
}
