import { useActivityGroups } from './useActivityGroups';
import { Button, IconButton, TextInput } from '@carbon/react';
import React, { useContext, useRef, useState, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Checkmark, TrashCan } from '@carbon/icons-react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Activity, ActivityGroup } from './ActivityGroup';

export default function ActivityGroups() {
  const { activityGroups } = useActivityGroups();
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const { t } = useTranslation();

  const [creatingActivityGroup, setCreatingActivityGroup] = useState(false);
  const [activityGroupName, setActivityGroupName] = useState('');
  const [editingActivityGroup, setEditingActivityGroup] = useState<string | null>(null);

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  const createNewActivityGroup = async () => {
    if (activityGroupName === '') return;
    await taskManagerService.addActivityGroup(organization.id, { name: activityGroupName });
    setActivityGroupName('');
    setCreatingActivityGroup(false);
  };

  const cancelCreatingActivityGroup = () => {
    setActivityGroupName('');
    setCreatingActivityGroup(false);
  };

  const cancelCreate = (event: FocusEvent) => {
    if (event.relatedTarget === saveButtonRef.current || event.relatedTarget === deleteButtonRef.current) return;
    setActivityGroupName('');
    setCreatingActivityGroup(false);
  };

  return (
    <section className="twp px-8 pt-4 w-[320px]">
      <div className="text-xl font-normal leading-7 mb-4">{t('taskManager.activities')}</div>
      <ul className="flex flex-col divide-y divide-[var(--cds-border-subtle-00)]">
        {activityGroups.map(activityGroup => (
          <ActivityGroupRow
            key={activityGroup.id}
            activityGroup={activityGroup}
            editingActivityGroup={editingActivityGroup}
            setEditingActivityGroup={setEditingActivityGroup}
          />
        ))}
      </ul>
      {creatingActivityGroup
        ? <div className="flex">
          <TextInput
            id='new-activity-group'
            labelText=''
            value={activityGroupName}
            onChange={e => setActivityGroupName(e.target.value)}
            onBlur={cancelCreate}
            autoFocus={true}
          />
          <IconButton
            onClick={createNewActivityGroup}
            label={t('taskManager.save')}
            align="bottom"
            kind="primary"
            size='md'
            ref={saveButtonRef}
          >
            <Checkmark />
          </IconButton>
          <IconButton
            onClick={cancelCreatingActivityGroup}
            label={t('taskManager.delete')}
            align='bottom'
            kind='secondary'
            size='md'
            ref={deleteButtonRef}
          >
            <TrashCan />
          </IconButton>
        </div>
        : <Button
          onClick={() => setCreatingActivityGroup(true)}
          kind="tertiary"
          size="lg"
          renderIcon={Add}
          disabled={Boolean(editingActivityGroup) || creatingActivityGroup}
        >
          {t('taskManager.addActivityGroup')}
        </Button>}
    </section>
  );
}

interface ActivityGroupRowProps {
  activityGroup: ActivityGroup,
  editingActivityGroup: string | null,
  setEditingActivityGroup: (id: string | null) => void
}

const ActivityGroupRow = (props: ActivityGroupRowProps) => {
  const { t } = useTranslation();
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const [activityGroupName, setActivityGroupName] = useState<string>(props.activityGroup.name);
  const [editing, setEditing] = useState(false);

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  const updateActivityGroup = async () => {
    await taskManagerService.updateActivityGroup(organization.id, props.activityGroup.id, { name: activityGroupName });
    setEditing(false);
    props.setEditingActivityGroup(null);
  };

  const deleteActivityGroup = async () => {
    await taskManagerService.deleteActivityGroup(organization.id, props.activityGroup.id);
    setEditing(false);
    props.setEditingActivityGroup(null);
  };

  const startEditing = () => {
    if (props.editingActivityGroup) return;
    setEditing(true);
    props.setEditingActivityGroup(props.activityGroup.id);
  };

  const cancelEditing = (event: FocusEvent) => {
    if (event.relatedTarget === saveButtonRef.current || event.relatedTarget === deleteButtonRef.current) return;
    setEditing(false);
    props.setEditingActivityGroup(null);
    setActivityGroupName(props.activityGroup.name);
  };

  return (
    <li key={props.activityGroup.id} className="flex flex-col gap-4 py-4">
      <div className="flex items-center justify-between">
        {editing
          ? <div className="flex">
            <TextInput
              id={`editing-${props.activityGroup.id}`}
              labelText=""
              value={activityGroupName}
              onChange={e => setActivityGroupName(e.target.value)}
              onBlur={cancelEditing}
              autoFocus={true}
            />
            <IconButton
              onClick={updateActivityGroup}
              label={t('taskManager.save')}
              align="bottom"
              kind="primary"
              size="md"
              ref={saveButtonRef}
            >
              <Checkmark />
            </IconButton>
            <IconButton
              onClick={deleteActivityGroup}
              label={t('taskManager.delete')}
              align="bottom"
              kind="secondary"
              size="md"
              ref={deleteButtonRef}
            >
              <TrashCan />
            </IconButton>
          </div>
          : <div
            className="text-sm font-semibold leading-tight tracking-tight"
            onClick={startEditing}
          >
            {props.activityGroup.name}
          </div>}
        <IconButton
          onClick={() => console.log('TODO: Add activity')}
          label={t('taskManager.addActivity')}
          align="right"
          kind="ghost"
          disabled={Boolean(props.editingActivityGroup)}
        >
          <Add />
        </IconButton>
      </div>
      <ul className="flex flex-col gap-4">
        {props.activityGroup.activities.map(activity => <ActivityRow key={activity.id} {...activity} />)}
      </ul>
    </li>
  );
};

const ActivityRow = (activity: Activity) => {
  return (
    <li
      key={activity.id}
      className="text-sm font-normal leading-tight tracking-tight py-2"
    >
      {activity.name}
    </li>
  );
};
