import { useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { LngLat } from 'mapbox-gl';
import styles from './InlineMap.module.scss';
import { InlineMapTreeMarkerLayer } from './html-tree-marker/InlineMapTreeMarkerLayer';
import { useCurrentAccount } from '../../../../account/useAccounts';
import getRuntimeConfig from '../../../../RuntimeConfig';
import { CARBON_MAP_STYLES, MAP_STYLES, MAX_ZOOM_LEVEL } from '../../../../constants';
import { Tree } from '../../../../tree/Tree';
import useCarbonThemes from '../useCarbonThemes';
import { IconButton, OverflowMenu, RadioButton, RadioButtonGroup } from '@carbon/react';
import { AirportLocation, Compass, Layers } from '@carbon/icons-react';
import { Minus, Plus } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { MapStyle } from '../../../../routes/CarbonInsights/components/MapStyleSelector';

export default function InlineMap(props: InlineMapProps) {
  const account = useCurrentAccount();
  const organization = account.organization;
  const apiUrl = getRuntimeConfig().apiUrl;
  const { t } = useTranslation();
  const [styleSelectorOpen, setStyleSelectorOpen] = useState(false);

  const { theme } = useCarbonThemes();

  const mapStyle = CARBON_MAP_STYLES[theme];
  const defaultMapStyle = MAP_STYLES.satellite;
  const [selectedMapStyle, setSelectedMapStyle] = useState(MapStyle.Satellite);
  const zoomLevel = 15.5;

  const [isLoaded, setLoadingState] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const treeMarkerLayer = useMemo(
    () => {
      return new InlineMapTreeMarkerLayer(
        getRuntimeConfig().apiUrl,
        organization,
        props.tree.id,
        containerRef,
        props.tree.managedAreaId
      );
    }, [props.tree]
  );

  useEffect(() => {
    if (!containerRef.current || mapRef.current) return;

    mapRef.current = new mapboxgl.Map({
      container: containerRef.current,
      style: defaultMapStyle,
      dragRotate: true,
      touchZoomRotate: false,
      zoom: zoomLevel,
      maxZoom: MAX_ZOOM_LEVEL,
      maxPitch: 0,
      touchPitch: false,
      accessToken: getRuntimeConfig().mapboxApiKey,
      transformRequest: url => (url.startsWith(apiUrl) ? { credentials: 'include', url } : { url }),
      logoPosition: 'bottom-left'
    });

    mapRef.current.on('style.load', () => {
      setLoadingState(true);
    });
  }, [organization.id, apiUrl, organization]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) return;

    mapRef.current?.addLayer(treeMarkerLayer);
    if (treeMarkerLayer) {
      setCenterToSelectedTree();
    }

    return () => {
      mapRef.current?.removeLayer(treeMarkerLayer.id);
    };
  }, [isLoaded, treeMarkerLayer]);

  const setCenterToSelectedTree = () => {
    const [lat, lng] = props.tree.getWorldCoordinates();
    mapRef.current?.setCenter(new LngLat(lng, lat));
    mapRef.current?.setZoom(zoomLevel);
  };

  const resize = () => {
    mapRef.current?.resize();
    treeMarkerLayer.resize();
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      resize();
    });
    resizeObserver.observe(containerRef.current!);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleMapStyleChange = (style: MapStyle) => {
    setSelectedMapStyle(style);
    if (style === MapStyle.Default) {
      mapRef.current?.setStyle(mapStyle);
    } else {
      mapRef.current?.setStyle(MAP_STYLES.satellite);
    }
  };

  return (
    <div className={`${styles.mapWrapper} inlineMapWrapper`}>
      <div ref={containerRef} className={styles.mapContainer} />
      <div className="z-20 absolute right-3 bottom-3 flex gap-0.5">
        <OverflowMenu
          className="bg-[#22272ACC] hover:bg-[#22272A]"
          direction="top"
          flipped
          size="lg"
          renderIcon={Layers}
          menuOptionsClass="w-[238px]"
          iconClass={styleSelectorOpen ? '' : '!fill-greehill-00'}
          onOpen={() => setStyleSelectorOpen(true)}
          onClose={() => setStyleSelectorOpen(false)}
          iconDescription={t('displayModes.title')}
        >
          <RadioButtonGroup className="w-full" name="map-style" orientation={'vertical'}>
            {[MapStyle.Satellite, MapStyle.Default].map(view => (
              <RadioButton key={view} checked={selectedMapStyle === view} onClick={() => handleMapStyleChange(view)} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" labelText={t('viewSelector.' + view)} value={view} />
            ))}
          </RadioButtonGroup>
        </OverflowMenu>
        <IconButton className="bg-[#22272ACC] hover:bg-[#22272A]" onClick={() => mapRef.current?.zoomIn()} label={t('tooltips.zoomIn')}><Plus /></IconButton>
        <IconButton className="bg-[#22272ACC] hover:bg-[#22272A]" onClick={() => mapRef.current?.zoomOut()} label={t('tooltips.zoomOut')}><Minus /></IconButton>
        <IconButton className="bg-[#22272ACC] hover:bg-[#22272A]" onClick={setCenterToSelectedTree} label={t('tooltips.resetView')}><AirportLocation /></IconButton>
        <IconButton className="mb-0 mt-auto bg-[#22272ACC] hover:bg-[#22272A]" onClick={() => mapRef.current?.rotateTo(0)} align="top-right" label={t('tooltips.resetBearingToNorth')}><Compass /></IconButton>
      </div>
    </div>
  );
}

interface InlineMapProps {
  tree: Tree
}
