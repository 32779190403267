import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, IconButton, Layer } from '@carbon/react';
import { FilterRemove } from '@carbon/icons-react';
import { Tree } from '../../../tree/Tree';
import { EnumCondition, Filter, FilterGroup as FilterGroupType, Operator } from '../../../filter/FilterConfig';
import { SpeciesLists } from '../../TaskManager/create/SpeciesList';
import CarbonFilterRow from './CarbonFilterRow';

export default function CarbonFilterGroup(props: CarbonFilterGroupProps) {
  const { t } = useTranslation();

  const handleDeleteFilter = (index: number) => {
    if (props.group.filters.length === 1) return props.handleDelete();
    props.update(props.groupIndex, {
      ...props.group,
      filters: props.group.filters.filter((_, filterIndex) => filterIndex !== index)
    });
  };

  const handleAddFilter = () => {
    props.update(props.groupIndex, {
      ...props.group,
      filters: [...props.group.filters, { property: '' as keyof Tree, condition: '' as EnumCondition, value: [] }]
    });
  };

  const updateFilter = (filterIndex: number, filter: Filter) => {
    props.update(props.groupIndex, {
      ...props.group,
      filters: props.group.filters.map((f, index) => index === filterIndex ? filter : f)
    });
  };

  const updateOperator = (operator: Operator) => {
    props.update(props.groupIndex, {
      ...props.group,
      filterGroupOperator: operator
    });
  };

  const topLevelOperatorOptions = [
    { id: Operator.AND, translationKey: `taskManager.topLevelOperators.${Operator.AND}` },
    { id: Operator.OR, translationKey: `taskManager.topLevelOperators.${Operator.OR}` }
  ];

  return (
    <FilterGroup i={props.groupIndex + 1} handleDelete={props.handleDelete}>
      {props.group.filters.map((filter, index) => (
        <>
          {index === 1 && (
            <div className="pt-4 px-4 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
              <Dropdown
                className="w-[100px] float-right"
                id="dropdown"
                items={topLevelOperatorOptions}
                label=""
                onChange={e => updateOperator(e.selectedItem!.id)}
                initialSelectedItem={topLevelOperatorOptions.find(it => it.id === props.group.filterGroupOperator)}
                itemToString={item => item ? t(item.translationKey) : ''}
              />
            </div>
          )}
          {index > 1 && (
            <div className="pt-4 px-4 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
              <div className="w-[100px] float-right px-4 text-sm">
                {t(topLevelOperatorOptions.find(it => it.id === props.group.filterGroupOperator)?.translationKey || '')}
              </div>
            </div>
          )}
          <CarbonFilterRow
            index={index}
            key={`group-${props.groupIndex}-filter-${index}`}
            handleDeleteFilter={() => handleDeleteFilter(index)}
            filter={filter}
            filterIndex={index}
            update={updateFilter}
            speciesLists={props.speciesLists}
          />
        </>
      ))}
      <Button className="mb-4 w-full" onClick={handleAddFilter} kind="tertiary">{t('filter.newFilterWithinTheGroup')}</Button>
    </FilterGroup>
  );
}

interface CarbonFilterGroupProps {
  handleDelete: () => void,
  group: FilterGroupType,
  groupIndex: number,
  update: (index: number, group: FilterGroupType) => void,
  speciesLists: SpeciesLists
}

function FilterGroup({ children, i, handleDelete }: { children: ReactNode, i: number, handleDelete: () => void }) {
  const { t } = useTranslation();
  return (
    <Layer level={1}>
      <div className="px-4 bg-[var(--cds-layer)] flex flex-col gap-4 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
        <div className="col-start-1 col-end-3 flex items-center justify-between">
          <p className="m-0 text-sm">{t('filter.filterGroup') + ' ' + i.toString().padStart(2, '0')}</p>
          <IconButton onClick={handleDelete} label={t('filter.removeFilterGroup')} align="left" kind="ghost"><FilterRemove/></IconButton>
        </div>
        {children}
      </div>
    </Layer>
  );
}
