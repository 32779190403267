import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ActivityGroup } from './ActivityGroup';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Features } from '../../../switches/Features';

export function useActivityGroups(): { activityGroups: ActivityGroup[], isLoading: boolean } {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const location = useLocation();
  const isActivitiesPage = location.pathname.includes('task-manager/activities');

  const cacheKey = `${organization.id}-activity-groups`;
  const { data, isLoading } = useQuery(
    [cacheKey],
    () => {
      if (!organization.isFeatureEnabled(Features.taskManager)) return [];
      return taskManagerService.listActivityGroups(organization.id);
    },
    {
      retry: 1,
      retryOnMount: false,
      refetchInterval: isActivitiesPage ? 10000 : false
    }
  );

  return {
    activityGroups: data ?? [],
    isLoading
  };
}
