import { DisplayableTreeProperty } from '../../../tree/Tree';
import styles from './TableViewer.module.scss';
import { TFunction, useTranslation } from 'react-i18next';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDown, ArrowUp, Filter, NavArrowDown, NavArrowUp } from 'iconoir-react';
import { DetailsBackUrl } from '../../../utils/DetailsBackUrl';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import TableRow from './components/TableRow';
import { TreeFilter } from '../../../tree-filter/TreeFilter';
import { JumperNavigation } from './JumperNavigation';
import { usePropertyConfigurations } from '../../../properties/usePropertyConfigurations';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import TableHeader from './components/TableHeader';
import TableDashboard from './components/TableDashboard';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useFetchTreeList } from '../useFetchTreeList';
import { PanelLayout } from '../PanelLayout';
import { useTracking } from '../../../analytics/useTracking';
import { FilteredPartialTree } from '../../../tree/FilteredPartialTree';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';

const MIN_DRAG_DISTANCE = 10;
const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 60;

export default function TableViewer(props: TableViewerProps) {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/*');
  const propertyConfigs = usePropertyConfigurations();
  const location = useLocation();
  const navigate = useNavigate();
  const account = useCurrentAccount();
  const { track, events } = useTracking();
  const dragStartPos = useRef<number | null>(null);
  const treeListTableRef = useRef<HTMLDivElement | null>(null);
  const tableBodyContainerRef = useRef<HTMLDivElement | null>(null);
  const fullSizedContainerRef = useRef<HTMLDivElement | null>(null);

  const [hidePlaceholderColumn, setHidePlaceholderColumn] = useState(false);
  const [visibleTableFragment, setVisibleTableFragment] = useState<{ trees: { tree: FilteredPartialTree, pinned: boolean }[], offset: number }>({ trees: [], offset: 0 });
  const [currentView, setCurrentView] = useState({ from: 0, to: 50 });
  const [sort, setSort] = useState<string | null>(null);
  const [dragDistance, setDragDistance] = useState(0);
  const [treesLastLoadedAt, setTreesLastLoadedAt] = useState<number>(0);
  const { treeList, firstPageIsLoading } = useFetchTreeList(treesLastLoadedAt, sort, props.filters, props.properties);
  const [tableAnimations, setTableAnimations] = useState<{ treeId: string, promise: Promise<void> }[]>([]);
  const [columnSettings, setColumnSettings] = useState(
    new Map<Property, ColumnSettings>(props.properties.map((property): [Property, ColumnSettings] => [
      property,
      { sort: null, aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null }
    ]))
  );

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const organizationId = match?.params?.organizationId || '';
  const header = treeList?.getHeader() || [];
  const total = treeList?.getTotal() ?? 0;
  const pinnedTreesCount = treeList?.getPinnedTreesCount() || 0;
  const properties: Property[] = ['externalId', 'species', 'managedAreaId', ...props.properties];

  const advancedFilterCountWithIcon = useMemo(() => {
    if (treeList && treeList.getFilteredCount() > 0) {
      return <><span
        className={styles.highlighted}><Filter />{treeList!.getTotal() - treeList!.getFilteredCount()}</span> </>;
    }
    return null;
  }, [treeList]);

  const dashboardDescription = useMemo(() => {
    const prefix = treeList && treeList.getFilteredCount() > 0 ? 'of ' : '';
    // TODO: Figure out prefix translation
    if (props.managedAreas.length > 1) {
      return t('treeList.treeSelectionInfoForMultipleManagedAreas', {
        total: prefix + total,
        managedAreaCount: props.managedAreas.length
      });
    }
    if (props.managedAreas.length === 1) {
      const code = props.managedAreas[0].code;
      return t('treeList.treeSelectionInfoForSingleManagedArea', {
        total: prefix + total,
        managedAreaName: code.slice(0, 2) + '...' + code.slice(-5)
      });
    }
    if (props.managedAreas.length === 0 && props.filters.length > 0) {
      return t('treeList.treeSelectionInfo', {
        total: prefix + total
      });
    }
  }, [t, props.managedAreas, JSON.stringify(props.filters), total, treeList]);

  const jumperNavigation = useRef(new JumperNavigation(pinnedTreesCount));
  const notFilteredCount = treeList?.getNotFilteredCount();
  useEffect(() => {
    jumperNavigation.current = new JumperNavigation(pinnedTreesCount);
  }, [pinnedTreesCount]);

  useEffect(() => {
    if (
      !treeListTableRef.current || !tableBodyContainerRef.current || !fullSizedContainerRef.current || total === 0
    ) return;

    const scrollContainer = treeListTableRef.current!;

    fullSizedContainerRef.current.style.height = ((notFilteredCount || 1) * ROW_HEIGHT).toString() + 'px';
    const maxVisibleRows = Math.floor((scrollContainer.offsetHeight - HEADER_HEIGHT) / ROW_HEIGHT);
    setCurrentView({ from: 0, to: maxVisibleRows });
    jumperNavigation.current.setDisplayedRange(0, maxVisibleRows);

    scrollContainer.scrollTo({ top: 0 });

    const onScroll = () => {
      const from = Math.floor((scrollContainer.scrollTop) / ROW_HEIGHT);
      const delta = Math.floor((scrollContainer.offsetHeight - HEADER_HEIGHT) / ROW_HEIGHT);
      const to = from + delta;
      jumperNavigation.current.setDisplayedRange(from, to);
      setCurrentView({ from, to });
    };

    scrollContainer.addEventListener('scroll', onScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', onScroll);
    };
  }, [treeListTableRef, total, props.open, notFilteredCount]);

  useEffect(() => {
    (async () => {
      const from = Math.max(currentView.from - 10, 0);
      const to = currentView.to + 10;
      const delta = from - pinnedTreesCount;

      await Promise.all(tableAnimations.map(it => it.promise));
      if (!treeList || !tableBodyContainerRef.current || !treeListTableRef.current) return;
      const trees = await treeList.getRange(from, to);
      setVisibleTableFragment({ trees: trees.map((it, index) => ({ tree: it, pinned: delta < 0 ? index < Math.abs(delta) : false })), offset: from });
      const offset = tableAnimations.length;
      if (treeListTableRef.current?.scrollTop > pinnedTreesCount * ROW_HEIGHT) {
        treeListTableRef.current.scrollTop = (treeListTableRef.current?.scrollTop) + offset * 40;
      }
      setTableAnimations([]);
    })();
  }, [treeList, currentView]);

  useEffect(() => {
    (async () => {
      if (!treeList) return;
      if (props.selectedTreeId && props.open) {
        const index = await treeList.findIndexById(props.selectedTreeId);
        const numberOfVisibleRows = (treeListTableRef.current!.offsetHeight - HEADER_HEIGHT) / ROW_HEIGHT;
        if (tableAnimations.length === 0) {
          treeListTableRef.current!.scrollTop = Math.max(index - (numberOfVisibleRows - 0.5) / 2, 0) * ROW_HEIGHT;
        }
      }
    })();
  }, [treeList, props.selectedTreeId, props.open]);

  const useEffectDependencyForColumnSettingInitialization = props.properties.join('');
  useEffect(() => {
    setColumnSettings(
      columnSettings =>
        new Map<Property, ColumnSettings>([
          ['externalId', { sort: null, aggregateFunction: null }],
          ...props.properties.map((property): [Property, ColumnSettings] => [
            property,
            columnSettings.get(property) ?? {
              sort: null,
              aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null
            }
          ])
        ])
    );
  }, [useEffectDependencyForColumnSettingInitialization]);

  useEffect(() => {
    if (!treeListTableRef.current) return;
    setHidePlaceholderColumn(treeListTableRef.current.scrollWidth > window.innerWidth - 50);
  }, [useEffectDependencyForColumnSettingInitialization]);

  const onToggle = useCallback(() => {
    if (urlContext.getSidebarOpen() && !urlContext.getTreeTableOpen()) {
      urlContext.setSidebarOpen(false);
    }

    const wasOpened = urlContext.getTreeTableOpen();
    urlContext.setTreeTableOpen(!wasOpened);
    props.panelLayoutRef.toggleTable();
    track(events.TABLE_TOGGLE_CHANGE, { opened: !wasOpened });
  }, []);

  const onTableDragStart = downEvent => {
    const nativeDownEvent = (downEvent.nativeEvent as MouseEvent);
    dragStartPos.current = nativeDownEvent.screenY;
    const mouseMoveListener = e => onMouseMove(e);
    const mouseUpListener = e => {
      const ev = e as MouseEvent;
      if (Math.abs(ev.screenY - (dragStartPos.current || ev.screenY)) < MIN_DRAG_DISTANCE) {
        onToggle();
      }

      props.setTableResizeTrigger(Math.random());
      window.removeEventListener('mousemove', mouseMoveListener);
      window.removeEventListener('mouseup', mouseUpListener);
    };

    window.addEventListener('mousemove', mouseMoveListener);
    window.addEventListener('mouseup', mouseUpListener);
  };

  const onMouseMove = useCallback((e: Event) => {
    const onTableDrag = height => {
      props.panelLayoutRef.setTableDragDistance(height);
      props.setTableDragDistance(height);
    };

    const evt = e as MouseEvent;
    const distance = window.innerHeight - evt.clientY;
    if (distance >= window.innerHeight * 0.7 && distance > dragDistance) {
      onTableDrag(window.innerHeight - 100);
      setDragDistance(distance);
      return;
    }

    if (distance < window.innerHeight - 100) {
      onTableDrag(distance);
    }

    setDragDistance(distance);
  }, [setDragDistance, dragDistance, props.panelLayoutRef]);

  const navigateToDetails = (treeId: string) => {
    DetailsBackUrl.store(treeId, location);
    let url = `/organizations/${organizationId}/trees/${treeId}/`;
    url += account.organization.isDemo ? 'analytics/general' : 'details';
    navigate(url);
  };

  const deselectPinned = async (treeId, animationPromise) => {
    setTableAnimations(prevState => ([...prevState, { treeId, promise: animationPromise }]));
    await unpinTree(treeId);
    track(events.TABLE_UNPIN_TREE, { treeId });
  };

  const unpinTree = async treeId => {
    const deleted = await treeService.deletePin(organizationId, treeId);
    if (!deleted) return;
    triggerTreeFetching();
  };

  const selectPinned = async (treeId, animationPromise) => {
    setTableAnimations(prevState => ([...prevState, { treeId, promise: animationPromise }]));
    await pinTree(treeId);
    track(events.TABLE_PIN_TREE, { treeId });
  };

  const pinTree = async treeId => {
    const created = await treeService.pinTree(organizationId, treeId);
    if (!created) return;
    triggerTreeFetching();
  };

  function triggerTreeFetching() {
    setTreesLastLoadedAt(Date.now());
  }

  const scrollToTableTop = () => treeListTableRef.current?.scrollTo({ top: 0, behavior: 'auto' });
  const scrollToNotPinnedTop = () => treeListTableRef.current?.scrollTo({
    top: pinnedTreesCount * ROW_HEIGHT,
    behavior: 'auto'
  });

  const handleJump = () => {
    if (jumperNavigation.current.shouldScrollUpToTableTop()) scrollToTableTop();
    if (jumperNavigation.current.shouldScrollDownToNotPinnedTop()) scrollToNotPinnedTop();
  };

  if (propertyConfigs.isLoading) return <></>;
  return (
    <div className={styles.container}>
      <div className={styles.resizeLine} onMouseDown={onTableDragStart} />
      <button
        type="button"
        className={styles.toggle}
        onMouseDown={onTableDragStart}
        data-testid={'table-toggle-button'}
      >
        {props.open ? <NavArrowDown /> : <NavArrowUp />}
        <span>
          {advancedFilterCountWithIcon}
        </span>
        {dashboardDescription}
      </button>

      {props.open && (
        <div
          className={styles.treeListTableContainer}
          onMouseEnter={() => {
            if (properties.length * 200 + 100 > window.innerWidth) {
              const arrows = document.getElementsByClassName(styles.floatingArrow);
              for (let i = 0; i < arrows.length; i++) {
                const arrow = arrows.item(i);
                (arrow as HTMLDivElement).style.visibility = 'visible';
              }
            }
          }}
          onMouseLeave={() => {
            const arrows = document.getElementsByClassName(styles.floatingArrow);
            for (let i = 0; i < arrows.length; i++) {
              const arrow = arrows.item(i);
              (arrow as HTMLDivElement).style.visibility = 'hidden';
            }
          }}
        >
          <TableDashboard
            properties={properties}
            displayableTreeProperties={props.properties}
            treeList={treeList!}
            windSpeed={props.windSpeed}
            propertyConfigs={propertyConfigs.data!}
          />
          <div
            className={`${styles.treeListTable} ${hidePlaceholderColumn ? styles.fullWidth : ''}`}
            ref={treeListTableRef}
          >
            <TableHeader
              columnSettings={columnSettings}
              properties={properties}
              onSortDirectionChange={setSort}
              hidePlaceholderColumn={hidePlaceholderColumn}
              pinnedTreesCount={pinnedTreesCount}
              scrollToTableTop={scrollToTableTop}
              setColumnSettings={setColumnSettings}
              managedAreas={treeList?.getManagedAreas() || []}
              species={treeList?.getSpecies() || []}
              propertyConfigs={propertyConfigs.data!}
              header={header}
              windSpeed={props.windSpeed}
              statuses={Object.values(TreeStatus)}
              vitalityVigorOptions={Object.values(VitalityVigor)}
            />
            <div ref={fullSizedContainerRef}>
              <div ref={tableBodyContainerRef} style={{ position: 'relative', width: '100%', top: (visibleTableFragment.offset * ROW_HEIGHT) + 'px' }}>
                <div className={styles.body}>
                  {visibleTableFragment.trees.map((it, index) => {
                    const treeId = it.tree.id;
                    return <TableRow
                      tree={it.tree}
                      hidePlaceholderColumn={hidePlaceholderColumn}
                      selectedTreeId={props.selectedTreeId}
                      index={index}
                      key={`tree-${index}${it.pinned ? it.tree.externalId + '-pinned' : ''}`}
                      pinned={it.pinned}
                      properties={properties}
                      onSelect={() => props.onSelect(treeId === props.selectedTreeId ? '' : treeId)}
                      windSpeed={props.windSpeed}
                      onPin={promise => it.pinned ? deselectPinned(treeId, promise) : selectPinned(treeId, promise)}
                      navigateToDetails={() => navigateToDetails(treeId)}
                      selectedTreeProperty={selectedTreeProperty}
                      propertyConfigs={propertyConfigs.data!}
                    />;
                  })}
                </div>
              </div>
            </div>
          </div>
          {firstPageIsLoading && <Spinner/>}
          {jumperNavigation.current.isVisible() && <button className={styles.jumperButton} onClick={() => handleJump()}>
            {jumperNavigation.current.shouldScrollUpToTableTop() ? <ArrowUp/> : <ArrowDown/>}</button>}
        </div>
      )}
    </div>
  );
}

function getAggregationOptions(t: TFunction, configuration: ColumnConfiguration) {
  return [
    { label: t('treeList.min'), value: 'min' as const },
    { label: t('treeList.max'), value: 'max' as const },
    { label: t('treeList.sum'), value: 'sum' as const },
    { label: t('treeList.avg'), value: 'avg' as const },
    { label: t('treeList.med'), value: 'med' as const }
  ].filter(it => configuration[it.value]);
}

export type Property = DisplayableTreeProperty | 'externalId' | 'species' | 'managedAreaId';

export interface ColumnConfiguration {
  min: boolean,
  max: boolean,
  avg: boolean,
  med: boolean,
  sort: boolean,
  sum: boolean
}

export const defaultColumnConfiguration: ColumnConfiguration = {
  min: false,
  max: false,
  avg: false,
  med: false,
  sort: false,
  sum: false
};

const numericColumnConfiguration: ColumnConfiguration = { min: true, max: true, avg: true, med: true, sort: true, sum: false };
const enumColumnConfiguration: ColumnConfiguration = { min: false, max: false, avg: false, med: false, sort: true, sum: false };

export const columnConfiguration = new Map<Property, ColumnConfiguration>([
  ['externalId', defaultColumnConfiguration],
  ['species', enumColumnConfiguration],
  ['managedAreaId', enumColumnConfiguration],
  [DisplayableTreeProperty.Status, enumColumnConfiguration],
  [DisplayableTreeProperty.VitalityVigor, enumColumnConfiguration],
  [DisplayableTreeProperty.Height, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyWidth, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkDiameter, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAt80Kmh, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactors, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafArea, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafBiomass, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaIndex, numericColumnConfiguration],
  [DisplayableTreeProperty.CarbonStorage, numericColumnConfiguration],
  [DisplayableTreeProperty.GrossCarbonSequestration, numericColumnConfiguration],
  [DisplayableTreeProperty.NO2, numericColumnConfiguration],
  [DisplayableTreeProperty.SO2, numericColumnConfiguration],
  [DisplayableTreeProperty.PM25, numericColumnConfiguration],
  [DisplayableTreeProperty.CO, numericColumnConfiguration],
  [DisplayableTreeProperty.O3, numericColumnConfiguration],
  [DisplayableTreeProperty.NDVI, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeHealth, numericColumnConfiguration],
  [DisplayableTreeProperty.PotentialEvapotranspiration, numericColumnConfiguration],
  [DisplayableTreeProperty.Transpiration, numericColumnConfiguration],
  [DisplayableTreeProperty.OxygenProduction, numericColumnConfiguration],
  [DisplayableTreeProperty.ThermalComfort, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueRado, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueCavat, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueKoch, numericColumnConfiguration],
  [DisplayableTreeProperty.LeaningAngle, numericColumnConfiguration],
  [DisplayableTreeProperty.WaterIntercepted, numericColumnConfiguration],
  [DisplayableTreeProperty.Evaporation, numericColumnConfiguration],
  [DisplayableTreeProperty.AvoidedRunoff, numericColumnConfiguration],
  [DisplayableTreeProperty.Dieback, numericColumnConfiguration],
  [DisplayableTreeProperty.Slenderness, numericColumnConfiguration],
  [DisplayableTreeProperty.LiveCrownRatio, numericColumnConfiguration],
  [DisplayableTreeProperty.CrownLightExposure, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaPerCrownVolume, numericColumnConfiguration],
  [DisplayableTreeProperty.ViStatus, enumColumnConfiguration],
  [DisplayableTreeProperty.HasMitigation, enumColumnConfiguration],
  [DisplayableTreeProperty.HasAssessmentRequest, enumColumnConfiguration],
  [DisplayableTreeProperty.HasViObservation, enumColumnConfiguration],
  [DisplayableTreeProperty.Mitigations, enumColumnConfiguration],
  [DisplayableTreeProperty.AssessmentRequests, enumColumnConfiguration],
  [DisplayableTreeProperty.ViObservations, enumColumnConfiguration]
]);

function getColumnConfiguration(property: Property) {
  return columnConfiguration.get(property) ?? defaultColumnConfiguration;
}

export interface ColumnSettings {
  sort: null | 'asc' | 'desc',
  aggregateFunction: null | 'min' | 'max' | 'avg' | 'med' | 'sum'
}

interface TableViewerProps {
  properties: DisplayableTreeProperty[],
  open: boolean,
  managedAreas: ManagedArea[],
  filters: TreeFilter[],
  onSelect: (treeId: string) => unknown,
  selectedTreeId: string | null,
  windSpeed: number,
  panelLayoutRef: PanelLayout,
  setTableResizeTrigger: Dispatch<SetStateAction<number>>,
  setTableDragDistance: (height: number) => void
}
