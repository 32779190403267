import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { useTranslation } from 'react-i18next';
import styles from './ColumnSelector.module.scss';
import { useContext, useEffect, useState } from 'react';
import AbsoluteModal from '../../../components/Modal/absolute-modal/AbsoluteModal';
import { ArrowLeft, Check, Plus, Xmark } from 'iconoir-react';
import { ReactComponent as DragAndDropIcon } from './images/drag-and-drop.svg';
import ModalDialog from '../../../components/Modal/absolute-modal/ModalDialog';
import { AvailableProperties } from '../../../properties/AvailableProperties';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useTracking } from '../../../analytics/useTracking';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { useLegacyAvailableColumnSelectorProperties } from '../../../properties/usePropertyConfigurations';

export default function ColumnSelector(props: ColumnSelectorProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const [firstPage, setFirstPage] = useState(true);
  const properties = useLegacyAvailableColumnSelectorProperties();
  const isModalVisible = props.isModalOpen;
  const [selectedProperties, setSelectedProperties] = useState(props.properties);
  const [isLoaded, setLoaded] = useState(false);

  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const { events, track } = useTracking();

  useEffect(() => {
    if (selectedProperties.length > 0 && !firstPage) setFirstPage(false);
    if (selectedProperties.length === 0) return setFirstPage(false);
    if (firstPage && isLoaded) return setFirstPage(true);
  }, [selectedProperties, isLoaded, firstPage]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const propertySelectHandler = property => {
    if (selectedProperties.includes(property)) {
      track(events.TABLE_COLUMN_SELECTOR_REMOVE_COLUMN, { column: property });
      return setSelectedProperties(selectedProperties.filter(prop => prop !== property));
    }
    track(events.TABLE_COLUMN_SELECTOR_ADD_NEW_COLUMN, { column: property });
    return setSelectedProperties([...selectedProperties, property]);
  };

  const removePropertyHandler = property => {
    if (property === selectedTreeProperty) urlContext.setSelectedTreeProperty(null);
    track(events.TABLE_COLUMN_SELECTOR_REMOVE_COLUMN, { column: property });
    setSelectedProperties(selectedProperties.filter(prop => prop !== property));
  };

  const onModalExit = () => {
    props.onChange(selectedProperties);
    props.onModalClose();
  };

  //DRAG AND DROP
  const getFilterStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? 'rgba(255, 255, 255, 0.1)' : '',
    ...draggableStyle
  });

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const filters = Array.from(selectedProperties);
    const [newOrder] = filters.splice(source.index, 1);
    filters.splice(destination.index, 0, newOrder);

    setSelectedProperties(filters);
  };

  const getPropertyTitle = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, organization);
    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    const columnTitle = t(`tree.${property}`) + translatedUnit;

    return (
      <li
        className={`${selectedProperties.includes(property) ? styles.activeProperty : ''} ${styles.propertyListItem}`}
        key={`property-selector-modal-${property}`}
        onClick={() => propertySelectHandler(property)}
      >
        {selectedProperties.includes(property) ? <Check /> : <span className={styles.placeholder}></span>}
        <span>{t(`${columnTitle}`)}</span>
      </li>);
  };

  const togglePropertyGroup = (properties: DisplayableTreeProperty[]) => {
    const anyPropertySelected = properties.some(property => selectedProperties.includes(property));
    if (anyPropertySelected) {
      setSelectedProperties(prevProperties => prevProperties.filter(prop => !properties.includes(prop)));
    } else {
      setSelectedProperties(prevProperties => [...prevProperties, ...properties]);
    }
  };

  return (
    <AbsoluteModal
      isVisible={isModalVisible}
      onHide={onModalExit}
      hideOverlay={true}
    >
      <ModalDialog
        style={{ left: `${props.left}px`, top: `${props.top + 20}px` }}
        classNames={styles.columnSelectorModal}
      >
        {firstPage && isLoaded ?
          //Render the first page
          <section className={styles.firstPageContainer}>
            <p className={styles.title}>{t('selectColumns.title')}</p>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'filterSelector'}>
                {provided => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedProperties.map((property, index) => (
                      <Draggable
                        draggableId={property}
                        index={index}
                        key={property}>
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getFilterStyle(snapshot.isDragging, provided.draggableProps.style)}
                            key={property}
                            id={property}
                            className={styles.activeFilter}
                          >
                            <span
                              className={styles.activeFilterText}><DragAndDropIcon /><span>{t(`tree.${property}`) + (Tree.getUnit(property, organization) ? ` [${t(`units.${Tree.getUnit(property, organization)}`)}]` : '')}</span></span>
                            <Xmark className={styles.cancelButton} onClick={() => removePropertyHandler(property)} />
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <button
              className={styles.addColumnButton}
              onClick={() => setFirstPage(false)}
            >
              <Plus />{t('selectColumns.addNewColumn')}</button>
          </section>
          :
          //Render the second page
          <section className={styles.secondPageContainer}>
            {props.properties.length && selectedProperties.length ? <button
              className={styles.addColumnButton}
              onClick={() => setFirstPage(true)}
            >
              <ArrowLeft />{t('selectColumns.addNewColumn')}</button> : ''}
            <div className={styles.propertyListContainer}>
              {
                new AvailableProperties(properties).map((name, properties) => {
                  return <>
                    <div
                      className={styles.propertyListTitle}
                      onClick={() => togglePropertyGroup(properties)}
                    >
                      {t(name)}
                    </div>
                    <ul className={styles.propertyList}>
                      {properties.map(property => (getPropertyTitle(property)))}
                    </ul>
                  </>;
                })
              }
              <div
                className={styles.propertyListTitle}
                onClick={() => togglePropertyGroup(Tree.INSPECTIONS)}
              >
                {t('treePropertySelector.inspections')}
              </div>
              <ul className={styles.propertyList}>
                {Tree.INSPECTIONS.map(property => (getPropertyTitle(property)))}
              </ul>
            </div>
          </section>
        }
      </ModalDialog>
    </AbsoluteModal>
  );
}

type ColumnSelectorProps = {
  isModalOpen: boolean,
  onModalClose: () => unknown,
  properties: DisplayableTreeProperty[],
  onChange: (properties: DisplayableTreeProperty[]) => unknown,
  left: number,
  top: number
};
