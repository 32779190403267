import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import styles from '../../../../../routes/Explore/tree-marker/html-tree-marker/TreeMarkerVariations.module.scss';
import { MercatorCoordinate } from '../../../../../routes/Explore/MercatorCoordinate';
import TreeMarkerVariations from '../../../../../routes/Explore/tree-marker/html-tree-marker/TreeMarkerVariations';

export class HtmlTreeMarkerLight {
  static create(
    treeId: string,
    coordinates: [number, number, number]
  ) {
    const treeMercatorCoordinates = new MercatorCoordinate(coordinates);

    return new HtmlTreeMarkerLight(
      treeId,
      treeMercatorCoordinates.x,
      treeMercatorCoordinates.y
    );
  }

  public isVisible = true;
  public isSelected = false;
  public readonly markerIcon: CSS2DObject = this.createMarkerHtml();
  private readonly treeMarkerVariations: TreeMarkerVariations = new TreeMarkerVariations(this.markerIcon.element as HTMLDivElement);

  constructor(
    readonly treeId: string,
    private readonly mercatorXPosition: number,
    private readonly mercatorYPosition: number
  ) {}

  setPosition(cameraCenterX: number, cameraCenterY: number, scale: number) {
    this.markerIcon.position
      .setX((this.mercatorXPosition - cameraCenterX) / scale)
      .setY((cameraCenterY - this.mercatorYPosition) / scale)
      .setZ(0);
  }

  addTo(scene: THREE.Scene) {
    scene.add(this.markerIcon);
    return this;
  }

  belongsTo(treeId: string) {
    return this.treeId === treeId;
  }

  removeListeners() {
    this.markerIcon.element.onclick = null;
    this.markerIcon.element.onmouseenter = null;
    this.markerIcon.element.onmouseleave = null;
    this.markerIcon.element.onwheel = null;
    this.markerIcon.element.onmousedown = null;
  }

  select() {
    if (this.isVisible) {
      this.isSelected = true;
      this.treeMarkerVariations.setContentSelected();
    }
    return this;
  }

  disable() {
    if (this.isVisible) {
      this.isSelected = false;
      this.treeMarkerVariations.disable();
    }
    return this;
  }

  private createMarkerHtml() {
    const div = document.createElement( 'div' );
    div.classList.add(styles.mapMarkerContainer);
    return new CSS2DObject(div);
  }
}
