import { TooltipProps as RcTooltipProps } from 'rc-tooltip/lib/Tooltip';
import RcTooltip from 'rc-tooltip';
import React, { ForwardRefExoticComponent, useRef, useState } from 'react';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Flippers } from '../../../switches/Flippers';

export default function Tooltip(props: RcTooltipProps) {
  const { organization } = useCurrentAccount();

  return (
    <RcTooltip
      {...props}
      mouseLeaveDelay={0}
      placement={props.placement || 'top'}
      overlayInnerStyle={
        organization.isEnabled(Flippers.carbonRedesign) ?
          { backgroundColor: 'var(--cds-text-primary)', color: 'var(--cds-background)' } : {}
      }
      showArrow={!organization.isEnabled(Flippers.carbonRedesign)}
    />
  );
}

export function TooltipForEllipsis(props: RcTooltipProps) {
  const { organization } = useCurrentAccount();
  const wrapperRef = useRef<ForwardRefExoticComponent<HTMLDivElement> | null>(null);
  const [visible, setVisible] = useState(false);

  const onTooltipVisibleChange = (show: boolean) => {
    const element = wrapperRef.current!['triggerRef']!.current;
    if (element) {
      const truncated = element.scrollWidth > element.clientWidth;
      if (show && truncated) {
        setVisible(show);
      } else {
        setVisible(false);
      }
    }
  };

  return (
    <RcTooltip
      ref={wrapperRef}
      {...props}
      visible={visible}
      onVisibleChange={onTooltipVisibleChange}
      mouseLeaveDelay={0}
      placement={props.placement || 'top'}
      overlayInnerStyle={
        organization.isEnabled(Flippers.carbonRedesign) ?
          { backgroundColor: 'var(--cds-text-primary)', color: 'var(--cds-background)' } : {}
      }
      showArrow={!organization.isEnabled(Flippers.carbonRedesign)}
    >
      {props.children}
    </RcTooltip>
  );
}
