import { FilterGroup, Operator } from './FilterConfig';
import { FilterCondition } from './FilterCondition';
import { Tree } from '../tree/Tree';

export default class FilterConditionGroup {
  static fromConfig(config: FilterGroup): FilterConditionGroup {
    return new FilterConditionGroup(
      config.filters.map(filterConditionConfig => FilterCondition.fromConfig(filterConditionConfig)),
      config.filterGroupOperator
    );
  }

  constructor(
    private readonly filters: FilterCondition[],
    private readonly filterGroupOperator: Operator
  ) {}

  apply(tree: Partial<Tree>): boolean {
    if (this.filterGroupOperator === Operator.AND) {
      return this.filters.every(filter => filter.apply(tree));
    }
    return this.filters.some(filter => filter.apply(tree));
  }

  getFields(): string[] {
    return Array.from(new Set(['id', ...this.filters.map(f => f.property)]));
  }
}

