import { ReactElement, useContext, useEffect, useState } from 'react';
import TwinViewTile from './twin-view/TwinViewTile';
import ImagesViewTile from './images-view/ImagesViewTile';
import StreetViewTile from './street-view/StreetViewTile';
import DetailedTree from '../../../tree/DetailedTree';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { TreeDisplays } from './TreeDisplays';

export default function TreeDisplayTile(props: TreeDisplayTileProps) {
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const { primaryTile, secondaryTile } = props;
  const showAddButton = primaryTile === TreeDisplays.NULL || secondaryTile === TreeDisplays.NULL;

  const [treeImageBlobs, setTreeImageBlobs] = useState<string[]>(new Array(props.tree.images.length).fill(''));

  useEffect(() => {
    const imageUrls = props.tree.images.map(image => image.getRotatedUrl());
    imageUrls.forEach(async (url, index) => {
      const response = await fetch(url, {
        mode: 'cors',
        credentials: 'include'
      });
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setTreeImageBlobs(prev => prev.map((it, idx) => idx === index ? blobUrl : it));
    });
  }, [props.tree.images]);

  const openSecondaryTile = () => {
    urlContext.setDetailsSecondary(TreeDisplays.IMAGES);
    urlContext.setHistoryTrackingOpen(false);
  };

  const primaryTiles = new Map<TreeDisplays, ReactElement>([
    [TreeDisplays.STREET_VIEW, (
      <StreetViewTile
        tree={props.tree}
        isCaptureModeActive={props.isCaptureModeActive}
        setCaptureModeActive={props.setCaptureModeActive}
        view={primaryTile}
        setView={urlContext.setDetailsPrimary.bind(urlContext)}
        openSecondaryTile={openSecondaryTile}
        showAddButton={props.expandedViewer && showAddButton}
        showCloseButton={false}
      />
    )],
    [TreeDisplays.IMAGES, (
      <ImagesViewTile
        treeImageBlobs={treeImageBlobs}
        view={primaryTile}
        setView={urlContext.setDetailsPrimary.bind(urlContext)}
        openSecondaryTile={openSecondaryTile}
        showAddButton={props.expandedViewer && showAddButton}
        showCloseButton={false}
      />
    )],
    [TreeDisplays.TWIN_VIEW, (
      <TwinViewTile
        tree={props.tree}
        view={primaryTile}
        setView={urlContext.setDetailsPrimary.bind(urlContext)}
        openSecondaryTile={openSecondaryTile}
        showAddButton={props.expandedViewer && showAddButton}
        showCloseButton={false}
      />
    )]
  ]);
  const secondaryTiles = new Map<TreeDisplays, ReactElement>([
    [TreeDisplays.STREET_VIEW, (
      <StreetViewTile
        tree={props.tree}
        isCaptureModeActive={props.isCaptureModeActive}
        setCaptureModeActive={props.setCaptureModeActive}
        view={secondaryTile}
        setView={urlContext.setDetailsSecondary.bind(urlContext)}
        openSecondaryTile={() => {
        }}
        showAddButton={props.expandedViewer && showAddButton}
        showCloseButton={true}
      />
    )],
    [TreeDisplays.IMAGES, (
      <ImagesViewTile
        treeImageBlobs={treeImageBlobs}
        view={secondaryTile}
        setView={urlContext.setDetailsSecondary.bind(urlContext)}
        openSecondaryTile={() => {
        }}
        showAddButton={props.expandedViewer && showAddButton}
        showCloseButton={true}
      />
    )],
    [TreeDisplays.TWIN_VIEW, (
      <TwinViewTile
        tree={props.tree}
        view={secondaryTile}
        setView={urlContext.setDetailsSecondary.bind(urlContext)}
        openSecondaryTile={() => {
        }}
        showAddButton={props.expandedViewer && showAddButton}
        showCloseButton={true}
      />
    )]
  ]);

  return <>
    {primaryTiles.get(primaryTile)}
    {props.expandedViewer && secondaryTiles.get(secondaryTile)}
  </>;
}

interface TreeDisplayTileProps {
  tree: DetailedTree,
  isCaptureModeActive: boolean,
  setCaptureModeActive: (isActive: boolean) => void,
  primaryTile: TreeDisplays,
  secondaryTile: TreeDisplays,
  expandedViewer: boolean
}
