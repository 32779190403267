import { PolarArea } from 'react-chartjs-2';
import { CheckmarkFilled, CloseFilled, ErrorFilled } from '@carbon/icons-react';
import useCarbonThemes, { CarbonTheme } from '../../../components/UI/Carbon/useCarbonThemes';
import { useRef } from 'react';
import { Chart } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { ChartData } from './ChartCalculator';

export default function PolarChart(props: PolarChartProps) {
  const { theme } = useCarbonThemes();
  const { t } = useTranslation();
  const chartRef = useRef(null);

  const isLightTheme = [CarbonTheme.g10, CarbonTheme.white].includes(theme);

  const plugin = {
    id: 'labelBackground',
    beforeDraw: (chart, args, options) => {
      const padding = 8;
      const { ctx } = chart;
      const labels = chart.scales.r._pointLabels;

      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';

      labels.forEach((label, index) => {
        const background = isLightTheme ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)';
        const border = isLightTheme ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)';
        const selectedBackground = isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.4)';
        const selectedBorder = isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.4)';

        const labelPosition = chart.scales.r.getPointLabelPosition(index);
        ctx.fillStyle = index === options.selectedIndex ? selectedBackground : background;
        ctx.strokeStyle = index === options.selectedIndex ? selectedBorder : border;
        ctx.lineWidth = 1; // Border width

        ctx.beginPath();
        ctx.roundRect(
          labelPosition.left - padding * 1.5,
          labelPosition.top - padding,
          labelPosition.right - labelPosition.left + 2 * padding * 1.5,
          30,
          1000
        );
        ctx.fill();
        ctx.stroke();
      });

      ctx.restore();
    },
    defaults: {
      selectedIndex: 0
    }
  };

  const options = {
    animation: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      labelBackground: {
        selectedIndex: props.selectedSectionIdx,
        hoveredIndex: -1
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      r: {
        grid: {
          lineWidth: 1
        },
        ticks: {
          display: false
        },
        pointLabels: {
          display: true,
          centerPointLabels: true,
          padding: 20,
          color: isLightTheme ? '#161616' : '#F4F4F4',
          font: {
            fontFamily: 'Inter',
            size: 12
          }
        }
      }
    },
    onClick: event => {
      const padding = 8;
      for (let i = 0; i < event.chart.data.labels.length; i++) {
        const label = event.chart.scales.r.getPointLabelPosition(i);
        if (label.left - padding < event.x && event.x < label.right + 2 * padding && label.top - padding < event.y && event.y < label.bottom + 2 * padding) {
          event.chart.config.options.plugins.labelBackground.selectedIndex = i;
          event.chart.update();
          props.setSelectedSectionIdx(i);
        }
      }
    },
    layout: {
      padding: 25
    }
  };

  const colorConfig = {
    [CarbonTheme.g100]: {
      noDataBackground: '#3f3f3f',
      negativeBackground: '#925554',
      poitiveBackground: '#42BE63',
      borderColor: '#262626'
    },
    [CarbonTheme.g90]: {
      noDataBackground: '#4d4d4d',
      negativeBackground: '#9C5F5E',
      poitiveBackground: '#24A145',
      borderColor: '#393939'
    },
    [CarbonTheme.g10]: {
      noDataBackground: '#e8e8e8',
      negativeBackground: '#F4BDBC',
      poitiveBackground: '#24A145',
      borderColor: '#FFFFFF'
    },
    [CarbonTheme.white]: {
      noDataBackground: '#dfdfdf',
      negativeBackground: '#ECB5B4',
      poitiveBackground: '#24A145',
      borderColor: '#F4F4F4'
    }
  };

  const innerCircleRadius = 1;

  return (
    <>
      <div className="pt-4 pl-4 text-[var(--cds-text-secondary)] text-xl">
        {t('treeDetails.treeDiagnostics')}
      </div>
      <div className="w-[90%] min-h-72 mx-auto my-6">
        <PolarArea
          ref={chartRef}
          data={{
            labels: [
              [`${props.data.sections[0].label}  •  ${props.data.sections[0].value} / ${5 - props.data.sections[0].noDataCount}`, '', props.data.sections[0].noDataCount > 0 ? `     ${t('treeDetails.noData')}: ${props.data.sections[0].noDataCount}` : ''],
              [`${props.data.sections[1].label}  •  ${props.data.sections[1].value} / ${5 - props.data.sections[1].noDataCount}`, '', props.data.sections[1].noDataCount > 0 ? `   ${t('treeDetails.noData')}: ${props.data.sections[1].noDataCount}` : ''],
              [`${props.data.sections[2].label}  •  ${props.data.sections[2].value} / ${5 - props.data.sections[2].noDataCount}`, '', props.data.sections[2].noDataCount > 0 ? `${t('treeDetails.noData')}: ${props.data.sections[2].noDataCount}       ` : ''],
              [`${props.data.sections[3].label}  •  ${props.data.sections[3].value} / ${5 - props.data.sections[3].noDataCount}`, '', props.data.sections[3].noDataCount > 0 ? `${t('treeDetails.noData')}: ${props.data.sections[3].noDataCount}    ` : '']
            ],
            datasets: [
              ...[0, 1, 2, 3, 4].map((layer: number) => ({
                data: [5 - layer, 5 - layer, 5 - layer, 5 - layer].map(it => it + innerCircleRadius),
                backgroundColor: colorConfig[theme].noDataBackground,
                hoverBackgroundColor: colorConfig[theme].noDataBackground,
                borderColor: colorConfig[theme].borderColor,
                hoverBorderColor: colorConfig[theme].borderColor,
                borderWidth: 4,
                order: 15 - layer
              })),
              ...[0, 1, 2, 3, 4].map((layer: number) => ({
                data: props.data.sections.map(it => Math.max(5 - it.noDataCount - layer, 0)).map(it => it + innerCircleRadius),
                backgroundColor: colorConfig[theme].negativeBackground,
                hoverBackgroundColor: colorConfig[theme].negativeBackground,
                borderColor: colorConfig[theme].borderColor,
                hoverBorderColor: colorConfig[theme].borderColor,
                borderWidth: 4,
                order: 10 - layer
              })),
              ...[0, 1, 2, 3, 4].map((layer: number) => ({
                data: props.data.sections.map(it => Math.max(it.value - layer, 0)).map(it => it + innerCircleRadius),
                backgroundColor: colorConfig[theme].poitiveBackground,
                hoverBackgroundColor: colorConfig[theme].poitiveBackground,
                borderColor: colorConfig[theme].borderColor,
                hoverBorderColor: colorConfig[theme].borderColor,
                borderWidth: 4,
                order: 5 - layer
              })), {
                data: [innerCircleRadius, innerCircleRadius, innerCircleRadius, innerCircleRadius],
                backgroundColor: colorConfig[theme].borderColor,
                hoverBackgroundColor: colorConfig[theme].borderColor,
                borderColor: colorConfig[theme].borderColor,
                hoverBorderColor: colorConfig[theme].borderColor,
                borderWidth: 4,
                order: 0
              }]
          }}
          options={options}
          plugins={[plugin]}
          onMouseMove={event => {
            if (!chartRef.current) return;
            const chart = Chart.getChart(chartRef.current) as any;
            if (!chart) return;
            const rect = chart.canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            const padding = 8;

            let hoveredIndex = -1;
            for (let i = 0; i < chart.data.labels!.length; i++) {
              const label = chart.scales.r.getPointLabelPosition(i);
              if (label.left - padding < x && x < label.right + padding && label.top - padding < y && y < label.bottom + padding) {
                hoveredIndex = i;
                chart.canvas.style.cursor = 'pointer';
                break;
              }
              chart.canvas.style.cursor = 'default';
            }
            chart.config.options!.plugins!.labelBackground.hoveredIndex = hoveredIndex;
            chart.update();
          }}
          onMouseLeave={() => {
            if (!chartRef.current) return;
            const chart = Chart.getChart(chartRef.current) as any;
            if (!chart) return;
            chart.config.options!.plugins!.labelBackground.hoveredIndex = -1;
            chart.update();
          }}
        />
      </div>
      <div className="px-4 text-sm">
        <h5 className="font-semibold my-3">{props.data.sections[props.selectedSectionIdx].label}</h5>
        <ul>
          {
            props.data.sections[props.selectedSectionIdx].properties
              .map(it => (
                <li className="my-1.5 flex items-center gap-2" key={it.label}>
                  <div>{
                    it.value === null
                      ? <ErrorFilled fill={colorConfig[theme].noDataBackground} />
                      : (it.value ? <CheckmarkFilled fill={colorConfig[theme].poitiveBackground} /> : <CloseFilled fill={colorConfig[theme].negativeBackground}/>)}</div>
                  <div>{it.label}</div>
                </li>)
              )
          }
        </ul>
      </div>
    </>
  );
}

export interface PolarChartProps {
  data: ChartData,
  selectedSectionIdx: number,
  setSelectedSectionIdx: (idx: number) => void
}

export type PolarChartData = {
  labels: string[],
  datasets: {
    label: string,
    data: number[],
    backgroundColor: string[]
  }[]
};
