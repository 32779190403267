import { Tree } from '../../tree/Tree';
import { useCurrentAccount } from '../../account/useAccounts';
import { useContext } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useMatch } from 'react-router-dom';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import { VI_CREATION_MODAL_PROPERTY_OPTIONS } from './table-view/components/PropertySelector';
import LegacyLazyTreeList from './LegacyLazyTreeList';
import LazyTreeList from './LazyTreeList';
import { Flippers } from '../../switches/Flippers';
import { useQuery } from 'react-query';
import PartialTree from '../../tree/PartialTree';

export const useFetchTreeList = (
  treesLastLoadedAt: number,
  sort: string | null,
  selectedTreeFilters: TreeFilter[],
  tableProperties: string[],
  taskTemplateId?: string
) => {
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const organizationId = useMatch('/organizations/:organizationId/*')?.params?.organizationId || '';
  const account = useCurrentAccount();
  const managedAreaIdsInURL = urlContext.getManagedAreaIds();
  const reverseMASelection = urlContext.getReverseMASelection();
  const treeFilterIds = urlContext.getTreeFilterIds();
  const filterConfig = urlContext.getFilterConfiguration();
  const advancedFilterConfiguration = urlContext.getAdvancedFilterConfiguration();
  const windspeed = urlContext.getWindSpeed() || account.getDefaultWindSpeed();
  const selectedTreeFilterProperties = selectedTreeFilters.flatMap(it => it.getPropertyNames());

  const cacheKeys = [
    treesLastLoadedAt,
    sort,
    organizationId,
    JSON.stringify(selectedTreeFilters),
    JSON.stringify(selectedTreeFilterProperties),
    JSON.stringify(tableProperties),
    account,
    JSON.stringify(managedAreaIdsInURL),
    JSON.stringify(treeFilterIds),
    JSON.stringify(filterConfig),
    JSON.stringify(advancedFilterConfiguration),
    JSON.stringify(reverseMASelection),
    windspeed
  ];
  if (taskTemplateId) cacheKeys.push(taskTemplateId);

  const result = useQuery(cacheKeys, ({ signal }) => {
    const fieldsToFetch = [
      'externalId',
      'species',
      'localizedLocation',
      ...VI_CREATION_MODAL_PROPERTY_OPTIONS,
      ...selectedTreeFilterProperties,
      ...tableProperties
    ];

    const treeList = account.organization.isEnabled(Flippers.workspace) ? new LazyTreeList(
      treeService,
      account.organization,
      organizationId,
      managedAreaIdsInURL,
      reverseMASelection,
      fieldsToFetch as (keyof Tree)[],
      filterConfig,
      sort,
      windspeed,
      taskTemplateId
    )
      : new LegacyLazyTreeList(
        treeService,
        account.organization,
        organizationId,
        managedAreaIdsInURL,
        reverseMASelection,
        fieldsToFetch as (keyof Tree)[],
        treeFilterIds,
        sort,
        advancedFilterConfiguration,
        windspeed
      );

    if (account.organization.isEmpty()) return treeList;

    return treeList.loadFirst(signal).then(() => {
      treeList.load(signal).then().catch(e => {
        if (e.message !== 'canceled') throw e;
      });
      return treeList;
    }).catch(e => {
      if (e.message !== 'canceled') throw e;
    });
  });

  return { treeList: result.data, firstPageIsLoading: result.isLoading };
};
