import mapboxgl from 'mapbox-gl';
import { TreeMarkerLayer } from './TreeMarkerLayer';
import { Organization } from '../../../../organization/Organization';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import { DisplayableTreeProperty, Tree, TreeDisplayConfiguration } from '../../../../tree/Tree';
import { TreeNamecard } from '../../../Explore/tree-namecard/TreeNamecard';
import { MapboxPopupTreeNamecard } from '../../../Explore/tree-namecard/MapboxPopupTreeNamecard';
import { HoverTimerForTreeId } from '../../../Explore/panoramic-view/TreeMarkerHandler';
import TreeService from '../../../../tree/TreeService';
import { TFunction } from 'react-i18next';
import CanopyLayerColorCalculator from '../../../Explore/mapbox-color/CanopyLayerColorCalculator';
import { ColoringType } from '../../../../components/Navbar/PropertyLegend';
import FilterConfig, { getProperties } from '../../../../filter/FilterConfig';
import tooltipStyles from '../../../../components/UI/Carbon/tooltip/Tooltip.module.scss';

export class CanopyLayer {
  static readonly ID = 'canopy';
  static readonly LAYER_ORDERING_ID = 'canopy-borders';

  private treeNamecard: TreeNamecard<mapboxgl.Map> = new MapboxPopupTreeNamecard(
    this.treeService,
    this.t,
    (tree: Tree) => this.onOpen(tree.id),
    this.organization,
    this.displayConfiguration.windSpeed || 0
  );
  private hoverTimer = new HoverTimerForTreeId();
  private map: mapboxgl.Map | null = null;
  private popup = new mapboxgl.Popup({
    className: tooltipStyles.mapboxTooltip,
    closeButton: false,
    closeOnClick: false
  });

  constructor(
    private readonly apiUrl: string,
    private organization: Organization,
    private propertyRangeIndex: number,
    private selectedPropertyConfig: PropertyConfiguration | null,
    private showCanopy: boolean,
    private filterConfiguration: FilterConfig,
    private displayConfiguration: TreeDisplayConfiguration,
    private selectedTreeId: string | null,
    private readonly treeService: TreeService,
    private readonly t: TFunction,
    private onSelect: (treeId: string) => unknown,
    private onOpen: (treeId: string) => unknown,
    private readonly tracking,
    private coloringType: ColoringType | null
  ) {
  }

  addTo(map: mapboxgl.Map) {
    this.map = map;

    this.addSource();
    this.addLayers();

    map.on('click', CanopyLayer.ID, this.onClick);
    this.updateDisplayConfiguration(this.selectedPropertyConfig, this.filterConfiguration, this.coloringType, this.displayConfiguration, this.showCanopy);

    map.on('mouseenter', CanopyLayer.ID, this.onMouseEnter);
    map.on('mousemove', CanopyLayer.ID, this.handleCanopyPopup(map));
    map.on('mouseleave', CanopyLayer.ID, this.onMouseLeave);
  }

  onClick = event => {
    const [canopy] = event.target.queryRenderedFeatures(event.point, { layers: [CanopyLayer.ID] });
    if (!canopy) return;

    if (canopy.id === this.selectedTreeId) {
      this.selectedTreeId = null;
    } else {
      this.selectedTreeId = canopy.id;
    }

    this.updatePaintProperties();

    this.hoverTimer.resetTimer();
    this.onSelect(this.selectedTreeId ?? '');
  };

  onMouseEnter = event => {
    if (!this.map) return;
    const [tree] = event.target.queryRenderedFeatures(event.point, { layers: [CanopyLayer.ID] });
    if (tree.id === this.selectedTreeId) return;

    const managedAreas = event.target.querySourceFeatures('managed-areas', { sourceLayer: 'managedAreas' });
    if (managedAreas.length === 0) return;

    const managedAreaCodeMap = {};
    managedAreas.forEach(area => managedAreaCodeMap[area.properties?.id] = area.properties?.code);
  };

  setSelectedTreeId(selectedTreeId: string | null) {
    this.selectedTreeId = selectedTreeId;
    this.updatePaintProperties();
  }

  get isVisible() {
    return this.showCanopy;
  }

  setOnSelectCallback(onSelect: (treeId: string) => unknown) {
    this.onSelect = onSelect;
  }

  setOnOpenCallback(onOpen: (treeId: string) => unknown) {
    this.onOpen = onOpen;
  }

  updateDisplayConfiguration(
    selectedPropertyConfig: PropertyConfiguration | null,
    filterConfig: FilterConfig,
    coloringType: ColoringType | null,
    displayConfiguration: TreeDisplayConfiguration,
    showCanopy: boolean
  ) {
    //!this.organization.id check is redundant after we delete Flippers.workspace
    if (!this.map || !this.organization.id) return;
    this.selectedPropertyConfig = selectedPropertyConfig;
    this.filterConfiguration = filterConfig;
    this.coloringType = coloringType;
    this.displayConfiguration = displayConfiguration;
    this.showCanopy = showCanopy;
    if (this.isVisible) {
      this.showLayers();
    } else {
      return this.hideLayers();
    }

    const selectedManagedAreasFilter = this.displayConfiguration.managedAreaIds.length ? [
      'match', ['get', 'managedAreaId'],
      this.displayConfiguration.managedAreaIds,
      !this.displayConfiguration.isManagedAreaSelectionReversed,
      this.displayConfiguration.isManagedAreaSelectionReversed
    ] : null;

    this.map.setFilter('canopy', selectedManagedAreasFilter);
    this.map.setFilter('canopy-borders', selectedManagedAreasFilter);

    this.updatePaintProperties();
  }

  setSelectedTreePropertyRangeIndex(index: number) {
    this.propertyRangeIndex = index;
    this.updatePaintProperties();
  }

  setSelectedPropertyConfig(selectedPropertyConfig: PropertyConfiguration | null) {
    this.selectedPropertyConfig = selectedPropertyConfig;
  }

  setOrganization(organization: Organization) {
    this.organization = organization;
    this.treeNamecard.setOrganization(organization);
    this.removeLayers();
    this.removeSource();
    this.map?.triggerRepaint();
  }

  updatePaintProperties() {
    const colors = new CanopyLayerColorCalculator().calculate(
      this.propertyRangeIndex,
      null,
      this.selectedPropertyConfig,
      this.coloringType || ColoringType.LABEL,
      { includeOnly: [], min: [], max: [], propertyConfigs: [], cohort: [] },
      this.selectedTreeId,
      this.displayConfiguration.windSpeed
    );

    if (!this.map) return;
    this.map.setPaintProperty('canopy', 'fill-color', colors.fillColor);
    this.map.setPaintProperty('canopy-borders', 'line-color', colors.lineColor);

    if (!this.selectedTreeId) return;
    const sortingExpression = ['case', ['==', ['get', 'id'], this.selectedTreeId], 1, 0];
    this.map.setLayoutProperty('canopy', 'fill-sort-key', sortingExpression);
    this.map.setLayoutProperty('canopy-borders', 'line-sort-key', sortingExpression);
  }

  getOrganizationId() {
    return this.organization.id;
  }

  private addSource() {
    if (!this.map) return;
    let properties: string[] = [
      ...getProperties(this.filterConfiguration),
      ...(this.selectedPropertyConfig ? [this.selectedPropertyConfig.property] : []),
      ...this.displayConfiguration.filters.flatMap(filter => [
        ...filter.numericPredicates.map(it => it.property),
        ...filter.enumPredicates.map(it => it.property),
        ...filter.propertyConfigurationPredicates.map(it => it.property)
      ])
    ];
    if (properties.some(it => it.includes('safetyFactors'))) {
      properties = properties.filter(it => !it.includes('safetyFactors'));
      properties.push('safetyFactors' as DisplayableTreeProperty);
    }
    const cohortProperties = this.getCohortProperties();
    const propertiesQuery = properties.map(it => `&field=${it}`).join('');
    this.map.addSource('canopy-source', {
      type: 'vector',
      promoteId: 'id',
      tiles: [
        `${this.apiUrl}/v1/organizations/${this.organization.id}/mvt/canopy-contours?x={x}&y={y}&z={z}${propertiesQuery}&filterConfig=${JSON.stringify(this.filterConfiguration)}${cohortProperties}`
      ]
    });
  }

  private getCohortProperties(): string {
    return [
      ...(this.coloringType === ColoringType.COHORT && this.selectedPropertyConfig) ? [`&cohortField=${this.selectedPropertyConfig?.property}`] : []
    ].join('')
    ;
  }

  private addLayers() {
    if (!this.map) return;
    this.map.addLayer(
      {
        id: CanopyLayer.ID,
        type: 'fill',
        source: 'canopy-source',
        'source-layer': 'canopyContours',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'fill-color': 'rgba(206, 215, 212, 0.3)'
        },
        minzoom: this.organization.getClusteringZoomLevel()
      },
      TreeMarkerLayer.ID
    );

    this.map.addLayer(
      {
        id: 'canopy-borders',
        type: 'line',
        source: 'canopy-source',
        'source-layer': 'canopyContours',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'line-width': 2,
          'line-color': 'rgba(206, 215, 212, 1)'
        },
        minzoom: this.organization.getClusteringZoomLevel()
      },
      CanopyLayer.ID
    );
  }

  private showLayers() {
    if (!this.map?.getLayer('canopy') || !this.map?.getLayer('canopy-borders')) return;

    this.map.setLayoutProperty('canopy', 'visibility', 'visible');
    this.map.setLayoutProperty('canopy-borders', 'visibility', 'visible');
  }

  private hideLayers() {
    if (!this.map?.getLayer('canopy') || !this.map?.getLayer('canopy-borders')) return;

    this.map.setLayoutProperty('canopy', 'visibility', 'none');
    this.map.setLayoutProperty('canopy-borders', 'visibility', 'none');
  }

  removeFromMap() {
    this.removeLayers();
    this.removeSource();
  }

  private readonly onMouseLeave = () => {
    this.removeCanopyPopup();
    if (!this.map) return;
    this.map.getCanvas().style.cursor = 'unset';
    this.treeNamecard.hide();
    this.hoverTimer.resetTimer();
  };

  private removeLayers() {
    if (this.map) {
      this.map.off('click', CanopyLayer.ID, this.onClick);
      this.map.off('mouseenter', CanopyLayer.ID, this.onMouseEnter);
      this.map.off('mouseleave', CanopyLayer.ID, this.onMouseLeave);
      if (this.map.getLayer('canopy')) this.map.removeLayer('canopy');
      if (this.map.getLayer('canopy-borders')) this.map.removeLayer('canopy-borders');
    }
  }

  private removeSource() {
    if (this.map?.getSource('canopy-source')) {
      this.map.removeSource('canopy-source');
    }
  }

  private handleCanopyPopup = map => e => {
    if (e.features!.length > 0) {
      this.addCanopyPopup(map, e);
    }
  };

  private addCanopyPopup = (map, e) => {
    const externalId = e.features[0].properties.externalId;

    const canopyPopup = `<div>${externalId}</div>`;
    this.popup.setLngLat(e.lngLat).setHTML(canopyPopup).addTo(map);
  };

  private removeCanopyPopup = () => {
    this.popup.remove();
  };
}
