import FilterConfig from '../../../filter/FilterConfig';
import { JobColumnName } from './JobColumnName';

export enum TaskTemplateDedicatedFor {
  ON_SITE_INSPECTION = 'onSiteInspection',
  REMOTE_INSPECTION = 'remoteInspection'
}

export default interface TaskTemplate {
  id: string,
  organizationId: string,
  name: string,
  jobColumnName: JobColumnName,
  rank: number,
  connectedModule: string,
  filterConfig: FilterConfig,
  trees: string[],
  assignedTrees: string[],
  dedicatedFor: TaskTemplateDedicatedFor | null
}

export type TaskTemplateWithTrees = TaskTemplate;
export type TaskTemplateWithTreesDto = {
  name: string,
  taskTemplates: TaskTemplateWithTrees[],
  notAssignedCount: number,
  totalTreeCountByArea: number
};
