import { useState } from 'react';

export enum CarbonTheme {
  white = 'white',
  g100 = 'g100',
  g90 = 'g90',
  g10 = 'g10'
}

type Colors = {
  background: string,
  textPrimary: string,
  borderSubtle00: string
};

const colorMap = new Map<CarbonTheme, Colors>([
  [CarbonTheme.white, {
    background: '#ffffff',
    textPrimary: '#161616',
    borderSubtle00: '#e0e0e0'
  }],
  [CarbonTheme.g10, {
    background: '#f4f4f4',
    textPrimary: '#161616',
    borderSubtle00: '#c6c6c6'
  }],
  [CarbonTheme.g90, {
    background: '#262626',
    textPrimary: '#f4f4f4',
    borderSubtle00: '#525252'
  }],
  [CarbonTheme.g100, {
    background: '#161616',
    textPrimary: '#f4f4f4',
    borderSubtle00: '#393939'
  }]
]);

export default function useCarbonThemes() {
  const [theme, setTheme] = useState<CarbonTheme>((localStorage.getItem('carbon-theme') as CarbonTheme) || CarbonTheme.g90);
  const changeTheme = (newTheme: CarbonTheme) => {
    document.documentElement.dataset.carbonTheme = newTheme;
    localStorage.setItem('carbon-theme', newTheme);
    setTheme(newTheme);
  };
  if (!document.documentElement.dataset.carbonTheme) document.documentElement.dataset.carbonTheme = theme;

  return { theme, setTheme: changeTheme, colors: colorMap.get(theme) as Colors };
}