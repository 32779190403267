import { ButtonsOverlay, TileButton, TileContainer, TileContent } from '../TileLayout';
import useStreetViewProps from './useStreetViewProps';
import StreetViewBottomRightButtons from './StreetViewBottomCenterButtons';
import StreetViewContent from './StreetViewContent';
import DetailedTree from '../../../../tree/DetailedTree';
import ViewSelector from '../components/ViewSelector';
import { useContext, useMemo } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { TreeDisplays } from '../../../Details/TreeDisplayTile/TreeDisplays';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, ArrowRight, Close, SplitScreen } from '@carbon/icons-react';
import { Button } from '@carbon/react';

export default function StreetViewTile(props: StreetViewTileProps) {
  const streetViewProps = useStreetViewProps({ tree: props.tree, isCaptureModeActive: props.isCaptureModeActive });
  const { t } = useTranslation();

  const { capturePoints } = streetViewProps;
  const { urlContext } = useContext(DependencyInjectionContext);
  const capturePointId = urlContext.getCapturePointId();

  const currentCapturePointIndex = useMemo(() =>
    capturePoints.findIndex(it => it.id === capturePointId)
  , [capturePoints, capturePointId]);

  const handleLeftArrowClick = () => {
    if (capturePoints.length === 0) return;
    urlContext.setCapturePointId(capturePoints[currentCapturePointIndex].leftNeighborId);
  };

  const handleRightArrowClick = () => {
    if (capturePoints.length === 0) return;
    urlContext.setCapturePointId(capturePoints[currentCapturePointIndex].rightNeighborId);
  };

  return (
    <TileContainer>
      <TileContent>
        <ButtonsOverlay>
          <ButtonsOverlay.TopRight>
            {props.isCaptureModeActive &&
              <TileButton
                label={t('tooltips.close')}
                onClick={() => {
                  const photoReceiver = document.querySelector('#photoReceiver');
                  if (photoReceiver) photoReceiver.id = '';
                  props.setCaptureModeActive(false);
                }}
                icon={<Close/>}
              />
            }
            {props.showAddButton && <TileButton label={t('tooltips.splitScreen')} onClick={() => props.openSecondaryTile()} icon={<SplitScreen />} />}
            {props.showCloseButton && <TileButton label={t('tooltips.close')} onClick={() => props.setView(TreeDisplays.NULL)} icon={<Close />} />}
          </ButtonsOverlay.TopRight>
          <ButtonsOverlay.BottomCenter>
            {props.isCaptureModeActive &&
              <div id="recordPhotoButton">
                <TileButton
                  label={'Record Photo'}
                  // className="h-fit w-fit p-0 disabled:opacity-50"
                  onClick={() => {
                    const canvas = document.querySelector<HTMLCanvasElement>('#streetView');
                    if (canvas) {
                      const photoReceiver = document.querySelector('#photoReceiver');
                      if (photoReceiver) {
                        const img = document.createElement('img');
                        img.src = canvas.toDataURL('image/png');
                        img.className = 'h-16 bg-outer-space-500 rounded shadow';
                        photoReceiver.appendChild(img);
                      }
                    }
                  }}
                  icon={<div className="w-4 h-4 bg-danger-500 rounded-full m-4"/>}
                />
              </div>
            }
          </ButtonsOverlay.BottomCenter>
          <ButtonsOverlay.BottomRight>
            <ViewSelector
              setView={props.setView}
              view={props.view}
              showPointcloud={streetViewProps.showPointcloud}
              setPointcloudVisibility={streetViewProps.setPointcloudVisibility}
              hideCanopy={streetViewProps.hideCanopy}
              setHideCanopy={streetViewProps.setHideCanopy}
              showEnvironment={streetViewProps.showEnvironmentPointcloud}
              setEnvironmentVisibility={streetViewProps.setEnvironmentPointcloudVisibility}
            />
            <StreetViewBottomRightButtons {...streetViewProps} />
          </ButtonsOverlay.BottomRight>
          <ButtonsOverlay.LeftCenter>
            <Button
              kind="secondary"
              className="flex items-center justify-center size-12 p-0 bg-[rgba(0,0,0,0.5)]"
              onClick={handleLeftArrowClick}
              disabled={capturePoints.length === 0}
            >
              <ArrowLeft />
            </Button>
          </ButtonsOverlay.LeftCenter>
          <ButtonsOverlay.RightCenter>
            <Button
              kind="secondary"
              className="flex items-center justify-center size-12 p-0 bg-[rgba(0,0,0,0.5)]"
              onClick={handleRightArrowClick}
              disabled={capturePoints.length === 0}
            >
              <ArrowRight />
            </Button>
          </ButtonsOverlay.RightCenter>
        </ButtonsOverlay>
        <StreetViewContent {...streetViewProps} />
      </TileContent>
    </TileContainer>
  );
}

interface StreetViewTileProps {
  tree: DetailedTree,
  isCaptureModeActive: boolean,
  setCaptureModeActive: (active: boolean) => void,
  view: TreeDisplays,
  setView: (view: TreeDisplays) => void,
  openSecondaryTile: () => void,
  showAddButton: boolean,
  showCloseButton: boolean
}
