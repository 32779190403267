import { ButtonsOverlay, TileButton, TileButtonGroup, TileContainer, TileContent } from '../TileLayout';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DetailsContext } from '../../LegacyDetails';
import { DisplayableTreeProperty } from '../../../../tree/Tree';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { Xmark, CenterAlign, Expand, Minus, Plus, Reduce, Tree } from 'iconoir-react';
import styles from './TwinViewTile.module.scss';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { PointCloudView } from '../../../../components/PointCloud/PointCloudView';
import { MultiOrbitControl } from '../../../../components/PointCloud/MultiOrbitControl';
import { TreeDisplays } from '../../constants';
import AddPageIcon from '../components/AddPageIcon';
import ResetButton from '../components/ResetButton';
import ViewSelector from '../components/ViewSelector';
import DateSelector from '../components/DateSelector';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { EnvironmentAccordion } from '../../DataPanel/DataTabs/ClearancesTab';
import { Tab } from '../../DataPanel/DataTabs/TabSelector';
import { Tab as LegacyTab } from '../../LegacyDataPanel/DataTabs/TabSelector';
import RiskOverlay from '../components/RiskOverlay';
import { LoadablePointCloud } from '../../../../point-cloud/LoadablePointCloud';
import * as THREE from 'three';
import { useTranslation } from 'react-i18next';
import { RulerGroup } from '../../../../components/PointCloud/RulerGroup';
import HistoryTracking from './history-tracking/HistoryTracking';
import DetailedTree from '../../../../tree/DetailedTree';
import LShapeRuler from './LShapeRuler';
import { getEndYearFromScanInterval } from '../../../../tree/TseTreeScan';

export default function TwinViewTile(props: TwinViewTileProps) {
  const { t } = useTranslation();
  const { tree } = props;
  const [seed, setSeed] = useState(Math.random().toString(16).slice(-7));
  const [showDetails, setDetailsVisibility] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const viewRef = useRef(new PointCloudView());
  const treePointCloud = useRef<THREE.Group>(new THREE.Group());
  const environmentPointCloud = useRef<THREE.Group>(new THREE.Group());
  const criticalCorridorPointCloud = useRef<THREE.Group>(new THREE.Group());
  const criticalWirePointCloud = useRef<THREE.Group>(new THREE.Group());
  const historicalPointCloud = useRef<THREE.Group>(new THREE.Group());
  const rulersRef = useRef<RulerGroup[]>([]);
  const [hideCanopy, setHideCanopy] = useState(false);

  const account = useCurrentAccount();
  const { rulersAndHover: rulers } = useContext(DetailsContext);

  const [isViewInitialized, setIsViewInitialized] = useState(false);
  const [isPointCloudLoading, setIsPointCloudLoading] = useState(true);

  const [envPointCloudTriggeredByAccordion, setEnvPointCloudTriggeredByAccordion] = useState(false);
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const isCorridorClearanceDemoTree = ['4713', '4769'].includes(tree.externalId);
  const isWireClearanceDemoTree = ['4690', '4769'].includes(tree.externalId);
  const resetView = () => setSeed(Math.random().toString(16).slice(-7));
  const toggleDetails = () => setDetailsVisibility(state => !state);

  const selectedTab = urlContext.getSelectedDataPanelTab();
  const selectedAccordion = urlContext.getSelectedEnvAccordion();
  const selectedYear = urlContext.getSelectedYear();

  const selectedScan = useMemo(() => tree.tseTreeScans?.find(scan => getEndYearFromScanInterval(scan.scanInterval) === selectedYear), [tree.tseTreeScans, selectedYear]);

  useEffect(() => {
    if (tree === null) return;

    let isCancelled = false;
    const environmentPointSize = 1.6;
    const pos = tree.localizedLocation;
    Promise.all([
      new LoadablePointCloud(tree.getPointCloudUrl(account.organization)).loadInto(pos, false, undefined, hideCanopy).then(pc => {
        if (isCancelled) return;
        treePointCloud.current.add(pc);
      }),
      new LoadablePointCloud(tree.getEnvironmentPointCloudUrl(account.organization), environmentPointSize)
        .loadInto(pos, true)
        .then(pc => {
          if (isCancelled) return;
          environmentPointCloud.current.add(pc);
        }),
      isCorridorClearanceDemoTree ? new LoadablePointCloud(tree.getCorridorClearingPointCloudUrl(account.organization), 0.8)
        .loadInto(pos, true, new THREE.PointsMaterial({ size: 2, color: '#FF455E', sizeAttenuation: false }))
        .then(pc => criticalCorridorPointCloud.current.add(pc)) : Promise.resolve(),
      isWireClearanceDemoTree ? new LoadablePointCloud(account.organization.getCDNUrlOfTreeDataFromRelativePath(`tasks/US_PA_PIT23_0177_A_009/infrastructure/cable_clearing/las/${props.tree.externalId}.las`), 2)
        .loadInto(pos, true, new THREE.PointsMaterial({ size: 2, color: '#FF455E', sizeAttenuation: false }))
        .then(pc => criticalWirePointCloud.current.add(pc)) : Promise.resolve(),
      selectedScan?.pointCloudPath ? new LoadablePointCloud(account.organization.getCDNUrlOfTreeDataFromRelativePath(selectedScan.pointCloudPath))
        .loadInto(selectedScan.localizedLocation.coordinates, true, new THREE.PointsMaterial({ size: 0.2, color: '#aa26d1', sizeAttenuation: false }))
        .then(pc => historicalPointCloud.current.add(pc)) : Promise.resolve()
    ])
      .then(() => {
        viewRef.current.render();
        if (isCancelled) return;
        setIsPointCloudLoading(false);
      });
    return () => {
      treePointCloud.current.clear();
      environmentPointCloud.current.clear();
      historicalPointCloud.current.clear();
      isCancelled = true;
    };
  }, [tree, t, account.organization, isCorridorClearanceDemoTree, isWireClearanceDemoTree, selectedScan, hideCanopy]);

  useEffect(() => {
    const isOn = selectedAccordion === EnvironmentAccordion.CorridorClearing;
    if (!isOn && envPointCloudTriggeredByAccordion) {
      setDetailsVisibility(false);
      setEnvPointCloudTriggeredByAccordion(false);
    }
    if (isOn && !showDetails && !envPointCloudTriggeredByAccordion) {
      setDetailsVisibility(true);
      setEnvPointCloudTriggeredByAccordion(true);
    }
    if (isOn && showDetails && !envPointCloudTriggeredByAccordion) {
      setDetailsVisibility(false);
      setEnvPointCloudTriggeredByAccordion(false);
    }
  }, [envPointCloudTriggeredByAccordion, showDetails, selectedAccordion]);

  useEffect(() => {
    if (canvasRef.current === null) return;

    const view = viewRef.current;
    view.init(canvasRef.current, new MultiOrbitControl());

    setIsViewInitialized(true);

    return () => {
      view.dispose();
    };
  }, [canvasRef.current]);

  useEffect(() => {
    if (!props.tree) return;
    const halfHeight = props.tree.metricHeight / 2;
    viewRef.current.resetTo(halfHeight, props.tree.canopyDirection);
  }, [isViewInitialized, props.tree, seed, account.organization]);

  useEffect(() => {
    if (props.tree === null) return;

    const isMetrical = account.organization.getIsMetrical();

    rulersRef.current = [
      RulerGroup.forHeight(props.tree, t('analytics.properties.height'), viewRef.current.render, isMetrical),
      RulerGroup.forFirstBifurcation(props.tree, t('analytics.properties.trunkHeight'), viewRef.current.render, isMetrical),
      RulerGroup.forCanopyWidth(props.tree, t('analytics.properties.canopyWidth'), viewRef.current.render, isMetrical),
      RulerGroup.forCanopyHeight(props.tree, t('analytics.properties.canopyHeight'), viewRef.current.render, isMetrical),
      RulerGroup.forDBH(account.organization, props.tree, t('analytics.properties.trunkDiameter'), viewRef.current.render, false)
    ];
  }, [props.tree, t, account.organization]);

  useEffect(() => {
    if (!canvasRef.current?.parentElement) return;
    const ro = new ResizeObserver(() => viewRef.current?.setCanvasSize());
    ro.observe(canvasRef.current?.parentElement);
    return () => ro.disconnect();
  }, []);

  useEffect(() => {
    if (
      tree === null ||
      isPointCloudLoading ||
      !isViewInitialized ||
      urlContext.getSelectedDataPanelTab() === Tab.Risk ||
      urlContext.getSelectedDataPanelTab() === LegacyTab.Risk
    ) return;
    const isMetrical = account.organization.getIsMetrical();

    viewRef.current.addEventListeners();
    viewRef.current.clear();

    const wireClearanceIsVisible = selectedTab === Tab.Clearances && selectedAccordion === EnvironmentAccordion.DistanceToWires;
    if (wireClearanceIsVisible && isWireClearanceDemoTree) {
      (async () => {
        await viewRef.current.addCable(props.tree.getWireClearanceFullWireUrl(), props.tree.localizedLocation, 'gray');
        await viewRef.current.addCable(props.tree.getWireClearanceCollidedWireUrl(), props.tree.localizedLocation, 'white');
        viewRef.current.render();
      })();
    }

    const corridorClearanceIsVisible = selectedTab === Tab.Clearances && selectedAccordion === EnvironmentAccordion.CorridorClearing && tree.environment?.corridorLShapeVertex;
    if (corridorClearanceIsVisible && isCorridorClearanceDemoTree) {
      const lShapeRuler = new LShapeRuler(tree.environment.corridorLShapeVertex!.coordinates.map(coord => coord.map((it, i) => it - tree.localizedLocation[i])), isMetrical).toThreeObject();
      viewRef.current.addToScene(lShapeRuler.rotateX(-Math.PI / 2));
      (async () => {
        if (!tree.environment?.corridorLShapeVertex?.coordinates) return;
        await viewRef.current.addLShape(account.organization, tree.localizedLocation, tree.externalId);
        viewRef.current.render();
      })();
    }

    viewRef.current.addPointClouds([
      treePointCloud.current,
      showDetails && environmentPointCloud.current,
      (isCorridorClearanceDemoTree && corridorClearanceIsVisible) && criticalCorridorPointCloud.current,
      (isWireClearanceDemoTree && wireClearanceIsVisible) && criticalWirePointCloud.current,
      selectedScan && historicalPointCloud.current
    ].filter(it => !!it) as any[]);

    viewRef.current.addRulers(
      rulersRef.current
        .filter(it => rulers.includes(it.propertyName))
        .map(it => ((rulers.includes(DisplayableTreeProperty.CanopyWidth) || rulers.length > 1) ? it.displayedInOriginalPlace() : it.displayedInTheMiddle()))
    );

    viewRef.current.addGrid();
    viewRef.current.setCanvasSize();

    return () => {
      viewRef.current.removeEventListeners();
    };
  }, [
    isPointCloudLoading,
    isViewInitialized,
    tree,
    showDetails,
    selectedScan,
    t,
    selectedTab,
    rulers,
    selectedAccordion,
    isCorridorClearanceDemoTree
  ]);

  return (
    <TileContainer>
      <div className={styles.resizableContainer}>
        <ButtonsOverlay>
          <ButtonsOverlay.TopRight>
            <TileButton onClick={() => props.resizeTile()} icon={props.isExpanded ? <Reduce/> : <Expand/>}/>
            {props.showAddButton && <TileButton onClick={() => props.openSecondaryTile()} icon={<AddPageIcon/>}/>}
            {props.showCloseButton &&
                <TileButton onClick={() => props.setView(TreeDisplays.NULL)} icon={<Xmark/>}/>}
          </ButtonsOverlay.TopRight>
          <ButtonsOverlay.RightBottom>
            <ResetButton onClick={resetView}/>
            <TileButtonGroup>
              <TileButton onClick={() => viewRef?.current?.zoomIn()} icon={<Plus/>}/>
              <TileButton onClick={() => viewRef?.current?.zoomOut()} icon={<Minus/>}/>
            </TileButtonGroup>
          </ButtonsOverlay.RightBottom>
          <ButtonsOverlay.BottomCenter>
            <TileButton
              onClick={() => setHideCanopy(!hideCanopy)}
              icon={<Tree/>}
              active={!hideCanopy}
            />
            <TileButton
              onClick={toggleDetails}
              icon={<CenterAlign/>}
              active={showDetails}
            />
            <ViewSelector setView={props.setView} view={props.view}/>
          </ButtonsOverlay.BottomCenter>
          <ButtonsOverlay.BottomLeft>
            {tree.recordingDate && <DateSelector date={new Date(tree.recordingDate)}/>}
          </ButtonsOverlay.BottomLeft>
        </ButtonsOverlay>
        <TileContent>
          <div className={styles.container}>
            <canvas ref={canvasRef} className={styles.canvas}/>
            {isPointCloudLoading && (
              <div className={styles.spinner}>
                <Spinner/>
              </div>
            )}
            {(urlContext.getSelectedDataPanelTab() === Tab.Risk || urlContext.getSelectedDataPanelTab() === LegacyTab.Risk) &&
              <RiskOverlay
                tree={props.tree}
                viewRef={viewRef}
                treePointCloud={treePointCloud}
                environmentPointCloud={environmentPointCloud}
                isViewInitialized={isViewInitialized}
                showDetails={showDetails}
                isPointCloudLoading={isPointCloudLoading}
              />
            }
          </div>
        </TileContent>
      </div>
      <HistoryTracking tree={props.tree}/>
    </TileContainer>
  );
}

interface TwinViewTileProps {
  view: TreeDisplays,
  setView: (view: TreeDisplays) => void,
  tree: DetailedTree,
  openSecondaryTile: () => void,
  showAddButton: boolean,
  showCloseButton: boolean,
  resizeTile: () => void,
  isExpanded: boolean
}
