import { allOf, anyOf } from './MapboxPredicateHelper';
import FilterConfig, {
  EnumCondition,
  Filter,
  FilterGroup, isNumericCondition,
  NumericCondition,
  Operator
} from '../../../filter/FilterConfig';

export class CanopyLayerFilter {
  constructor(private readonly filterConfig: FilterConfig) {
  }

  create() {
    if (!this.filterConfig.filters?.length && !this.filterConfig.filterGroups?.length) return null;

    if (this.filterConfig.topLevelOperator === Operator.AND) {
      return allOf(
        ...this.filterConfig.filters.map(it => this.filteringLogic(it)),
        ...this.filterConfig.filterGroups.map(it => this.groupFilteringLogic(it))
      );
    }

    return anyOf(
      ...this.filterConfig.filters.map(it => this.filteringLogic(it)),
      ...this.filterConfig.filterGroups.map(it => this.groupFilteringLogic(it))
    );
  }

  private groupFilteringLogic(filterGroup: FilterGroup) {
    if (filterGroup.filterGroupOperator === Operator.AND) {
      return allOf(...filterGroup.filters.map(it => this.filteringLogic(it)));
    }

    return anyOf(...filterGroup.filters.map(it => this.filteringLogic(it)));
  }

  private filteringLogic(filter: Filter) {
    const property = isNumericCondition(filter.condition) ? ['/', ['round', ['*', 100, ['get', filter.property]]], 100] : ['get', filter.property];
    return [this.getOperator(filter), property, this.getValue(filter)];
  }

  private getOperator(filter: Filter) {
    switch (filter.condition) {
    case EnumCondition.IN:
      return 'in';
    case NumericCondition.GREATER_THAN:
      return '>';
    case NumericCondition.LESS_THAN:
      return '<';
    case NumericCondition.EQUALS:
    default:
      return '==';
    }
  }

  private getValue(filter: Filter) {
    if (filter.condition === EnumCondition.IN) return ['literal', filter.value.toString()];
    return filter.value;
  }
}
