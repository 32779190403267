import FilterConfig, { Operator } from './FilterConfig';
import { FilterCondition } from './FilterCondition';
import FilterConditionGroup from './FilterConditionGroup';
import { Tree } from '../tree/Tree';

export default class FilterCompound {
  static fromConfig(config: FilterConfig): FilterCompound {
    return new FilterCompound(
      config.filters.map(filterConditionConfig => FilterCondition.fromConfig(filterConditionConfig)),
      config.filterGroups.map(filterConditionGroupConfig => FilterConditionGroup.fromConfig(filterConditionGroupConfig)),
      config.topLevelOperator
    );
  }

  constructor(
    private readonly filters: FilterCondition[],
    private readonly filterGroups: FilterConditionGroup[],
    private readonly topLevelOperator: Operator
  ) {}

  apply(tree: Partial<Tree>): boolean {
    if (this.topLevelOperator === Operator.AND) {
      return this.filters.every(filter => filter.apply(tree)) && this.filterGroups.every(filterGroup => filterGroup.apply(tree));
    }
    return this.filters.some(filter => filter.apply(tree)) || this.filterGroups.some(filterGroup => filterGroup.apply(tree));
  }

  getFields(): string[] {
    return Array.from(new Set([
      'id',
      ...this.filters.map(f => f.property),
      ...this.filterGroups.flatMap(fg => fg.getFields())
    ]));
  }
}

