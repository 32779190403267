import { Bar } from 'react-chartjs-2';
import { LabelDistributionPoint, NumericDistributionPoint } from '../GraphModal';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import { useCurrentAccount } from '../../../../account/useAccounts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { usePropertyConfigurations } from '../../../../properties/usePropertyConfigurations';
import { useMemo } from 'react';
import { getTranslation } from './labelTranslation';
import { themes } from '@carbon/themes';
import useCarbonThemes from '../../../../components/UI/Carbon/useCarbonThemes';

const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const { ctx, chartArea: { left, top, width, height } } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.setLineDash(options.borderDash || []);
    ctx.lineDashOffset = options.borderDashOffset;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  }
};

type BarChartProps = {
  data: (NumericDistributionPoint | LabelDistributionPoint)[],
  barColors: string[],
  property: string,
  displayPercentage: boolean
};

export default function BarChart(props: BarChartProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const propertyConfig = usePropertyConfigurations().data.find(it => it.property === props.property);

  const { theme } = useCarbonThemes();

  const unit = Tree.getUnit(props.property, account.organization);
  const translatedUnit = unit ? `[${t('units.' + unit)}]` : '';
  const title = `${Tree.renderPropertyName(props.property, t)} ${translatedUnit}`;

  const maxPercentage = Math.max(...props.data.map(it => it.percentage * 100));
  const maxTreeCount = Math.max(...props.data.map(it => it.value));
  const total = props.data.reduce((acc, it) => acc + it.value, 0);

  const isLabelDistributionChart = useMemo(() => {
    return props.data.some(it => propertyConfig?.ranges.find(range => range.name === it.key));
  }, [props.data, propertyConfig]);

  const labels = useMemo(() => {
    return props.data.map(it => {
      if (propertyConfig?.ranges[0].value || propertyConfig?.property === DisplayableTreeProperty.OverallOutlierIndex)
        return getTranslation(it.key, t, props.property);
      if (isLabelDistributionChart) {
        const range = propertyConfig?.ranges.find(range => range.name === it.key);
        return [capitalize(it.key), range?.from && range?.to ? `(${Number.parseFloat(range.from.toFixed(1))}-${Number.parseFloat(range.to.toFixed(1))})` : ''];
      }
      return getTranslation(it.key, t, props.property);
    });
  }, [props.data, propertyConfig]);

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 32
      }
    },
    plugins: {
      legend: {
        display: false
      },
      chartAreaBorder: {
        borderColor: '#EDF4F299',
        borderWidth: 1
      },
      datalabels: {
        color: themes[theme].textPrimary,
        align: 'top' as const,
        anchor: 'end' as const,
        formatter: value => props.displayPercentage ? (value / total * 100).toFixed(1) + '%' : value,
        font: {
          weight: 'bold' as const
        }
      }
    },
    scales: {
      treeCountAxis: {
        max: maxTreeCount,
        title: {
          display: true,
          text: t('workspace.graphModal.numberOfTrees'),
          color: themes[theme].textPrimary,
          font: {
            size: 16
          }
        },
        type: 'linear' as const,
        position: 'left' as const,
        grid: {
          color: '#8D8D8D',
          drawTicks: false,
          borderDash: [1, 2],
          drawBorder: false
        },
        ticks: {
          display: true,
          color: themes[theme].textPrimary,
          callback: value => Math.round(value),
          padding: 12
        }
      },
      percentageAxis: {
        max: maxPercentage,
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          display: false
        },
        ticks: {
          padding: 12,
          color: themes[theme].textPrimary,
          callback: value => Math.round(value) + ' %'
        }
      },
      x: {
        title: {
          display: true,
          text: title,
          color: themes[theme].textPrimary,
          font: {
            size: 16
          },
          padding: {
            top: 16
          }
        },
        ticks: {
          color: themes[theme].textPrimary
        },
        grid: {
          display: false
        }
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: t('workspace.graphModal.trees'),
        data: props.data.map(it => it.value),
        backgroundColor: props.barColors.map((_, idx) => props.barColors[idx % props.barColors.length]),
        barPercentage: 1
      }
    ]
  };

  return (
    <Bar
      options={options}
      data={data}
      plugins={[chartAreaBorder, ChartDataLabels]}
    />
  );
}

function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
