import styles from './Workspace.module.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { useAvailableProperties, usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useCurrentAccount } from '../../account/useAccounts';
import TableDashboard from './components/TableDashboard';
import { useTranslation } from 'react-i18next';
import { useFetchTreeList } from './useFetchTreeList';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import LazyTreeList from './LazyTreeList';
import { TaskTemplateWithTrees } from '../TaskManager/create/TaskTemplate';
import { OverflowMenu, OverflowMenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@carbon/react';
import PartialTree from '../../tree/PartialTree';
import { SortButton, SortingDirection } from './SortButton';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { ChartColumn, ColorPalette, View } from '@carbon/icons-react';
import useManagedAreas from '../../managed-area/useManagedAreaList';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import { OpenNewWindow } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';
import GraphModal from './components/GraphModal';
import PropertyColorConfiguration from '../../properties/PropertyColorConfiguration';

export default function TreeTable(props: TreeTableProps) {
  const propertyConfigs = usePropertyConfigurations();
  const account = useCurrentAccount();
  const { t } = useTranslation();
  const { managedAreaList } = useManagedAreas(account.organization.id);

  const [trees, setTrees] = useState<PartialTree[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const { urlContext } = useContext(DependencyInjectionContext);
  const navigate = useNavigate();

  const [graphModalProperty, setGraphModalProperty] = useState<DisplayableTreeProperty | null>(null);

  const { treeList, firstPageIsLoading } = useFetchTreeList(0, urlContext.getTreeTableSortingString(), props.filters, props.properties, props.taskTemplate?.id);
  const [loading, setLoading] = useState(false);

  const fullSizedContainerRef = useRef<HTMLDivElement | null>(null);

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const getPropertyConfigColor = (property: DisplayableTreeProperty, value: number | string) => {
    if (propertyConfigs.isLoading) return '255, 255, 255';
    const propertyConfig = propertyConfigs.data.find(it => it.property === property);
    if (propertyConfig) {
      const index = propertyConfig.getRangeIndexForValue(value, Boolean(propertyConfig.ranges[0].value));
      const colors = PropertyColorConfiguration.getColorsForConfig(propertyConfig);
      return colors[index];
    }
    return '255, 255, 255';
  };

  useEffect(() => {
    if (!treeList) return;
    setLoading(true);
    treeList.getRange(0, (pageCount + 1) * 100).then(trees => {
      localStorage.setItem(
        'loadedTableTrees',
        JSON.stringify(trees.map(it => ({ id: it.id, externalId: it.externalId })))
      );
      setTrees(trees);
      setLoading(false);
    });
    return () => {
      treeList?.cancel();
      setLoading(false);
    };
  }, [treeList, pageCount]);

  useEffect(() => {
    if (!fullSizedContainerRef.current || !treeList) return;

    const onScroll = e => {
      const isScrolledToBottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
      if (isScrolledToBottom) {
        setPageCount(pageCount => pageCount + 1);
      }
    };

    fullSizedContainerRef.current.getElementsByTagName('table')[0].addEventListener('scroll', onScroll);

    return () => {
      if (!fullSizedContainerRef.current) return;
      fullSizedContainerRef.current.getElementsByTagName('table')[0].removeEventListener('scroll', onScroll);
    };
  }, [treeList]);

  function toggleSort(property: DisplayableTreeProperty | 'externalId') {
    return () => {
      const direction = (urlContext.getTreeTableSorting()?.property === property && urlContext.getTreeTableSorting()?.direction) || null;
      urlContext.setTreeTableSorting(property, direction === SortingDirection.ASCENDING ? SortingDirection.DESCENDING : SortingDirection.ASCENDING);
    };
  }

  const navigateToTreeDetails = (event, treeId: string) => {
    event.stopPropagation();
    navigate(`/organizations/${account.organization.id}/inventory/trees/${treeId}`);
  };

  const handleTableTreeSelect = id => {
    urlContext.setTreeId(id);
    urlContext.setCapturePointId(null);
    urlContext.setCameraRotation(null);
  };

  const properties = useAvailableProperties();
  const mapAnalyticsProperties = Object.keys(properties).reduce((acc, key) => {
    acc = [...acc, ...properties[key]];
    return acc;
  }, [] as DisplayableTreeProperty[]);

  if (propertyConfigs.isLoading) return <Spinner />;
  return <>
    {props.open && (
      <div
        className={`${styles.treeListTableContainer}`}
        onMouseEnter={() => {
          if (props.properties.length * 200 + 100 > window.innerWidth) {
            const arrows = document.getElementsByClassName(styles.floatingArrow);
            for (let i = 0; i < arrows.length; i++) {
              const arrow = arrows.item(i);
              (arrow as HTMLDivElement).style.visibility = 'visible';
            }
          }
        }}
        onMouseLeave={() => {
          const arrows = document.getElementsByClassName(styles.floatingArrow);
          for (let i = 0; i < arrows.length; i++) {
            const arrow = arrows.item(i);
            (arrow as HTMLDivElement).style.visibility = 'hidden';
          }
        }}
      >
        <TableDashboard
          treeList={treeList as LazyTreeList}
          windSpeed={props.windSpeed}
          properties={props.properties}
        />
        <div className="mx-8 h-full">
          <div className="text-sm leading-[18px] tracking-tight mb-1">
            {`${t('total')} ${treeList?.getTotalFilteredByArea() || 0} / ${t('filtered')} ${treeList?.getNotFilteredCount() || 0}`}
          </div>
          {props.areaFilterIsSelected ?
            <div
              ref={fullSizedContainerRef}
              className="h-full max-w-full overflow-y-clip [&>section]:h-full [&>section>div]:h-full [&>section>div]:border-none"
              style={{ width: (props.properties.length * 200) + 12 + 'px' }}
            >
              <Table className="max-h-[calc(100%-111px)] border-none bg-[var(--cds-layer)]" size="lg" useZebraStyles={false} stickyHeader={true}>
                <TableHead style={{ width: (props.properties.length * 200) + 7 + 'px', overflow: 'hidden' }}>
                  <TableRow>
                    {props.properties.map(property => {
                      const unit = Tree.getUnit(property, account.organization);
                      const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
                      const columnTitle = Tree.renderPropertyName(property, t) + translatedUnit;

                      return <td id={property} key={property} className="h-12 flex items-center justify-between p-0" style={{ paddingBlockStart: '0px', paddingInlineEnd: '0px' }}>
                        <Tooltip overlay={columnTitle}>
                          <div className={`overflow-x-clip text-ellipsis whitespace-nowrap shrink-[2] ${hasMoreOptions(property) || isSortableProperty(property) ? 'w-[86px]' : 'w-full'}`}>{columnTitle}</div>
                        </Tooltip>
                        <div className="flex shrink-1">
                          {isSortableProperty(property) && (
                            <Tooltip overlay={t('tooltips.sort')}>
                              <div>
                                <SortButton
                                  sortingDirection={urlContext.getTreeTableSorting()?.property === property ? urlContext.getTreeTableSorting()?.direction : null}
                                  onClick={toggleSort(property)}
                                />
                              </div>
                            </Tooltip>
                          )}
                          {hasMoreOptions(property) && (
                            <Tooltip overlay={t('tooltips.more')}>
                              <OverflowMenu menuOptionsClass="w-fit mt-1" className="p-6" flipped>
                                {mapAnalyticsProperties.includes(property as DisplayableTreeProperty) && <OverflowMenuItem
                                  itemText={<div className="flex gap-2 p-2 pl-0 whitespace-nowrap">
                                    <ColorPalette />
                                    {t('treeList.mapAnalytics')}
                                    {urlContext.getSelectedTreeProperty() === property ? <View /> : ''}
                                  </div>}
                                  onClick={() => {
                                    if (urlContext.getSelectedTreeProperty() === property) {
                                      urlContext.setSelectedTreeProperty(null);
                                    } else {
                                      urlContext.setSelectedTreeProperty(property as DisplayableTreeProperty);
                                    }
                                  }}
                                />}
                                <OverflowMenuItem
                                  itemText={<div className="flex gap-2 p-2 pl-0 whitespace-nowrap">
                                    <ChartColumn/>
                                    {t('treeList.statistics')}
                                  </div>}
                                  onClick={() => setGraphModalProperty(property as DisplayableTreeProperty)}
                                />
                              </OverflowMenu>
                            </Tooltip>
                          )}
                        </div>
                      </td>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: (props.properties.length * 200) + 7 + 'px' }}>
                  {trees.map(tree =>
                    <TableRow key={tree.id} onClick={() => handleTableTreeSelect(tree.id)} isSelected={urlContext.getTreeId() === tree.id}>
                      {props.properties.map(property => {
                        if (property === DisplayableTreeProperty.ExternalId) {
                          return <TableCell key={property} className="flex justify-between">
                            <span>{tree.externalId}</span>
                            <Tooltip overlay={t('tooltips.openDetails')}>
                              <OpenNewWindow onClick={e => navigateToTreeDetails(e, tree.id)} className="cursor-pointer" />
                            </Tooltip>
                          </TableCell>;
                        }

                        const value = property === 'managedAreaId' ? tree['managedAreaName'] : tree[property];

                        return <TableCell key={property} className={`${Tree.isItalicProperty(property) ? 'italic' : ''} relative`}>
                          <span className="w-full" style={selectedTreeProperty === property ? { color: `rgb(${getPropertyConfigColor(property, value)})` } : {}}>
                            {Tree.renderPropertyValue(property, value, t)}
                          </span>
                          <div className="absolute left-0 top-0 bottom-0 w-[1px] bg-[var(--cds-border-subtle-00)]"></div>
                        </TableCell>;
                      })}
                    </TableRow>)}
                </TableBody>
              </Table>
            </div>
            : <EmptyState/>}
          {(firstPageIsLoading || loading) && <Spinner/>}
        </div>
      </div>
    )}
    {graphModalProperty && <GraphModal
      onClose={() => setGraphModalProperty(null)}
      property={graphModalProperty}
      managedAreas={managedAreaList}
    />}
  </>;
}

function EmptyState() {
  const { t } = useTranslation();

  return <div className={styles.emptyState}>
    <h2>{t('workspace.emptyState')}</h2>
    <p>{/*Lorem ipsum*/}</p>
  </div>;
}

export type Property = DisplayableTreeProperty | 'externalId';

const propertiesWithoutOptions: string[] = [
  DisplayableTreeProperty.DisplayableWorldCoordinates,
  DisplayableTreeProperty.CustomerTreeId,
  DisplayableTreeProperty.CustomerTagId,
  DisplayableTreeProperty.CustomerSiteId,
  DisplayableTreeProperty.StreetAddress,
  DisplayableTreeProperty.ExternalId,
  DisplayableTreeProperty.CultivarOrVariety,
  DisplayableTreeProperty.Owner,
  DisplayableTreeProperty.AddressFromParcel,
  DisplayableTreeProperty.CommonName,
  DisplayableTreeProperty.ParkName,
  DisplayableTreeProperty.OnStreetName,
  DisplayableTreeProperty.GrowSpaceSize,
  DisplayableTreeProperty.FurtherInspectionNeeded,
  DisplayableTreeProperty.RecordingDate,
  DisplayableTreeProperty.LastUpdatedAt
];

const nonSortableProperties: string[] = [
  DisplayableTreeProperty.DisplayableWorldCoordinates
];

const isSortableProperty = (property: Property) => !nonSortableProperties.includes(property);

const hasMoreOptions = (property: Property) => !propertiesWithoutOptions.includes(property);

interface TreeTableProps {
  filters: TreeFilter[],
  properties: Property[],
  areaFilterIsSelected: boolean,
  windSpeed: number,
  open: boolean,
  taskTemplate?: TaskTemplateWithTrees
}
