import mapboxgl, { MapLayerEventType } from 'mapbox-gl';
import { ManagedArea } from '../../../../managed-area/ManagedArea';
import { TreeMarkerLayer } from './TreeMarkerLayer';
import { Polygon } from 'geojson';
import { CanopyLayer } from './CanopyLayer';
import { Organization } from '../../../../organization/Organization';
import tooltipStyles from '../../../../components/UI/Carbon/tooltip/Tooltip.module.scss';

export class ManagedAreaLayer {
  static ID = 'managed-areas';
  private enableClickToFit = true;
  private hoveredManagedAreaId: string | null = null;
  private popup = new mapboxgl.Popup({
    className: tooltipStyles.mapboxTooltip,
    closeButton: false,
    closeOnClick: false
  });

  constructor(
    private readonly apiUrl: string,
    private readonly organization: Organization
  ) {}

  private onSelectManagedArea = (_: string) => {};
  private onDeselectManagedArea = (_: string) => {};

  private fitOnClick = (event: MapLayerEventType['click']) => {
    if (!this.enableClickToFit) return;

    const [canopy] = event.target.queryRenderedFeatures(event.point, { layers: [CanopyLayer.ID] });
    if (canopy) return;

    const [managedArea] = event.target.queryRenderedFeatures(event.point, { layers: ['managed-areas'] });
    if (!managedArea) return;

    event.target.fitBounds(
      (managedArea.geometry as Polygon).coordinates
        .flatMap(position => position as [number, number][])
        .reduce((bounds, position) => bounds.extend(position), new mapboxgl.LngLatBounds())
    );
  };

  private selectManagedArea = (event: MapLayerEventType['click']) => {
    const [canopy] = event.target.queryRenderedFeatures(event.point, { layers: [CanopyLayer.ID] });
    if (canopy) return;
    const [managedArea] = event.target.queryRenderedFeatures(event.point, { layers: ['managed-areas'] });
    if (!managedArea) {
      return;
    }

    this.onSelectManagedArea(managedArea.properties!.id);
  };

  private deselectManagedArea = (event: MapLayerEventType['click']) => {
    const [canopy] = event.target.queryRenderedFeatures(event.point, { layers: [CanopyLayer.ID] });
    if (canopy) return;
    const [managedArea] = event.target.queryRenderedFeatures(event.point, { layers: ['selected-managed-areas'] });
    if (!managedArea) {
      return;
    }

    this.onDeselectManagedArea(managedArea.properties!.id);
  };

  addTo(map: mapboxgl.Map) {
    map.addSource('managed-areas', {
      type: 'vector',
      promoteId: 'id',
      tiles: [
        `${this.apiUrl}/v1/organizations/${this.organization.id}/mvt/managed-areas?x={x}&y={y}&z={z}`
      ]
    });

    map.addLayer(
      {
        id: 'managed-areas',
        type: 'fill',
        source: 'managed-areas',
        'source-layer': 'managedAreas',
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(39, 185, 136, 0.1)',
            'rgba(206, 215, 212, 0.1)'
          ]
        }
      },
      TreeMarkerLayer.ID
    );

    map.addLayer(
      {
        id: 'managed-area-borders',
        type: 'line',
        source: 'managed-areas',
        'source-layer': 'managedAreas',
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            '#27B988',
            'rgba(255, 255, 255, 0.5)'
          ],
          'line-width': 2,
          'line-dasharray': [4, 4]
        }
      },
      TreeMarkerLayer.ID
    );

    map.addLayer(
      {
        id: 'selected-managed-areas',
        type: 'fill',
        source: 'managed-areas',
        'source-layer': 'managedAreas',
        paint: {
          'fill-color': 'rgba(39, 185, 136, 0.1)'
        },
        filter: ['==', '1', '0']
      },
      TreeMarkerLayer.ID
    );

    map.addLayer(
      {
        id: 'selected-managed-area-borders',
        type: 'line',
        source: 'managed-areas',
        'source-layer': 'managedAreas',
        paint: {
          'line-color': '#27B988',
          'line-width': 2
        },
        filter: ['==', '1', '0']
      },
      TreeMarkerLayer.ID
    );

    map.on('click', 'managed-areas', this.fitOnClick);
    map.on('click', 'managed-areas', this.selectManagedArea);
    map.on('click', 'selected-managed-areas', this.deselectManagedArea);

    map.on('mousemove', 'managed-areas', this.onMouseMove(map));
    map.on('mouseleave', 'managed-areas', this.onMouseLeave(map));
    map.on('mousemove', ['managed-areas', 'selected-managed-areas'], this.handleAreaPopup(map));
    map.on('mouseleave', ['managed-areas', 'selected-managed-areas'], this.removeAreaPopup);
  }

  onMouseMove = map => e => {
    if (e.features!.length > 0) {
      if (this.hoveredManagedAreaId !== null) {
        map.setFeatureState(
          { source: 'managed-areas', sourceLayer: 'managedAreas', id: this.hoveredManagedAreaId },
          { hover: false }
        );
      }

      this.hoveredManagedAreaId = e.features[0].id;
      map.setFeatureState(
        { source: 'managed-areas', sourceLayer: 'managedAreas', id: this.hoveredManagedAreaId },
        { hover: true }
      );
    }
  };

  onMouseLeave = map => () => {
    if (this.hoveredManagedAreaId !== null) {
      map.setFeatureState(
        { source: 'managed-areas', sourceLayer: 'managedAreas', id: this.hoveredManagedAreaId },
        { hover: false }
      );
    }
    this.hoveredManagedAreaId = null;
  };

  filterOn(map: mapboxgl.Map, dataToShow: ManagedArea[], selectedTreeId?: string | null) {
    if (dataToShow.length === 0) {
      this.enableClickToFit = true;

      map.setFilter('managed-areas', null);
      map.setFilter('managed-area-borders', null);
      map.setFilter('selected-managed-areas', ['==', '1', '0']);
      map.setFilter('selected-managed-area-borders', ['==', '1', '0']);
    } else {
      this.enableClickToFit = false;

      const selectedManagedAreaIds = dataToShow.map(managedArea => managedArea.id);
      map.setFilter('managed-areas', ['match', ['get', 'id'], selectedManagedAreaIds, false, true]);
      map.setFilter('managed-area-borders', ['match', ['get', 'id'], selectedManagedAreaIds, false, true]);
      map.setFilter('selected-managed-areas', ['match', ['get', 'id'], selectedManagedAreaIds, true, false]);
      map.setFilter('selected-managed-area-borders', ['match', ['get', 'id'], selectedManagedAreaIds, true, false]);
    }

    if (dataToShow.length === 0) {
      return;
    }

    if (selectedTreeId) return;
    map.fitBounds(
      dataToShow
        .flatMap(managedArea => managedArea.getBoundingBoxCoordinates())
        .reduce((bounds, coordinates) => bounds.extend(coordinates), new mapboxgl.LngLatBounds())
      , { bearing: 0, pitch: 0 });
  }

  removeFrom(map: mapboxgl.Map) {
    this.removeExistingLayer(map, 'selected-managed-areas');
    this.removeExistingLayer(map, 'selected-managed-area-borders');
    this.removeExistingLayer(map, 'managed-areas');
    this.removeExistingLayer(map, 'managed-area-borders');

    if (map.getSource('managed-areas')) {
      map.removeSource('managed-areas');
    }

    map.off('click', 'managed-areas', this.fitOnClick);
    map.off('click', 'selected-managed-areas', this.deselectManagedArea);
    map.off('click', 'managed-areas', this.selectManagedArea);
    map.off('mousemove', 'managed-areas', this.onMouseMove(map));
    map.off('mouseleave', 'managed-areas', this.onMouseLeave(map));

    map.off('mousemove', 'managed-areas', this.handleAreaPopup(map));
    map.off('mousemove', 'selected-managed-areas', this.handleAreaPopup(map));
    map.off('mouseleave', 'managed-areas', this.removeAreaPopup);
    map.off('mouseleave', 'selected-managed-areas', this.removeAreaPopup);
  }

  private handleAreaPopup = map => e => {
    const overflowingLayers = e.target.queryRenderedFeatures(e.point, { layers: ['canopy'] });

    if (e.features!.length > 0 && overflowingLayers.length === 0) {
      this.addAreaPopup(map, e);
    }

    if (overflowingLayers.length > 0) {
      this.removeAreaPopup();
    }
  };

  private addAreaPopup = (map, e) => {
    const managedAreaCode = e.features[0].properties.code;

    const managedAreaPopup = `<div>${managedAreaCode}</div>`;
    this.popup.setLngLat(e.lngLat).setHTML(managedAreaPopup).addTo(map);
  };

  private removeAreaPopup = () => {
    this.popup.remove();
  };

  private removeExistingLayer(map: mapboxgl.Map, layerId: string) {
    if (!map.getLayer(layerId)) {
      return;
    }

    map.removeLayer(layerId);
  }

  setOnSelectCallback(onSelectManagedArea: (managedAreaId: string) => unknown) {
    this.onSelectManagedArea = onSelectManagedArea;
  }

  setOnDeselectCallback(onDeselectManagedArea: (managedAreaId: string) => unknown) {
    this.onDeselectManagedArea = onDeselectManagedArea;
  }
}
