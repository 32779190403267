import mapboxgl, { CustomLayerInterface, MapboxGeoJSONFeature } from 'mapbox-gl';
import { Organization } from '../../../../organization/Organization';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { DisplayableTreeProperty } from '../../../../tree/Tree';
import Supercluster from 'supercluster';
import CohortColor from '../../../../components/cohort/CohortColor';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import FilterConfig from '../../../../filter/FilterConfig';

type MarkerWithExtraInfo = mapboxgl.Marker & { isMA: boolean };

export default class TreeClusterLayer implements CustomLayerInterface {
  static ID = 'custom-tree-cluster-layer';
  id = TreeClusterLayer.ID;
  renderingMode: '2d' | '3d' | undefined;
  readonly type = 'custom';
  private readonly markers: Record<string, MarkerWithExtraInfo> = {};
  private markersOnScreen: string[] = [];

  constructor(
    private readonly apiUrl: string,
    private readonly organization: Organization,
    private readonly selectedProperty: string | null,
    private readonly windSpeed: number,
    private readonly managedAreaIds: string[],
    private readonly reverseMASelection: boolean,
    private readonly filterIds: string[],
    private readonly filterConfig: FilterConfig,
    private readonly coloringType: string,
    private readonly selectedPropertyConfig: PropertyConfiguration | null
  ) {
  }

  private readonly renderListener = (map: mapboxgl.Map) => () => this.updateMarkersAfterSourceIsLoaded(map);
  private readonly clickListener = (map: mapboxgl.Map, marker: MarkerWithExtraInfo) => this.zoomToCluster(map, marker);
  private readonly mouseenterListener = event => this.changeCursorToPointer(event);
  private readonly mouseleaveListener = event => this.changeCursorToDefault(event);

  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext): void {
    map.addSource('clusters', {
      type: 'vector',
      promoteId: 'id',
      tiles: [this.getSourceUrl()],
      maxzoom: this.organization.getClusteringZoomLevel()
    });

    map.addLayer({
      id: 'cluster-labels',
      type: 'symbol',
      source: 'clusters',
      'source-layer': 'clusters',
      maxzoom: this.organization.getClusteringZoomLevel()
    });

    map.on('render', this.renderListener(map));
  }

  onRemove(map: mapboxgl.Map, gl: WebGLRenderingContext): void {
    map.off('render', this.renderListener(map));

    Object.values(this.markers).forEach(it => {
      it.getElement().removeEventListener('click', () => this.clickListener(map, it));
      it.getElement().removeEventListener('mouseenter', this.mouseenterListener);
      it.getElement().removeEventListener('mouseleave', this.mouseleaveListener);
    });

    document.querySelectorAll('.treeClusterDonut').forEach(it => it.remove());
    this.removeExistingLayer(map, 'cluster-labels');
    this.removeExistingSource(map, 'clusters');
  }

  prerender(gl: WebGLRenderingContext, matrix: number[]): void {
  }

  render(gl: WebGLRenderingContext, matrix: number[]): void {
  }

  private getFeatureKey(feature: MapboxGeoJSONFeature) {
    return `${feature.id}-${feature.properties?.size}-${feature.properties?.ranges}`;
  }

  private updateMarkers(map: mapboxgl.Map) {
    const featureKeys: string[] = [];
    const rawFeatures = map.querySourceFeatures('clusters', { sourceLayer: 'clusters' }) as RawTreeClusterFeature[];
    const features = this.parseFeatures(rawFeatures);
    const clusters = this.createClusters(map, features);

    for (const feature of clusters) {
      if (feature.geometry.type !== 'Point') {
        return;
      }
      const coords = feature.geometry.coordinates;
      if (!coords) continue;

      const featureKey = this.getFeatureKey(feature);
      if (!this.markers[featureKey]) {
        const marker = this.createMarker(feature);
        const element = marker.getElement();
        element.addEventListener('click', () => this.clickListener(map, marker));
        element.addEventListener('mouseenter', this.mouseenterListener);
        element.addEventListener('mouseleave', this.mouseleaveListener);
        this.markers[featureKey] = marker;
      }
      featureKeys.push(featureKey);

      if (!this.markersOnScreen.includes(featureKey))
        this.markers[featureKey].addTo(map);
    }

    this.removeOffscreenMarkers(featureKeys);
    this.markersOnScreen = featureKeys;
  }

  private parseFeatures(features: RawTreeClusterFeature[]): TreeClusterFeature[] {
    const featureMap = {};
    features.forEach(it => featureMap[it.id] = Object.assign(it, { properties: { ...it.properties, ranges: JSON.parse(it.properties.ranges || '[]') } }));
    return Object.values(featureMap);
  }

  private createClusters(map: mapboxgl.Map, features: TreeClusterFeature[]): TreeClusterFeature[] {
    const bounds = map.getBounds();
    const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
    const index = new Supercluster({
      radius: 50,
      maxZoom: this.organization.getClusteringZoomLevel(),
      map: props => ({ size: props.size, ranges: props.ranges }),
      reduce: (accumulated, props) => {
        accumulated.size += props.size;
        accumulated.ranges = accumulated.ranges?.map((it, i) => it + (props.ranges[i] || 0)) || [];
      }
    });
    index.load(features);
    return index.getClusters(bbox, map.getZoom());
  }

  private removeOffscreenMarkers(newFeatureKeys: string[]) {
    this.markersOnScreen.filter(id => !newFeatureKeys.includes(id))
      .forEach(id => {
        this.markers[id].remove();
        delete this.markers[id];
      });
  }

  private createMarker(feature): MarkerWithExtraInfo {
    const coords = feature.geometry.coordinates;
    const ranges = feature.properties.ranges;
    const el = this.createClusterDonuts(ranges, feature.properties.size || 0);
    const marker = new mapboxgl.Marker({ element: el }).setLngLat(coords as [number, number]) as MarkerWithExtraInfo;
    marker.getElement().style.display = 'none';
    setTimeout(() => marker.getElement().style.display = 'flex');
    marker.isMA = !!feature.properties.id;
    return marker;
  }

  private createClusterDonuts(ranges: number[], size: number): HTMLDivElement {
    let isFiltered;
    if (ranges.length !== 0) isFiltered = true;
    const rangeColors = this.getRangeColors();
    const calculateDegrees = () => {
      const arcs: number[][] = [];
      ranges.forEach((range, index) => {
        const startDegree = index === 0 ? 0 : arcs[index - 1][1];
        const endDegree = startDegree + (360 / size * range);
        arcs[index] = [startDegree, endDegree];
      });
      return arcs.map((arc, index) => {
        if (index === ranges.length - 1) return `rgb(255, 255, 255) ${arc[0]}deg ${arc[1]}deg`;
        return `rgb(${rangeColors[index]}) ${arc[0]}deg ${arc[1]}deg`;
      });
    };
    const background = calculateDegrees().join(', ');
    const outerCircleSize = () => {
      const value = 40 + 8 * Math.log(1 + (size / 24));
      return `${value}px`;
    };
    const innerCircleSize = isFiltered ? '80%' : '90%';
    const donut = document.createElement('div');
    donut.classList.add('treeClusterDonut');
    donut.style.background = isFiltered ? `conic-gradient(${background})` : 'white';
    donut.style.width = outerCircleSize();
    donut.style.height = outerCircleSize();
    donut.innerHTML = `<div class="treeClusterLabel" style="width: ${innerCircleSize}; height: ${innerCircleSize}">${size}</div>`;
    donut.onclick = e => e.stopPropagation();
    donut.style.zIndex = `${size}`;
    return donut;
  }

  private getRangeColors(): string[] {
    if (this.coloringType === 'cohort') {
      return Object.values(CohortColor.rgbs);
    }
    if (!this.selectedPropertyConfig) return [];
    return PropertyColorConfiguration.getColorsForConfig(this.selectedPropertyConfig);
  }

  private getSourceUrl() {
    const qs = ['x={x}', 'y={y}', 'z={z}'];
    if (this.selectedProperty) {
      const property = this.selectedProperty !== DisplayableTreeProperty.SafetyFactors ? this.selectedProperty : `${this.selectedProperty}-${this.windSpeed}`;
      qs.push(`property=${property}`);
    }
    this.managedAreaIds.map(area => qs.push(`managedAreaId=${area}`));
    this.filterIds.map(filter => qs.push(`filterId=${filter}`));
    qs.push(`filterConfig=${JSON.stringify(this.filterConfig)}`);

    return `${this.apiUrl}/v1/organizations/${this.organization.id}/mvt/tree-clusters?${qs.join('&')}&reverseMASelection=${this.reverseMASelection}${this.coloringType ? `&coloringType=${this.coloringType}` : ''}`;
  }

  private removeExistingSource(map: mapboxgl.Map, sourceId: string) {
    if (!map.getSource(sourceId)) {
      return;
    }

    map.removeSource(sourceId);
  }

  private removeExistingLayer(map: mapboxgl.Map, layerId: string) {
    if (!map.getLayer(layerId)) {
      return;
    }

    map.removeLayer(layerId);
  }

  private updateMarkersAfterSourceIsLoaded(map: mapboxgl.Map) {
    if (!map.getSource('clusters') || !map.isSourceLoaded('clusters')) return;
    this.updateMarkers(map);
  }

  private zoomToCluster(map: mapboxgl.Map, marker: MarkerWithExtraInfo) {
    if (marker.isMA) {
      const zoom = this.organization.getClusteringZoomLevel() + 1;
      return map.easeTo({ center: marker.getLngLat(), zoom });
    }
    map.easeTo({ center: marker.getLngLat(), zoom: map.getZoom() + 2 });
  }

  private changeCursorToPointer(event: MouseEvent) {
    (event.target as HTMLDivElement).style.cursor = 'pointer';
  }

  private changeCursorToDefault(event) {
    (event.target as HTMLDivElement).style.cursor = '';
  }
}

type RawTreeClusterFeature = MapboxGeoJSONFeature & {
  id: string,
  properties: {
    ranges: string,
    size: number
  }
};

type TreeClusterFeature = Omit<RawTreeClusterFeature, 'properties'> & {
  properties: {
    ranges: number[],
    size: number,
    id?: string
  }
};
