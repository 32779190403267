import { ButtonsOverlay, TileButton, TileContainer, TileContent } from '../TileLayout';
import useStreetViewProps from './useStreetViewProps';
import StreetViewBottomRightButtons from './StreetViewBottomCenterButtons';
import StreetViewRightBottomButtons from './StreetViewRightBottomButtons';
import StreetViewContent from './StreetViewContent';
import DetailedTree from '../../../../tree/DetailedTree';
import ViewSelector from '../components/ViewSelector';
import { NavArrowLeft, NavArrowRight, Xmark } from 'iconoir-react';
import styles from './StreetViewTile.module.scss';
import { useContext, useMemo } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { TreeDisplays } from '../TreeDisplays';

export default function StreetViewTile(props: StreetViewTileProps) {
  const streetViewProps = useStreetViewProps({ tree: props.tree, isCaptureModeActive: props.isCaptureModeActive });

  const { capturePoints } = streetViewProps;
  const { urlContext } = useContext(DependencyInjectionContext);
  const capturePointId = urlContext.getCapturePointId();

  const currentCapturePointIndex = useMemo(() =>
    capturePoints.findIndex(it => it.id === capturePointId)
  , [capturePoints, capturePointId]);

  const handleLeftArrowClick = () => {
    if (capturePoints.length === 0) return;
    urlContext.setCapturePointId(capturePoints[currentCapturePointIndex].leftNeighborId);
  };

  const handleRightArrowClick = () => {
    if (capturePoints.length === 0) return;
    urlContext.setCapturePointId(capturePoints[currentCapturePointIndex].rightNeighborId);
  };

  return (
    <TileContainer>
      <TileContent>
        <ButtonsOverlay>
          <ButtonsOverlay.TopRight>
            {props.isCaptureModeActive &&
              <TileButton
                className="h-fit w-fit p-0"
                onClick={() => {
                  const photoReceiver = document.querySelector('#photoReceiver');
                  if (photoReceiver) photoReceiver.id = '';
                  props.setCaptureModeActive(false);
                }}
                icon={<Xmark className="m-2"/>}
              />
            }
          </ButtonsOverlay.TopRight>
          <ButtonsOverlay.BottomCenter>
            {props.isCaptureModeActive &&
              <div id="recordPhotoButton">
                <TileButton
                  className="h-fit w-fit p-0 disabled:opacity-50"
                  onClick={() => {
                    const canvas = document.querySelector<HTMLCanvasElement>('#streetView');
                    if (canvas) {
                      const photoReceiver = document.querySelector('#photoReceiver');
                      if (photoReceiver) {
                        const img = document.createElement('img');
                        img.src = canvas.toDataURL('image/png');
                        img.className = 'h-16 bg-outer-space-500 rounded shadow';
                        photoReceiver.appendChild(img);
                      }
                    }
                  }}
                  icon={<div className="w-4 h-4 bg-danger-500 rounded-full m-4"/>}
                />
              </div>
            }
          </ButtonsOverlay.BottomCenter>
          <ButtonsOverlay.BottomRight>
            <StreetViewBottomRightButtons {...streetViewProps} />
            <ViewSelector setView={props.setView} view={props.view}/>
          </ButtonsOverlay.BottomRight>
          <ButtonsOverlay.RightBottom>
            <StreetViewRightBottomButtons {...streetViewProps} />
          </ButtonsOverlay.RightBottom>
          <ButtonsOverlay.LeftCenter>
            <button
              className={styles.arrowButton}
              onClick={handleLeftArrowClick}
              disabled={capturePoints.length === 0}
            >
              <NavArrowLeft />
            </button>
          </ButtonsOverlay.LeftCenter>
          <ButtonsOverlay.RightCenter>
            <button
              className={styles.arrowButton}
              onClick={handleRightArrowClick}
              disabled={capturePoints.length === 0}
            >
              <NavArrowRight />
            </button>
          </ButtonsOverlay.RightCenter>
        </ButtonsOverlay>
        <StreetViewContent {...streetViewProps} />
      </TileContent>
    </TileContainer>
  );
}

interface StreetViewTileProps {
  tree: DetailedTree,
  isCaptureModeActive: boolean,
  setCaptureModeActive: (active: boolean) => void,
  view: TreeDisplays,
  setView: (view: TreeDisplays) => unknown
}
