import { useTranslation } from 'react-i18next';
import { Condition as TreeCondition, CrownTransparency, DisplayableTreeProperty, Fork, Tree } from '../../../tree/Tree';
import React, { ReactNode, useState } from 'react';
import { useAvailableFilterItemProperties } from '../../../properties/usePropertyConfigurations';
import { useCurrentAccount } from '../../../account/useAccounts';
import {
  Condition,
  DateRangeCondition,
  EnumCondition,
  Filter,
  NumericCondition,
  StringCondition
} from '../../../filter/FilterConfig';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';
import { ViStatus } from '../../../tree/DetailedTree';
import { SpeciesLists } from './SpeciesList';
import { IconButton } from '@carbon/react';
import FluidDropdown from '@carbon/react/es/components/FluidDropdown/FluidDropdown';
import FluidMultiSelect from '@carbon/react/es/components/FluidMultiSelect/FluidMultiSelect';
import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
import FluidDatePicker from '@carbon/react/es/components/FluidDatePicker/FluidDatePicker';
import FluidDatePickerInput from '@carbon/react/es/components/FluidDatePickerInput/FluidDatePickerInput';
import { FilterRemove } from '@carbon/icons-react';
import { GrowSpace } from '../../../property-enums/GrowSpace';
import { OverheadUtilities } from '../../../property-enums/OverheadUtilities';
import { CardinalDirection } from '../../../utils/getCardinalDirectionFromAngle';

type ReactSelectItem = { value: string, label: string };
export default function CarbonFilterRow(props: FilterRowProps) {
  const enumProperties = {
    [DisplayableTreeProperty.Status]: Object.values(TreeStatus),
    [DisplayableTreeProperty.VitalityVigor]: Object.values(VitalityVigor),
    [DisplayableTreeProperty.ViStatus]: Object.values(ViStatus),
    [DisplayableTreeProperty.ScientificName]: props.speciesLists.scientificNames,
    [DisplayableTreeProperty.Genus]: props.speciesLists.genus,
    [DisplayableTreeProperty.Species]: props.speciesLists.species,
    [DisplayableTreeProperty.OutlierHeightPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierHeightPerLeafArea]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerHeight]: ['true', 'false'],
    [DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea]: ['true', 'false'],
    [DisplayableTreeProperty.OverallOutlierIndex]: new Array(7).fill(0).map((_, i) => i.toString()),
    [DisplayableTreeProperty.WireClearanceIssueDetected]: ['true', 'false'],
    [DisplayableTreeProperty.RoadClearanceIssueDetected]: ['true', 'false'],
    [DisplayableTreeProperty.TmsCategory]: ['l0', 'l1', 'l2'],
    [DisplayableTreeProperty.AgeClass]: ['young', 'mature_veteran'],
    [DisplayableTreeProperty.Condition]: Object.values(TreeCondition),
    [DisplayableTreeProperty.CrownTransparency]: Object.values(CrownTransparency),
    [DisplayableTreeProperty.CrownLightExposure]: new Array(5).fill(0).map((_, i) => i.toString()),
    [DisplayableTreeProperty.GrowSpace]: Object.values(GrowSpace),
    [DisplayableTreeProperty.OverheadUtilities]: Object.values(OverheadUtilities),
    [DisplayableTreeProperty.PrevailingWindDirection]: Object.values(CardinalDirection),
    [DisplayableTreeProperty.FoliageNoneSeasonal]: ['true', 'false'],
    [DisplayableTreeProperty.FoliageNoneDead]: ['true', 'false'],
    [DisplayableTreeProperty.CoDominantStems]: ['true', 'false'],
    [DisplayableTreeProperty.Fork]: Object.values(Fork),
    [DisplayableTreeProperty.IncludedBark]: ['true', 'false']
  };

  const { t } = useTranslation();

  const { organization } = useCurrentAccount();
  const properties = useAvailableFilterItemProperties();

  const [inputValue, setInputValue] = useState(props.filter.value?.toString());

  const getDisplayableProperty = (property: DisplayableTreeProperty) => {
    const unit = Tree.getUnit(property, organization);
    const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
    return Tree.renderPropertyName(property, t) + translatedUnit;
  };

  const groups = Object.keys(properties).map(title => ({
    label: t(`treePropertySelector.${title}`),
    options: properties[title].map(it => ({ value: it, label: getDisplayableProperty(it) }))
  }));

  const nonFilterablePropertyFilter = item => ![DisplayableTreeProperty.DisplayableWorldCoordinates].includes(item.value);

  const allProperty = groups.flatMap(group => group.options).filter(nonFilterablePropertyFilter).sort((a, b) => a.label.localeCompare(b.label));
  const selectedProperty = allProperty.find(item => item.value === props.filter.property);

  const handleTreePropertySelect = (item: ReactSelectItem) => {
    if (Tree.isStringProperty(item.value)) {
      props.update(props.filterIndex, {
        ...props.filter,
        property: item.value as keyof Tree,
        condition: StringCondition.CONTAINS,
        value: ''
      });
      return;
    }
    if (Tree.isDateProperty(item.value)) {
      props.update(props.filterIndex, {
        ...props.filter,
        property: item.value as keyof Tree,
        condition: DateRangeCondition.IN_DATE_RANGE,
        value: [undefined, undefined] as unknown as string[]
      });
      return;
    }
    if (Tree.isEnumProperty(item.value)) {
      props.update(props.filterIndex, {
        ...props.filter,
        property: item.value as keyof Tree,
        condition: EnumCondition.IN,
        value: []
      });
      return;
    }
    props.update(props.filterIndex, {
      ...props.filter,
      property: item.value as keyof Tree,
      condition: NumericCondition.EQUALS,
      value: (null as unknown as number)
    });
  };

  const handleConditionSelect = (item: ReactSelectItem) => {
    if (item?.value) {
      props.update(props.filterIndex, {
        ...props.filter,
        condition: item?.value as NumericCondition | EnumCondition,
        value: isEnumCondition(item.value as NumericCondition | EnumCondition) ? [] : (props.filter.value ?? 0)
      });
    } else {
      props.update(props.filterIndex, {
        ...props.filter,
        condition: '' as NumericCondition | EnumCondition,
        value: []
      });
    }
  };

  const handleNumericUpdate = (value: string) => {
    value = value.replace(/-/g, '');
    props.update(props.filterIndex, {
      ...props.filter,
      value: Number(value)
    });
    setInputValue(value);
  };

  const handlePropertyValueChange = (value: string[] | string) => {
    props.update(props.filterIndex, { ...props.filter, value });
  };

  const isEnumCondition = (condition: Condition) => {
    return Object.values(EnumCondition).includes(condition as EnumCondition);
  };

  const enumPropertyOptions = enumProperties[props.filter.property]?.map(it => ({
    label: Tree.renderPropertyValue(props.filter.property, it, t),
    value: it
  })) || [];

  return (
    <FilterRow i={props.index + 1} onDelete={props.handleDeleteFilter}>
      <FluidDropdown
        className="col-start-1 col-end-3"
        titleText={t('filter.property')}
        onChange={it => handleTreePropertySelect(it.selectedItem)}
        initialSelectedItem={selectedProperty}
        items={allProperty}
        itemToString={item => item.label}
        id={props.index + 1 + '-property'}
        label=''
      />
      {Tree.isMultiSelectProperty(props.filter.property) &&
        <EnumMultiSelector
          handleChange={handlePropertyValueChange}
          values={props.filter.value as string[]}
          enumPropertyOptions={enumPropertyOptions}
        />
      }
      {Tree.isSingleSelectProperty(props.filter.property) &&
        <EnumSingleSelector
          handleEnumChange={handlePropertyValueChange}
          values={props.filter.value as string[]}
          enumPropertyOptions={enumPropertyOptions}
        />
      }
      {Tree.isStringProperty(props.filter.property) &&
        <StringSelector
          handleChange={handlePropertyValueChange}
          value={props.filter.value as string}
        />
      }
      {Tree.isDateProperty(props.filter.property) &&
        <DateRangeSelector
          handleChange={handlePropertyValueChange}
          value={props.filter.value as string[]}
        />
      }
      {(!Tree.isEnumProperty(props.filter.property) && !Tree.isStringProperty(props.filter.property)) && !Tree.isDateProperty(props.filter.property) &&
        <ContinuousNumberSelector
          handleConditionSelect={handleConditionSelect}
          handleNumericUpdate={handleNumericUpdate}
          inputValue={inputValue}
          condition={props.filter.condition}
          index={props.index}
        />
      }
    </FilterRow>
  );
}

type StringSelectorProps = {
  handleChange: (value: string) => void,
  value: string
};
function StringSelector({ handleChange, value }: StringSelectorProps) {
  const { t } = useTranslation();

  return <FluidTextInput
    className="col-start-1 col-end-3"
    labelText={t('filter.value')}
    onChange={e => handleChange(e.target.value)}
    defaultValue={value}
  />;
}

type DateRangeSelectorProps = {
  handleChange: (values: string[]) => void,
  value: string[]
};
function DateRangeSelector({ handleChange, value }: DateRangeSelectorProps) {
  const { t } = useTranslation();

  return <div className="col-start-1 col-end-3">
    <FluidDatePicker
      datePickerType="range"
      onChange={([from, to]) => handleChange([convertToString(from), convertToString(to)] as string[])}
      value={value.map(it => it ? new Date(it) : undefined)}
    >
      <FluidDatePickerInput id="date-picker-input-id-start" labelText={t('startDate') as string} size="md" />
      <FluidDatePickerInput id="date-picker-input-id-finish" labelText={t('endDate') as string} size="md" />
    </FluidDatePicker>
  </div>;

  function convertToString(date: Date): string | undefined {
    if (!date) return undefined;
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
  }
}

type EnumSingleSelectorProps = {
  handleEnumChange: (values: string[]) => void,
  values: string[],
  enumPropertyOptions: { label: string, value: string }[]
};
function EnumSingleSelector({ handleEnumChange, values, enumPropertyOptions }: EnumSingleSelectorProps) {
  const { t } = useTranslation();

  return <FluidDropdown
    className="col-start-1 col-end-3"
    titleText={t('filter.value')}
    labelText={t('filter.value')}
    onChange={it => handleEnumChange([it.selectedItem.value])}
    initialSelectedItem={enumPropertyOptions.filter(it => values.includes(it.value))[0]}
    items={enumPropertyOptions}
    itemToString={item => item.label}
  />;
}

type EnumMultiSelectorProps = {
  handleChange: (values: string[]) => void,
  values: string[],
  enumPropertyOptions: { label: string, value: string }[]
};
function EnumMultiSelector({ handleChange, values, enumPropertyOptions }: EnumMultiSelectorProps) {
  const { t } = useTranslation();

  return <FluidMultiSelect
    isFilterable
    className="col-start-1 col-end-3"
    titleText={t('filter.value')}
    labelText={t('filter.value')}
    onChange={it => handleChange(it.selectedItems.map(it => it.value))}
    items={enumPropertyOptions}
    initialSelectedItems={enumPropertyOptions.filter(it => values.includes(it.value))}
    itemToString={item => item.label}
  />;
}

type NumberSelectorProps = {
  handleConditionSelect: (item: ReactSelectItem) => void,
  handleNumericUpdate: (value: string) => void,
  inputValue: string,
  condition: Condition,
  index: number
};
function ContinuousNumberSelector({ handleConditionSelect, condition, index, handleNumericUpdate, inputValue }: NumberSelectorProps) {
  const { t } = useTranslation();
  const numberConditionOptions = Object.values(NumericCondition).map(it => ({ value: it, label: t(`taskManager.${it}`) }));
  const selectedCondition = numberConditionOptions.find(item => item.value === condition);

  return <>
    <FluidDropdown
      className="col-start-1 col-end-2"
      titleText={t('filter.condition')}
      onChange={it => handleConditionSelect(it.selectedItem)}
      initialSelectedItem={selectedCondition}
      items={numberConditionOptions}
      itemToString={item => item.label}
      id={index + 1 + '-condition'}
      label=""
    />
    <FluidTextInput
      className="col-start-2 col-end-3"
      type="number"
      labelText={t('filter.value')}
      onChange={e => handleNumericUpdate(e.target.value)}
      defaultValue={inputValue}
      id={index + 1 + '-input'}
    />
  </>;
}

function FilterRow({ children, i, onDelete }: { children?: ReactNode, i: number, onDelete: () => void }) {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2 gap-0.5 border-solid border-t border-[var(--cds-border-subtle-00)] border-0">
      <div className="col-start-1 col-end-3 flex items-center justify-between">
        <p className="m-0 text-sm">{t('filter.filter') + ' ' + i.toString().padStart(2, '0')}</p>
        <IconButton onClick={onDelete} label={t('filter.removeFilter')} align="left" kind="ghost"><FilterRemove/></IconButton>
      </div>
      {children}
    </div>
  );
}

interface FilterRowProps {
  index: number,
  handleDeleteFilter: () => void,
  filter: Filter,
  filterIndex: number,
  update: (index: number, filter: Filter) => void,
  speciesLists: SpeciesLists
}
