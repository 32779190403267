import { Mitigation, Tree, TreeDto } from './Tree';
import { plainToInstance } from 'class-transformer';
import { CardinalDirection } from '../utils/getCardinalDirectionFromAngle';
import { KMH_TO_MPH, METER_TO_FEET } from '../components/PointCloud/unitConstants';
import { TrackableTreeProperty } from './TrackableTreeProperty';
import { TseTreeScan } from './TseTreeScan';
import {
  ConsequencesOfFailure,
  LikelihoodOfFailure,
  LikelihoodOfImpact
} from '../routes/Details/CurrentState/RiskCategorization';
import { DataStatus } from './DataStatus';
import { InspectionLimitation } from './InspectionLimitation';
import { Organization } from '../organization/Organization';

export enum ViStatus {
  TO_BE_SCHEDULED = 'toBeScheduled',
  TO_DO = 'toDo',
  IN_PROGRESS = 'inProgress',
  ON_HOLD = 'onHold',
  SENT_TO_ON_SITE_VI = 'sentToOnSiteVi',
  ADVANCED_ASSESSMENT_TO_DO = 'advancedAssessmentToDo',
  MITIGATION_TO_DO = 'mitigationToDo',
  ESCALATED = 'escalated'
}

export interface Observation {
  name: string,
  affectedTreePart: string,
  note: string,
  photoUrls: string[],
  conditionOfConcern: boolean,
  mitigations: Mitigation[]
}

export type RoadClearance = {
  minimumHeight: number,
  minimumDistance: number,
  clearanceVolume: number,
  clearanceVolumeRatio: number,
  clearanceBlobPath: string
};

export interface TargetAssessment {
  id: string,
  treeId: string,
  organizationId: string,
  userId: string,
  createdAt: Date,
  targetDescription: string,
  targetZone: string,
  treePart: string,
  conditionOfConcern: string,
  likelihoodOfFailure: LikelihoodOfFailure,
  likelihoodOfImpact: LikelihoodOfImpact,
  consequences: ConsequencesOfFailure,
  affectedTreePart: string
}

export interface DetailedTreeDto extends TreeDto {
  isMetric: boolean,
  numberOfStems?: number,
  commonName?: string,
  owner?: string,
  onStreetName?: string,
  sideLocation?: string,
  landUse?: string,
  parkName?: string,
  growSpaceSize?: string,
  deadDyingOrBrokenBranches?: boolean,
  overExtendedBranches?: boolean,
  mechanicalDamage?: boolean,
  girdlingRoots?: boolean,
  furtherInspectionNeeded?: Date,
  overallTreeRiskRating?: string,
  overallResidualRisk?: string,
  readonly foliage?: string,
  readonly vitalityBerlin?: string,
  readonly CTLA?: number,
  readonly environment?: {
    corridorMinimumHeightClearance?: number | null,
    corridorObstructionHeight?: number | null,
    corridorObstructionVolume?: number | null,
    corridorAffectedCrown?: number | null,
    viaductCriticalHeight?: number | null,
    viaductOutsideCriticalBoundaryPercentage?: number | null,
    viaductOutsideCriticalBoundaryArea?: number | null,
    trafficSignVisibleDistance?: number | null,
    trafficSignCoverage?: number | null,
    distanceToWires?: number | null,
    potentialTargets?: string | null
  },
  readonly criticalWindSpeed?: number,
  readonly cohortId?: string,
  readonly firstBifurcationPoint?: { type: 'Point', coordinates: number[] },
  readonly observations: Observation[],
  readonly potentialTargets?: string,
  readonly dataStatus?: DataStatus,
  readonly recommendedInspectionInterval?: string,
  readonly advancedAssessmentNeeded?: boolean,
  readonly advancedAssessmentReason?: string,
  readonly inspectionLimitation?: InspectionLimitation,
  readonly inspectionLimitationDescription?: string,
  readonly note?: string,
  readonly outlierCoSequestration?: boolean,
  readonly outlierPmReduction?: boolean,
  readonly outlierOxygenProduction?: boolean,
  readonly outlierWaterRunoff?: boolean,
  readonly outlierEvapotranspiration?: boolean
}

export default class DetailedTree extends Tree {
  static readonly TRACKABLE_TREE_PROPERTIES = Object.values(TrackableTreeProperty);

  static fromDetailedTreeDto(dto: DetailedTreeDto): DetailedTree {
    return plainToInstance(DetailedTree, { ...dto, ...Tree.fromDto(dto) });
  }

  readonly isMetric: boolean = false;
  readonly addressFromParcel?: string;
  readonly numberOfStems?: number;
  readonly commonName?: string;
  readonly owner?: string;
  readonly onStreetName?: string;
  readonly sideLocation?: string;
  readonly landUse?: string[];
  readonly parkName?: string;
  readonly growSpaceSize?: string;
  readonly deadDyingOrBrokenBranches?: boolean;
  readonly overExtendedBranches?: boolean;
  readonly mechanicalDamage?: boolean;
  readonly girdlingRoots?: boolean;
  readonly furtherInspectionNeeded?: Date;
  readonly overallTreeRiskRating?: string;
  readonly overallResidualRisk?: string;
  readonly foliage?: string;
  readonly vitalityBerlin?: string;
  readonly ctla?: number;
  readonly environment?: TreeEnvironment;
  readonly cohortId?: string;
  readonly tseTreeScans?: TseTreeScan[];
  readonly firstBifurcationPoint?: { type: 'Point', coordinates: number[] };
  readonly observations: Observation[] = [];
  readonly potentialTargets?: TargetAssessment[] = [];
  readonly dataStatus?: DataStatus;
  readonly recommendedInspectionInterval?: string;
  readonly advancedAssessmentNeeded?: boolean;
  readonly advancedAssessmentReason?: string;
  readonly inspectionLimitation?: InspectionLimitation;
  readonly inspectionLimitationDescription?: string;
  readonly note?: string;
  readonly roadClearances: RoadClearance[] = [];
  readonly outlierCoSequestration?: boolean;
  readonly outlierPmReduction?: boolean;
  readonly outlierOxygenProduction?: boolean;
  readonly outlierWaterRunoff?: boolean;
  readonly outlierEvapotranspiration?: boolean;
  readonly aggAllWireClearances?: any[];

  get age(): string {
    return this.plantingYear ? (new Date().getFullYear() - Number(this.plantingYear)).toString() : '';
  }

  get criticalWindSpeed(): number | null {
    const criticalWindSpeed = this.safetyFactors.find(it => it.safetyFactor <= 1.5)?.windSpeed;
    if (criticalWindSpeed === undefined) return null;
    return this.isMetric ? criticalWindSpeed : criticalWindSpeed * KMH_TO_MPH;
  }

  get metricHeight(): number {
    return this.isMetric ? this.height : this.height / METER_TO_FEET;
  }

  get metricCanopyHeight(): number {
    return this.isMetric ? this.canopyHeight : this.canopyHeight / METER_TO_FEET;
  }

  get isViToBeScheduled(): boolean {
    return this.viStatus === ViStatus.TO_BE_SCHEDULED;
  }

  override getGenus(organization: Organization): string {
    return super.getGenus(organization);
  }

  override getSpecies(organization: Organization): string {
    return super.getSpecies(organization);
  }
}

export type TreeEnvironment = {
  corridorLShapeVertex?: { type: 'MultiPoint', coordinates: number[][]},
  corridorMinimumHeightClearance?: number | null,
  corridorObstructionHeight?: number | null,
  corridorObstructionVolume?: number | null,
  corridorAffectedCrown?: number | null,
  viaductCriticalHeight?: number | null,
  viaductOutsideCriticalBoundaryPercentage?: number | null,
  viaductOutsideCriticalBoundaryArea?: number | null,
  trafficSignVisibleDistance?: number | null,
  trafficSignCoverage?: number | null,
  distanceToWires?: number | null,
  potentialTargets?: string | null
};
