import { AxiosInstance } from 'axios';
import { Image } from '../routes/Explore/panoramic-view/meshGenerator';
import CapturePoint, { CapturePointDto } from './CapturePoint';
import { Organization } from '../organization/Organization';

export default class CapturePointService {
  constructor(private readonly http: AxiosInstance) {}

  async show(organizationId: string, id: string): Promise<CapturePoint> {
    const { data } = await this.http.get<CapturePointDto>(`/v1/organizations/${organizationId}/capture-points/${id}`);
    return CapturePoint.fromDto(data);
  }

  async listVisibleFrom(organizationId: string, origin: number[], epsilon: number, date: string | undefined) {
    const [x, y] = origin;
    const andDateParam = date ? ('&date=' + date) : '';
    const { data } = await this.http.get<CapturePointDto[]>(
      `/v1/organizations/${organizationId}/capture-points?x=${x}&y=${y}&epsilon=${epsilon}${andDateParam}`
    );
    return data.map(dto => CapturePoint.fromDto(dto));
  }

  async getImagesBySnapshotId(organization: Organization, snapshotId: string): Promise<Image[]> {
    const { data: nearestImages } = await this.http.get<LegacyCapturePoint[]>(
      `/v0/organizations/${organization.id}/tse-images?snapshotId=${snapshotId}`
    );

    const cameraData = this.cameraDataForNearestImagesRequest(nearestImages);
    return this.getImagesFromCapturePoint(cameraData, organization);
  }

  private getImagesFromCapturePoint(capturePoints, organization: Organization): Image[] {
    const getSmallUrl = url => {
      return organization.getCDNUrlFromRelativePath(`scan/${url.replace('_large.jpg', '_small.jpg')}`);
    };
    const getLargeUrl = url => {
      return organization.getCDNUrlFromRelativePath(`scan/${url}`);
    };

    return capturePoints.map(cam => ({
      ...cam,
      directionVec: cam.direction.coordinates || cam.direction,
      upVec: cam.up.coordinates || cam.up,
      width: cam.nx,
      height: cam.ny,
      cameraMatrix: [
        [cam.fx, 0, cam.cx],
        [0, cam.fy, cam.cy],
        [0, 0, 1]
      ],
      url: organization.getCDNUrlFromRelativePath(`scan/${cam.path}`),
      smallUrl: getSmallUrl(cam.path),
      largeUrl: getLargeUrl(cam.path)
    }));
  }

  private cameraDataForNearestImagesRequest(data: LegacyCapturePoint[] = []) {
    const cameras = data.map(cam => ({
      ...cam,
      origin: cam.origin?.coordinates ? cam.origin : { coordinates: cam.origin }
    }));

    return cameras.sort((a, b) => (Number(a.camera_id) > Number(b.camera_id) ? 1 : -1));
  }
}

interface LegacyCapturePoint extends Record<string, unknown> {
  camera_id: string,
  path: string,
  filename: string,
  origin: {
    coordinates: unknown
  } & Record<string, unknown>
}
