import DetailedTree, { Observation } from '../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Fork, GH_DEFAULT, Tree } from '../../../tree/Tree';
import { useCurrentAccount } from '../../../account/useAccounts';
import {
  Accordion,
  AccordionDropdownField,
  AccordionGroup,
  AccordionMultiSelectField,
  AccordionNumericInputField,
  AccordionTextInputField
} from './AccordionGroup';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Item } from '../../../components/UI/Dropdown/Dropdown';
import { CardinalDirection } from '../../../utils/getCardinalDirectionFromAngle';
import { VitalityVigor } from '../../../property-enums/VitalityVigor';
import { TreeStatus } from '../../../property-enums/TreeStatus';
import {
  CrownObservationOptionEnum,
  RootObservationOptionEnum,
  TrunkObservationOptionEnum
} from './observationOptions';
import { LandUse } from '../../../property-enums/LandUse';
import { OverheadUtilities } from '../../../property-enums/OverheadUtilities';
import { GrowSpace } from '../../../property-enums/GrowSpace';
import NumberOfLimbs from './components/NumberOfLimbs';
import PotentialTargets from './components/PotentialTargets';
import { RiskRating } from '../../../property-enums/RiskRating';
import { DetailsContext } from '../Details';
import { ObservationItem } from './ObservationItem';
import { Flippers } from '../../../switches/Flippers';

export enum DataCollectionSections {
  AssignmentAndTreeId = 'assignmentAndTreeId',
  TargetAssessment = 'targetAssessment',
  SiteFactors = 'siteFactors',
  TreeHealthAndSpeciesProfile = 'treeHealthAndSpeciesProfile',
  LoadFactors = 'loadFactors',
  CrownAndBranches = 'crownAndBranches',
  Trunk = 'trunk',
  RootsAndRootCollar = 'rootsAndRootCollar'
}

const booleanItems: Item[] = [
  { id: 'true', translationKey: 'details.properties.booleanLabels.true' },
  { id: 'false', translationKey: 'details.properties.booleanLabels.false' }
];

interface Option<T> {
  label: string,
  value: T
}

export default function DataCollection({ tree, handleChange, editingSection, setEditingSection, isCaptureModeActive, setCaptureModeActive }: {
  tree: DetailedTree | null,
  handleChange: <T>(property: string, value: T) => void,
  editingSection: string | null,
  setEditingSection: Dispatch<SetStateAction<string | null>>,
  isCaptureModeActive: boolean,
  setCaptureModeActive: Dispatch<SetStateAction<boolean>>
}) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const [editingSubsection, setEditingSubsection] = useState<string | null>(null);
  const { setRootZoneVisibility } = useContext(DetailsContext);

  const prevailingWindDirectionOptions: Item[] = Object.values(CardinalDirection).map(direction => {
    return { id: direction, translationKey: 'treeDetails.risk.windDirections.' + direction };
  });
  const landUseOptions: Option<string>[] = Object.values(LandUse).map(land => {
    return { value: land, label: t('details.properties.landUseTypes.' + land) };
  });
  const overheadUtilitiesOptions: Item[] = Object.values(OverheadUtilities).map(utility => {
    return { id: utility, translationKey: t('details.properties.overheadUtilitiesTypes.' + utility) };
  });
  const growSpaceOptions: Item[] = Object.values(GrowSpace).map(space => {
    return { id: space, translationKey: 'details.properties.growSpaceTypes.' + space };
  });

  const handleObservationCheck = (affectedTreePart: string, disease: string) => {
    if (!tree) return;
    const observationWasChecked = tree.observations.some(it => it.affectedTreePart === affectedTreePart && it.name === disease);
    if (observationWasChecked) {
      handleChange('observations', tree.observations.filter(it => it.name !== disease));
    } else {
      handleChange('observations', [...tree.observations, { affectedTreePart, name: disease }]);
    }
  };

  const handleObservationUpdate = (observation: Observation) => {
    if (!tree) return;
    const updatedObservations = tree.observations.map(it => it.name === observation.name ? observation : it);
    handleChange('observations', updatedObservations);
    if (observation.mitigations?.length) {
      const newMitigations = observation.mitigations
        .filter(it => !tree.mitigations.some(mitigation => mitigation.taskTemplateId === it.taskTemplateId))
        .map(it => ({ ...it, residualRisk: RiskRating.LOW }));

      if (newMitigations.length) {
        const previousMitigations = tree?.mitigations || [];
        handleChange('mitigations', [...previousMitigations, ...newMitigations]);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (editingSection) {
        setEditingSection(null);
      }
      setRootZoneVisibility(false);
    };
  }, [tree?.id]);

  const handleOpenSectionChange = (openSection: string | null) => {
    if (openSection === DataCollectionSections.TargetAssessment) setRootZoneVisibility(true);
    else setRootZoneVisibility(false);
  };

  return (
    <ul className="twp text-greehill-00 divide-y divide-outer-space-500">
      <AccordionGroup tree={tree} handleChange={handleChange} editingSection={editingSection} setEditingSection={setEditingSection} onOpenSectionChange={handleOpenSectionChange}>
        <li>
          <Accordion section={DataCollectionSections.AssignmentAndTreeId}>
            <AccordionTextInputField property={DisplayableTreeProperty.TmsCategory} value={tree?.tmsCategory} />
            <AccordionTextInputField property={DisplayableTreeProperty.CustomerSiteId} value={tree?.customerSiteId} editable/>
            <AccordionTextInputField property={DisplayableTreeProperty.CustomerTagId} value={tree?.customerTagId} editable/>
            <AccordionTextInputField property={DisplayableTreeProperty.CustomerTreeId} value={tree?.customerTreeId} editable/>
            <AccordionTextInputField property={DisplayableTreeProperty.Owner} value={tree?.owner} editable/>
            <AccordionNumericInputField property={DisplayableTreeProperty.PlantingYear} value={tree?.plantingYear} editable integer/>
            <AccordionTextInputField property={DisplayableTreeProperty.AgeClass} value={tree?.ageClass}/>
            {organization.isEnabled(Flippers.davey) ? '' : (
              <AccordionTextInputField property={DisplayableTreeProperty.LocalizedLocation} value={tree?.displayableWorldCoordinates}/>
            )}
            <AccordionTextInputField property={DisplayableTreeProperty.StreetAddress} value={tree?.streetAddress} editable/>
            <AccordionTextInputField property={DisplayableTreeProperty.AddressFromParcel} value={tree?.addressFromParcel} editable/>
            <AccordionTextInputField property={DisplayableTreeProperty.SideLocation} value={tree?.sideLocation} editable/>
            <AccordionTextInputField property={DisplayableTreeProperty.ParkName} value={tree?.parkName} editable/>
            <AccordionTextInputField
              property={DisplayableTreeProperty.Genus}
              value={organization.isEnabled(Flippers.davey) && tree?.genus === GH_DEFAULT ? 'Unknown' : tree?.genus}
              editable
              italic
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.Species}
              value={organization.isEnabled(Flippers.davey) && tree?.species === GH_DEFAULT ? 'Unknown' : tree?.species}
              editable
              italic
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.CultivarOrVariety}
              value={tree?.cultivarOrVariety}
              editable
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionTextInputField
              property={DisplayableTreeProperty.CommonName}
              value={tree?.commonName}
              editable
              userUpdatedProperties={tree?.userUpdatedProperties}
            />
            <AccordionNumericInputField property={DisplayableTreeProperty.Height} value={tree?.height} defaultUnit/>
            <AccordionNumericInputField property={DisplayableTreeProperty.NumberOfStems} value={tree?.numberOfStems} integer/>
            <AccordionNumericInputField property={DisplayableTreeProperty.TrunkCircumference} value={tree?.trunkCircumference} defaultUnit/>
            <AccordionNumericInputField property={DisplayableTreeProperty.TrunkDiameter} value={tree?.trunkDiameter} defaultUnit/>
            <AccordionNumericInputField property={DisplayableTreeProperty.TrunkHeight} value={tree?.trunkHeight} defaultUnit/>
            <AccordionNumericInputField property={DisplayableTreeProperty.CanopyWidth} value={tree?.canopyWidth} defaultUnit/>
            <AccordionNumericInputField property={DisplayableTreeProperty.CanopyHeight} value={tree?.canopyHeight} defaultUnit/>
            <AccordionNumericInputField property={DisplayableTreeProperty.CanopyCircumference} value={tree?.canopyCircumference} defaultUnit/>
            <AccordionNumericInputField property={DisplayableTreeProperty.CrownVolume} label={t('tree.crownVolume')} value={tree?.crownVolume} defaultUnit/>
          </Accordion>
        </li>
        <li>
          <Accordion section={DataCollectionSections.TargetAssessment}>
            <PotentialTargets
              tree={tree}
              editing={editingSection === DataCollectionSections.TargetAssessment}
              handleChange={handleChange}
            />
          </Accordion>
        </li>
        <li>
          <Accordion section={DataCollectionSections.SiteFactors}>
            <AccordionDropdownField items={prevailingWindDirectionOptions} property={'prevailingWindDirection' as DisplayableTreeProperty} value={prevailingWindDirectionOptions.find(it => it.id === tree?.prevailingWindDirection)}/>
            <AccordionMultiSelectField property={DisplayableTreeProperty.LandUse} value={tree?.landUse ?? []} options={landUseOptions}/>
            <AccordionDropdownField property={DisplayableTreeProperty.OverheadUtilities} value={overheadUtilitiesOptions.find(it => it.id === tree?.overheadUtilities)} items={overheadUtilitiesOptions}/>
            <AccordionTextInputField property={DisplayableTreeProperty.GrowSpaceSize} value={tree?.growSpaceSize} editable/>
            <AccordionDropdownField property={DisplayableTreeProperty.GrowSpace} value={growSpaceOptions.find(it => it.id === tree?.growSpace)} items={growSpaceOptions}/>
          </Accordion>
        </li>
        <li>
          <Accordion section={DataCollectionSections.TreeHealthAndSpeciesProfile}>
            <AccordionDropdownField
              items={Object.values(TreeStatus).map(it => ({ id: it, translationKey: `tree.statusTypes.${it}` }))}
              property={DisplayableTreeProperty.Status}
              value={tree?.status ? { id: tree.status, translationKey: `tree.statusTypes.${tree.status}` } : undefined}
            />
            <AccordionDropdownField
              items={Object.values(VitalityVigor).map(it => ({ id: it, translationKey: `tree.vitalityVigorTypes.${it}` }))}
              property={DisplayableTreeProperty.VitalityVigor}
              value={tree?.vitalityVigor ? { id: tree.vitalityVigor, translationKey: `tree.vitalityVigorTypes.${tree.vitalityVigor}` } : undefined}
            />
            <AccordionDropdownField
              items={booleanItems}
              property={DisplayableTreeProperty.FoliageNoneSeasonal}
              value={tree?.foliageNoneSeasonal !== null && tree?.foliageNoneSeasonal !== undefined ? { id: `${tree?.foliageNoneSeasonal}`, translationKey: `details.properties.booleanLabels.${tree?.foliageNoneSeasonal}` } : undefined}
            />
            <AccordionDropdownField
              items={booleanItems}
              property={DisplayableTreeProperty.FoliageNoneDead}
              value={tree?.foliageNoneDead !== null && tree?.foliageNoneDead !== undefined ? { id: `${tree?.foliageNoneDead}`, translationKey: `details.properties.booleanLabels.${tree?.foliageNoneDead}` } : undefined}
            />
            <AccordionNumericInputField property={DisplayableTreeProperty.NormalFoliage} value={tree?.normalFoliage} defaultUnit editable />
            <AccordionNumericInputField property={DisplayableTreeProperty.ChloroticFoliage} value={tree?.chloroticFoliage} defaultUnit editable />
            <AccordionNumericInputField property={DisplayableTreeProperty.NecroticFoliage} value={tree?.necroticFoliage} defaultUnit editable />
            <NumberOfLimbs
              tree={tree}
              editing={editingSection === DataCollectionSections.TreeHealthAndSpeciesProfile}
              handleChange={handleChange}
            />
            <AccordionDropdownField
              items={Object.values(Fork).map(option => ({ id: option, translationKey: 'details.properties.forkTypes.' + option }))}
              property={'fork' as DisplayableTreeProperty}
              value={tree?.fork ? { id: tree.fork, translationKey: `details.properties.forkTypes.${tree.fork}` } : undefined}
            />
          </Accordion>
        </li>
        <li>
          <Accordion section={DataCollectionSections.LoadFactors} disableEdit={true}>
            <AccordionNumericInputField property={DisplayableTreeProperty.CrownLightExposure} value={tree?.crownLightExposure}/>
            <AccordionNumericInputField
              property={'criticalWindSpeed' as DisplayableTreeProperty}
              value={tree?.criticalWindSpeed}
              customUnit={Tree.getWindSpeedUnit(organization)}
            />
          </Accordion>
        </li>
        <li>
          <Accordion section={DataCollectionSections.CrownAndBranches}>
            <AccordionGroup tree={tree} handleChange={handleChange} editingSection={editingSubsection} setEditingSection={setEditingSubsection}>
              {Object.values(CrownObservationOptionEnum).map(disease => (
                <ObservationItem
                  key={disease}
                  name={disease}
                  disabled={editingSection !== DataCollectionSections.CrownAndBranches}
                  checked={!!tree?.observations.filter(it => it.affectedTreePart === 'crown')?.some(it => it.name === disease)}
                  handleCheck={() => handleObservationCheck('crown', disease)}
                  update={(observation: Observation) => handleObservationUpdate(observation)}
                  observation={tree?.observations.find(it => it.name === disease)}
                  tree={tree}
                  isCaptureModeActive={isCaptureModeActive}
                  setCaptureModeActive={setCaptureModeActive}
                />
              ))}
              <ObservationItem
                key={DisplayableTreeProperty.LiveCrownRatio}
                name={DisplayableTreeProperty.LiveCrownRatio as unknown as CrownObservationOptionEnum}
                disabled={editingSection !== DataCollectionSections.CrownAndBranches}
                checked={true}
                handleCheck={() => {}}
                update={(observation: Observation) => handleObservationUpdate(observation)}
                observation={tree?.observations.find(it => it.name === DisplayableTreeProperty.LiveCrownRatio)}
                tree={tree}
                isCaptureModeActive={isCaptureModeActive}
                setCaptureModeActive={setCaptureModeActive}
              />
            </AccordionGroup>
          </Accordion>
        </li>
        <li>
          <Accordion section={DataCollectionSections.Trunk}>
            <AccordionGroup tree={tree} handleChange={handleChange} editingSection={editingSubsection} setEditingSection={setEditingSubsection}>
              {Object.values(TrunkObservationOptionEnum).map(disease => (
                <ObservationItem
                  key={disease}
                  name={disease}
                  disabled={editingSection !== DataCollectionSections.Trunk}
                  checked={!!tree?.observations.filter(it => it.affectedTreePart === 'trunk')?.some(it => it.name === disease)}
                  handleCheck={() => handleObservationCheck('trunk', disease)}
                  update={(observation: Observation) => handleObservationUpdate(observation)}
                  observation={tree?.observations.find(it => it.name === disease)}
                  tree={tree}
                  isCaptureModeActive={isCaptureModeActive}
                  setCaptureModeActive={setCaptureModeActive}
                />
              ))}
              <ObservationItem
                key={DisplayableTreeProperty.LeaningAngle}
                name={DisplayableTreeProperty.LeaningAngle as unknown as TrunkObservationOptionEnum}
                disabled={editingSection !== DataCollectionSections.Trunk}
                checked={true}
                handleCheck={() => {}}
                update={(observation: Observation) => handleObservationUpdate(observation)}
                observation={tree?.observations.find(it => it.name === DisplayableTreeProperty.LeaningAngle)}
                tree={tree}
                isCaptureModeActive={isCaptureModeActive}
                setCaptureModeActive={setCaptureModeActive}
              />
            </AccordionGroup>
          </Accordion>
        </li>
        <li>
          <Accordion section={DataCollectionSections.RootsAndRootCollar}>
            <AccordionGroup tree={tree} handleChange={handleChange} editingSection={editingSubsection} setEditingSection={setEditingSubsection}>
              {Object.values(RootObservationOptionEnum).map(disease => (
                <ObservationItem
                  key={disease}
                  name={disease}
                  disabled={editingSection !== DataCollectionSections.RootsAndRootCollar}
                  checked={!!tree?.observations.filter(it => it.affectedTreePart === 'root')?.some(it => it.name === disease)}
                  handleCheck={() => handleObservationCheck('root', disease)}
                  update={(observation: Observation) => handleObservationUpdate(observation)}
                  observation={tree?.observations.find(it => it.name === disease)}
                  tree={tree}
                  isCaptureModeActive={isCaptureModeActive}
                  setCaptureModeActive={setCaptureModeActive}
                />
              ))}
            </AccordionGroup>
          </Accordion>
        </li>
      </AccordionGroup>
    </ul>
  );
}
